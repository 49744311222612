const enGenders_t = Object.freeze({
  eGender_Unknown: 0,
  eGender_Male: 1,
  eGender_Female: 2,
  eGender_BiSexual: 3,
  eGender_Other: 4,
  eGender_Invalid: 255,
});

export default enGenders_t;
