/**
 * @brief: base carousel card view data class
 * @details: this class describe the features all carousel's card views must have
 * This file make use of composition api for code reusability and modularity
 * (through which also a kind of extension will be implemented when reusing this in other files)
 * NB: the references allows double way binding of data and thsis composition api based pattern
 * reinforce the fact that each instantiation of this class will be independent from the others
 */

import { ref } from "vue"; //computed

import { enViewItemType_t } from "@/../Common/commontypes/enums/index";


// will return separate instance of variables for each call
export default function jCarouselBaseitemdata(
  i_uMenuItemId = 0,
  i_eViewItemType0 = enViewItemType_t.eViewItemType_Unknown,
  i_strLabel = "",
  i_uCarouselPosition = 0
) {
  const m_uMenuItemId = ref(i_uMenuItemId);
  
  const m_eCarouselItemType = ref(i_eViewItemType0);

  const m_strMenuItemName = ref(i_strLabel); //TODO: Is this a reference to the constant??? prio 1

  const m_uCarouselPosition = ref(i_uCarouselPosition);

  //   const comp_var1 = computed(() => var1.value + shared_var_1.value);
  // comp_var1 updates value when either var1 or shared_var_1 value gets updated
  return { m_uMenuItemId, m_eCarouselItemType, m_strMenuItemName, m_uCarouselPosition };
}
