<template>
  <!-- small top nav bar component on home screen-->
  <!-- :class="{
      'bannerContainer-smAndDown': m_bIsMobile,
      'bannerContainer-mdAndUp': !m_bIsMobile,
    }" -->
  <v-container :style="m_pAppBarStyle" fluid>
    <v-layout>
      <!-- banner with message and search field background -->
      <v-img id="bannerImg" src="@/assets/images/banners/BannerBackground.svg" class="img-fluid" contain>
        <!-- search field component insertion -->
        <SearchField :class="{
          'searchComponent-smAndDown': m_bIsMobile,
          'searchComponent-mdAndUp': !m_bIsMobile,
          }" :m_bIsMobile=m_bIsMobile 
          :m_bDisplayAttributes=m_bDisplayAttributes
          @[userChangeEventListener]="onCustomAppUserEvent" />
      </v-img>
    </v-layout>
  </v-container>

</template>

<script>
import { ref } from "vue";


import SoftDevConfigs from "../../configurations/Configs.js";
import SearchField from "./SearchField";


export default {
  name: "landingPageBanner",
  components: { SearchField },
  props: {
    /**
     * @brief: property for the component to know weither the device is a mobile
     */
    m_bIsMobile: {
      type: Boolean,
      required: true,
    },
    /**
     * @brief: property for the component to know attributes necessary for responsiveness
     */
    m_bDisplayAttributes: {
      required: true,
    },
  },

  
   /**
    * @brief: explicite declaration of events for proper documentation and visibility 
    * (and some maybe unknown reasons)
    */
    emits: [ SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent],


  setup(props, { emit }) {

    /**
     * @brief
     * @details
     * //TODO: config and/or unit/integration tests must match this to top nav bar height    prio 6
     */
    function getTopMargininPercent() {
      let f64Val = 10;
      console.log(props.m_bDisplayAttributes.name)
      //TODO: prio 6 some hard coded consts here are taken from MainnavBar component height and must be imprived
      switch (props.m_bDisplayAttributes.name) {
        case 'xs': {
          f64Val = (1 / 10) * 577;//TODO: prio 4 hard coded to improve (1/7 is top nav bar height)
          break;
        }
        case 'sm': {
          f64Val = 1/6*577;//TODO: prio 4 hard coded to improve (1/7 is top nav bar height)
          break;
        }
        case 'md': {
          f64Val = 1/4*577;
          break;
        }
        case 'lg': {
          f64Val = 1/4*577;
          break;
        }
        case 'xl':{
            f64Val = 1/3*577;
            break;
          }
          case 'xxl':{
            f64Val = 1/2*577
            break;
          }
      }
      return f64Val;
    }

    const m_pAppBarStyle = ref({
      margin: "0px !important",
      "margin-top": getTopMargininPercent().toString() + "px !important",
      "margin-bottom": "0% !important",
      padding: "0px !important",
    });


    /**
     * @brief event listeners for any card view's event signaling a change
     */
     const userChangeEventListener = SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent;
     
     /**
     * @brief call back used by the listener for any child's event after a change or request
     * @details used by userChangeEventListener when it receives a signaled event
     * The method actually forward the emission to parent.
     * Nothing is done if not at least one of expected param properties is found
     */
    function onCustomAppUserEvent(i_oParams) {

      if ( undefined != i_oParams.m_strFilter ) {
        // Bubble event with arguments
        emit(SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent, i_oParams);
      } else {//TODO: prio 3 comment logs//TODO:prio 10 keep log in debug log level
        console.log(
          "landingPageBanner::onCustomAppUserEvent: CRITICAL has none of expected attributes"
        );
        console.log(i_oParams);
      }
    }
  
    return { m_pAppBarStyle, 
             userChangeEventListener,onCustomAppUserEvent }
  }
};
</script>

<!-- id selector -> class selector -> element selector -->
<style lang="scss" scoped>
@import "@/styles/variables.scss"; // $text-primary would be defined in that file

#bannerImg {
  width: 100%;
  height: 100%;
}

.searchComponent-mdAndUp {
  width: 50%;
  height: 20%;
  margin-left: 25.2% !important;
  margin-top: 20%;
  /* width: auto; */
  // border-style: dotted;
  // border-color: brown;
}

.searchComponent-smAndDown {
  width: 70%;
  height: fit-content;
  margin-left: 25.2% !important;
  margin-top: 19%;
  /* width: auto; */
  /* border-style: dotted;
  border-color: brown; */
}
</style>
