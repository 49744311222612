/** * * @brief single card view rendering on the home screen * @details: * this
is a modular reusable implementation of each * shopping item (carousel element)
* on the landing page */
<template>
  <!-- main card view shape and ids for styling-. "contain" key word IS QUITE IMPORTANT -->

  <v-card :class="{ 'rounded-card-container-0-smAndDown': m_bIsMobile, 
    'rounded-card-container-0-mdAndUp': !m_bIsMobile }" elevation="10" fluid>
    <DynamicMenuItemImage class="Img"  
    :m_bIsMobile="m_bIsMobile" :m_bDisplayAttributes=m_bDisplayAttributes
     :m_oMenuItemData="m_oMenuItemData" :m_eActionModes=1 />

    <!-- card view main label(name of the menu item) -->
    <div class="Container-1">
      <span :class="{
        'carViewTitle-smAndDown': m_bIsMobile,
        'carViewTitle-mdAndUp': !m_bIsMobile}">
        {{ m_oMenuItemData.m_strMenuItemName.substr(0, m_uLabel_NbrofChars) }}
      </span>
      <!-- key card view row ui containing location and the numbe rof units manipulation buttons -->
      <span
        :class="{
          'container-12-smAndDown': m_bIsMobile,
          'container-12-mdAndUp': !m_bIsMobile,
        }"
      >
        <span
          :class="{
            'CardViewLocalization-smAndDown': m_bIsMobile,
            'CardViewLocalization-mdAndUp': !m_bIsMobile,
          }"
        >
          <body>
            {{m_oMenuItemData.m_strLocalization.substr(0, m_uLocalization_NbrofChars)}}
          </body>
        </span>
        <span
          :class="{
            'BtnConainer-smAndDown': m_bIsMobile,
            'BtnConainer-mdAndUp': !m_bIsMobile,
          }"
        >
          <v-btn id="minusButtonId"
            :class="{
              'CardViewSignMinus-smAndDown': m_bIsMobile,
              'CardViewSignMinus-mdAndUp': !m_bIsMobile,
            }"
            @click="onChangeNumber"
          >
            -</v-btn
          >
          <v-btn 
            :class="{
              'cardViewNbrofunits-smAndDown': m_bIsMobile,
              'cardViewNbrofunits-mdAndUp': !m_bIsMobile,
            }"
            >{{ m_uNbrofUnits }}</v-btn
          >
          <v-btn id="plusButtonId"
            :class="{
              'CardViewSignPlus-smAndDown': m_bIsMobile,
              'CardViewSignPlus-mdAndUp': !m_bIsMobile,
            }"
            @click="onChangeNumber"
            >+</v-btn
          >
        </span>
      </span>
      <!-- days as buttons for selection of the delivery days -->
      <!-- <span class="container-13">
        <v-btn
          :class="{ 'CardViewDayBtn-Active-smAndDown': m_bIsMobile && button.isActive,
         'CardViewDayBtn-Active-mdAndUp': !m_bIsMobile && button.isActive,
         'CardViewDayBtn-InActive-smAndDown': m_bIsMobile && !button.isActive,
         'CardViewDayBtn-InActive-mdAndUp': !m_bIsMobile && !button.isActive  }"
          outlined
          v-for="(button, index) in m_lstButtons.value"
          :key="index"
          :active-class="button.isActive ? 'active' : 'no-active'"
          @click="button.isActive = !button.isActive"
        >
          {{ button.label }}
        </v-btn>
      </span> -->
      <!-- Display of the total price and the call-to-action button -->
      <!-- no v-card-actions, becaus eof the default space its use -->
      <span class="container-14">
        <span :class="{
                        'Box-141-smAndDown': m_bIsMobile,
                        'Box-141-mdAndUp': !m_bIsMobile,
                      }">
          {{m_uTotalPrice}} FCFA
        </span>
        <span :class="{
                        'Box-142-smAndDown': m_bIsMobile,
                        'Box-142-mdAndUp': !m_bIsMobile,
                      }">
          <v-btn :class="{
                        'CardViewAddBtn-smAndDown': m_bIsMobile,
                        'CardViewAddBtn-mdAndUp': !m_bIsMobile,
                      }" depressed @click="onAddtoBasket">Ajouter
          </v-btn>
        </span>
      </span>
    </div>
  </v-card>
</template>

<script>
import { onMounted, onBeforeUnmount, ref, watch } from "vue";


import SoftDevConfigs from "../../configurations/Configs.js";
import {SOFTCONFIG_DEV_WEEKDAYS, SOFTCONFIG_DEV_WEEKDAYS_SHORT_LENGTH, 
  SOFTCONFIG_DEV_WEEKDAYS_MAX, SOFTCONFIG_DEV_WEEKDAYS_MIN} from "@/../Common/commontypes/enums/enWeekDays_t";
import DynamicMenuItemImage from "../../src/components/DynamicMenuItemImage.vue";

export default {
  name: "HomeScreenCategoryCardView",
  components: {
    DynamicMenuItemImage,
  },

  props: {
    /**
     * @brief property for the component to know weither the device is a mobile
     */
    m_bIsMobile: {
      type: Boolean,
      required: true,
    },
      /**
      * @brief property for the component to know attributes necessary for responsiveness
      */
      m_bDisplayAttributes: {
      required: true,
    },

    m_oMenuItemData: {
      // type: jHomeScreenCategoryItemData,
      required: true,
    },
  },
  /**
   * @brief explicite declaration of events for proper documentation and visibility 
   * (and some maybe unknown reasons)
   */
  emits: [ SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent],

  //Use setup option as the entry point to the Composition API
  setup(props, {emit} ) {
    // console.log(props.m_oMenuItemData)

    /**
    * @brief used card width
    * @details
    * it might e an idea to have card width  as parameter prio 10.
    */
    function getLocalizationWidth() {
      let f64Val = SoftDevConfigs().CST_TEXTS.m_strLocationLengthMax;
      switch (props.m_bDisplayAttributes.name) {
        case 'xs': {
          f64Val = f64Val * 2 / 3 + 2;
          break;
        }
        case 'sm': {
          f64Val = f64Val * 2 / 3 + 2;
          break;
        }
        case 'md': {
          f64Val = f64Val * 2 / 3 + 2;
          break;
        }
        case 'lg': {
          f64Val = f64Val * 2 / 3 + 2;
          break;
        }
        case 'xl': {
          break;
        }
        case 'xxl': {
          break;
        }
      }

      if (2 == props.m_CardView) {
        f64Val *= 1.25;
      }

      return f64Val;
    }

    const m_uLocalization_NbrofChars = ref(Math.min(getLocalizationWidth(),
      props.m_oMenuItemData.m_strLocalization.length));

    const m_uLabel_NbrofChars = new ref(Math.min(m_uLocalization_NbrofChars.value * 3,
      props.m_oMenuItemData.m_strMenuItemName.length));
    /* watch for props to update item status */
    let m_oStopWatchHandle = watch(() => props.m_oMenuItemData.m_strMenuItemName,
      /**
       * @brief update lengths of key card texts when certain attributes change
       */
      function __updateTextSizes() {
        m_uLocalization_NbrofChars.value = Math.min(getLocalizationWidth(),
          props.m_oMenuItemData.m_strLocalization.length);
        m_uLabel_NbrofChars.value = Math.min(m_uLocalization_NbrofChars.value * 3,
          props.m_oMenuItemData.m_strMenuItemName.length);
        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!" + m_uLocalization_NbrofChars.value)
        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!" + m_uLabel_NbrofChars.value)
      }, { deep: false })

    /**
     * @brief list of buttons representing days that can be selected
     */
    var m_lstButtons = new ref([]);
    for (let i = SOFTCONFIG_DEV_WEEKDAYS_MIN; i <= SOFTCONFIG_DEV_WEEKDAYS_MAX; ++i) {
      m_lstButtons.value.push({
        label: SOFTCONFIG_DEV_WEEKDAYS[i].slice(0, SOFTCONFIG_DEV_WEEKDAYS_SHORT_LENGTH),
        m_bIsActive: false,
      });
    }

    /**
     * @brief member variable implementing a double binding to the view representation of the number of units
     *  */
    var m_uNbrofUnits = new ref(parseInt(props.m_oMenuItemData.m_uNbrofUnits));
    /**
     * @brief member variable implementing a double binding to the view representation of the unit price
     *  */
    const m_uUnitPrice = parseInt(props.m_oMenuItemData.m_uUnitPrice);
    /**
     * @brief member variable implementing a double binding to the view representation of total price
     */
    var m_uTotalPrice = new ref("");

    onMounted(()=> {
      let temp = m_uNbrofUnits.value*m_uUnitPrice;
      m_uTotalPrice.value = temp.toString().padStart(SoftDevConfigs().CST_TEXTS.m_strPriceLengthMax, " ");
    })

    onBeforeUnmount(() => {
      // stop watching for parent's param(may have been deleted already)
      m_oStopWatchHandle();
    })

    /**
     * @brief update the view model's number of unit items(therewith also the 
     * displayed number through reactivity)
     */
    function onChangeNumber(event) {
      // `event` is the native DOM event
      let step = 0;
      if (event) {
        if ("plusButtonId" === event.currentTarget.id) {
            step = 1;
        }
        else if ("minusButtonId" === event.currentTarget.id) {//TODO: improve this into a proper not hard coded constant
          step = (0 < m_uNbrofUnits.value) ? -1 : 0;// limit min price to 0
        }
        else {
          console.log("HomeScreenCategoryCardView::onChangeNumber: CRITICAL:DEV: was called from invalid target"
            + event.currentTarget)
        }
        if (0 != step) {
          m_uNbrofUnits.value += step;
          let temp = m_uNbrofUnits.value*m_uUnitPrice;
          m_uTotalPrice.value = temp.toString().padStart( SoftDevConfigs().CST_TEXTS.m_strPriceLengthMax);


          emit(SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent, {
            m_strUsecase: SoftDevConfigs().CST_EVENT_NAMES.m_strNbrofUnitEvent,
            m_strMenuItemId: props.m_oMenuItemData.m_uMenuItemId,
            m_uNbrofUnits: m_uNbrofUnits.value,
            m_eProductCategory: props.m_oMenuItemData.m_eProductCategory
          });
        }
      }
      else {
        console.log("HomeScreenCategoryCardView::onChangeNumber: Warning: was called without valid event")
      }
    }

    
    /**
     * @brief add a configured menu item to the shopping cart(basket)
     * @details: this will trigger the item to be sent via signal emission to Home screen
     * main parent view model.
     */
     function onAddtoBasket(event) {
      // `event` is the native DOM event
      if (event) {
        emit(SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent, {
          m_strUsecase: SoftDevConfigs().CST_EVENT_NAMES.m_strAddtoBasket,
          m_strMenuItemId: props.m_oMenuItemData.m_uMenuItemId,
          m_eProductCategory: props.m_oMenuItemData.m_eProductCategory
        });
      }
      else {
        console.log("HomeScreenCategoryCardView::onAddtoBasket: Warning: was called without valid event")
      }
    }

  

    return {
      m_lstButtons,
      m_uNbrofUnits,
      m_uTotalPrice,
      m_uLabel_NbrofChars,
      m_uLocalization_NbrofChars,

      onChangeNumber,
      onAddtoBasket,
    };
  },

  // computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss"; // $text-primary would be defined in that file

.rounded-card-container-0-mdAndUp {
  outline-width: 5px;
  border-radius: 5%;
  border-color: $color-secondary;
  // padding-left: 0%;
  // padding-right: 0%;
  // min-width: 0%;
  // min-height: 0%;
  width: 100%;
  height: 100%;
  font-family: "Rockwell", sans-serif;
  color: $color-on-surface;
  border: $color-secondary;
  border-style: none;
  box-shadow: $color-secondary;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // margin-right: 0%;
  // margin-left: 0%;;
}
.rounded-card-container-0-smAndDown {
  outline-width: 5px;
  border-radius: 5%;
  border-color: $color-secondary;
  // padding-left: 0%;
  // padding-right: 0%;
  // min-width: 0%;
  // min-height: 0%;
  // width: 184px;
  width: 100%;
  height: 100%;
  font-family: "Rockwell", sans-serif;
  color: $color-on-surface;
  border: $color-secondary;
  // border-style: dashed;
  box-shadow: $color-secondary;
  display: flex;
  flex-direction: column;
  align-content: space-around;
  // margin-right: 0%;
  // margin-left: 0%;;
}

.Img {
  order: 1;
  // min-width: 0%;
  // min-height: 0%;
  // height: 50px !important;
  // width: 5%!important;
  max-width: 100% !important;
  max-height: 45% !important;
}

// .viewCardPrice-mdAndUp {
//   width: fit-content;
//   height: fit-content;
//   border-radius: 15%;
//   margin-left: 65%;
//   margin-top: 30%;
//   background: $color-secondary;
//   color: #fff;
//   font-size: large;
// }
// .viewCardPrice-smAndDown {
//   width: fit-content;
//   height: fit-content;
//   border-radius: 15%;
//   margin-left: 60%;
//   margin-top: 35%;
//   background: $color-secondary;
//   color: #fff;
//   font-size: small;
// }
.Container-1 {
  order: 2;
  display: flex;
  flex-direction: column;
  // justify-items: flex-start;
  // justify-content: space-evenly;
  // align-items: flex-start;
  // align-content: space-between;
  // border-style: dotted;
  // border-color: chartreuse;
  height: 55%;
}
.carViewTitle-smAndDown {
  order: 1;
  margin-top: 0%;
  margin-left: 0%;
  align-self: center;
  width: 100%;
  height: 40%;
  // border-style: dashed;
  // border-color: coral;
  font-size: x-small;
  font-weight: bold;
}
.carViewTitle-mdAndUp {
  order: 1;
  margin-top: 0%;
  margin-left: 0%;
  align-self: center;
  justify-self: center;
  width: 100%;
  height: 40%;
  // border-style: dashed;
  // border-color: coral;
  font-size: medium;
  font-weight: bold;
}
.container-12-mdAndUp {
  order: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  align-content: center;
  justify-content: center;
  justify-items: center;
  width: 100%;
  // height: 50%;
  margin-top: 0%;
  margin-left: 1%;
  margin-bottom: 0%;
  // border-style: dashed;
}
.container-12-smAndDown {
  order: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  width: 100%;
  margin-top: 0%;
  margin-left: 1%;
  margin-bottom: 0%;
  // border-style: dashed;
  // border-color: chocolate;
}
.CardViewLocalization-mdAndUp {
  order: 1;
  width: 55%;
  // max-height: 50%;
  height: 55%;
  // border-style: dotted;
  // border-color: chocolate;
  margin: 0%;
  font-size: medium;
  
}
.CardViewLocalization-smAndDown {
  order: 1;
  width: 55%;
  // height: 20%;
  // border-style: dotted;
  // border-color: chocolate;
  margin: 0%;
  font-size: x-small;
}
.BtnConainer-mdAndUp {
  order: 2;
  display: flex;
  flex-direction: row;
  margin-left: 0%;
  width: 45%;
  // border-style: dotted;
  // border-color: aqua;
}
.BtnConainer-smAndDown {
  order: 2;
  display: flex;
  flex-direction: row;
  margin-left: 0%;
  width: 45%;
  // border-style: dotted;
  // border-color: chocolate;
}
.CardViewSignMinus-mdAndUp {
  order: 1;
  background: $color-secondary;
  color: $color-surface;
  min-width: 0%;
  min-height: 0%;
  width: fit-content;
  height: fit-content;
  margin-top: 0%;
  margin-left: 0%;
  padding-left: 9%;
  padding-right: 9%;
  padding-top: 1%;
  padding-bottom: 1%;
}

.CardViewSignMinus-smAndDown {
  order: 1;
  background: $color-secondary;
  color: $color-surface;
  min-width: 0%;
  min-height: 0%;
  width: fit-content;
  height: fit-content;
  margin-top: 0%;
  margin-left: 0%;
  padding-left: 9%;
  padding-right: 9%;
  padding-top: 1%;
  padding-bottom: 1%;
}

.cardViewNbrofunits-mdAndUp {
  order: 2;
  background: $color-secondary;
  color: $color-surface;
  min-width: 0%;
  min-height: 0%;
  width: fit-content;
  height: fit-content;
  margin-top: 0%;
  margin-right: 2%;
  margin-left: 2%;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 35%;
}

.cardViewNbrofunits-smAndDown {
  order: 2;
  background: $color-secondary;
  color: $color-surface;
  min-width: 0%;
  min-height: 0%;
  width: fit-content;
  height: fit-content;
  margin-top: 0%;
  margin-right: 2%;
  margin-left: 2%;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 35%;
}

.CardViewSignPlus-mdAndUp {
  order: 3;
  background: $color-secondary;
  color: $color-surface;
  min-width: 0%;
  width: fit-content;
  height: fit-content;
  margin-top: 0%;
  margin-right: 5%;
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 1%;
  padding-bottom: 1%;
}

.CardViewSignPlus-smAndDown {
  order: 3;
  background: $color-secondary;
  color: $color-surface;
  min-width: 0%;
  width: fit-content;
  height: fit-content;
  margin-top: 0%;
  margin-right: 5%;
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 1%;
  padding-bottom: 1%;
}
.container-13 {
  order: 3;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-wrap: nowrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-items: flex-start;
  justify-content: space-between;
  min-height: 0%;
  min-width: 0%;
  // width: 100%;
  // height: 3%;
  margin-top: 0%;
  // margin-left: 3%;
  margin-bottom: 0%;
  // border-style: dotted;
  // border-color: cornflowerblue;
}
.CardViewDayBtn-Active-mdAndUp {
  min-width: 0%;
  min-height: 0%;

  width: 19%;
  height: 50%;
  margin-top: 0%;
  margin-right: 2.5%;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 1%;
  padding-bottom: 1%;

  border: thin solid #fff;
  border-style: groove;
  color: $color-surface;
  background-color: $color-secondary;
  font-size: xx-small;
}
.CardViewDayBtn-Active-smAndDown {
  min-width: 0%;
  min-height: 0%;

  width: 3%;
  height: 50%;
  margin-top: 0%;
  margin-right: 2.5%;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 1%;
  padding-bottom: 1%;

  border: thin solid #fff;
  border-style: groove;
  color: $color-surface;
  background-color: $color-secondary;
  font-size: xx-small;
}
.CardViewDayBtn-InActive-mdAndUp {
  min-width: 0%;
  min-height: 0%;

  width: 19%;
  height: 50%;
  margin-top: 0%;
  margin-right: 2.5%;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 1%;
  padding-bottom: 1%;

  border: thin solid;
  border-style: dashed;
  border-color: $color-secondary;
  background-color: $color-surface;
  font-size: xx-small;
}
.CardViewDayBtn-InActive-smAndDown {
  min-width: 0%;
  min-height: 0%;

  width: 3%;
  height: 50%;
  margin-top: 0%;
  margin-right: 2.5%;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 1%;
  padding-bottom: 1%;

  border: thin solid;
  border-style: dashed;
  border-color: $color-secondary;
  background-color: $color-surface;
  font-size: xx-small;
}


.container-14 {
  order: 4;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  // justify-content: flex-start;
  // align-content: flex-start;
  // justify-items: flex-start;
  // // margin-top: auto;
  // margin-bottom: auto;
  // flex-grow: 0;
  // flex-shrink: 0;
  width: 100%;
  // border-style: dotted;
}
.Box-141-mdAndUp {
  order: 1;
  flex-wrap: nowrap;
  // align-self: flex-start;  
  margin-left: 1%;
  margin-right: 0%;
  width: 50%;
  font-size: large;
  // border-style: dashed;
  // border-color: darkgoldenrod;
}
.Box-141-smAndDown {
  order: 1;
  flex-wrap: nowrap;
  // align-self: flex-start;  
  margin-left: 0%;
  margin-right: 0%;
  width: 50%;
  // margin-top: 2%;
  font-size: small;
}
.Box-142-mdAndUp {
  order: 2;
  // align-self: flex-start;  
  // min-width: 0%;
  // min-height: 0%;
  width: 40%;
  // height: 10%;
  margin-top: 1%;
  margin-left: 0%;
  // border-style: dashed;
  // border-color: darkkhaki;
  font-size: small;
}
.Box-142-smAndDown {
  order: 2;
  // align-self: flex-start;  
  min-width: 0%;
  min-height: 0%;
  width: 40%;
  height: 100%;
  margin-top: 2%;
  margin-left: 20;

  font-size: small;
}
.CardViewAddBtn-mdAndUp {
  // min-width: 0%;
  min-height: 0%;
  width: 100%;
  height: 20px;
  border-radius: 8%;
  margin-left: 0%;
  // margin-top: 0%;
  background: $color-primary;
  color: $color-on-primary;
  font-size: small;
}
.CardViewAddBtn-smAndDown {
  // min-width: 0%;
  min-height: 0%;
  width: 100%;
  height: 20px;
  border-radius: 8%;
  margin-left: 10%;
  // justify-self: center;
  // margin-top: 0%;
  background: $color-primary;
  color: $color-on-primary;
  font-size: xx-small;
}
</style>
