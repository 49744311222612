import { createApp } from "vue";
import router from "./router/index";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";

import SoftDevConfigs from "../configurations/Configs.js";
import App from "./App.vue";
import store from "@/store/index"

/// @brief: improve loading fonts? or just docuemnt this ?
loadFonts();

const oApp = createApp(App)
  .use(router)
  .use(vuetify, {
    options: {
      customProperties: true, // necessary to refer to theme vuetify defined props in local styles https://stackoverflow.com/questions/48280990/using-custom-theming-in-vuetify-and-pass-color-variables-to-components
    },
  })
  .use(store);

// import ONLY really global constants globally
oApp.config.globalProperties.m_oSoftDevConfigs = SoftDevConfigs();

oApp.mount("#app");
