const enWeekDays_t = Object.freeze({
  Unknown: 0,
  eMonday: 1,
  eTuesday: 2,
  eWednesday: 3,
  eThursday: 4,
  eFriday: 5,
  eSaturday: 6,
  eSunday: 7,
  eInValid: 255,
});

/// @brief: Define the maximum MenuCategory constant
const SOFTCONFIG_DEV_WEEKDAYS_MAX = enWeekDays_t.eSunday;
/// @brief: Define the minimum MenuCategory constant
const SOFTCONFIG_DEV_WEEKDAYS_MIN = enWeekDays_t.eMonday;

const SOFTCONFIG_DEV_WEEKDAYS = Object.freeze([
  "Inconnue",
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
  "Non Valide",
]);

/**
 * @brief: this constant defines how many characters we want to use to show
 * a day label/name on a reduced view form(card view))
 */
 const SOFTCONFIG_DEV_WEEKDAYS_SHORT_LENGTH = 3;
/**
 * @brief Convert the enum enWeekDays_t to string,
 * The string returned is based on the value of the enWeekDays_t defined.
 * @param {enWeekDays_t} i_eWeekDay -> The WeekDay to be converted to string
 * @returns {String}
 */
const toString = (i_eWeekDay) => {
  let strWeekDay;
  if (
    i_eWeekDay < SOFTCONFIG_DEV_WEEKDAYS_MIN ||
    i_eWeekDay > SOFTCONFIG_DEV_WEEKDAYS_MAX
  ) {
    strWeekDay = SOFTCONFIG_DEV_WEEKDAYS[SOFTCONFIG_DEV_WEEKDAYS.length - 1];
  } else {
    strWeekDay = SOFTCONFIG_DEV_WEEKDAYS[i_eWeekDay];
  }
  return strWeekDay;
};

/**
 * @brief Convert string to the proper enum enWeekDays_t if applicable,
 * The enum returned is based on the value of the string provided.
 * @param {String} i_strWeekDay -> The WeekDay to be converted to enWeekDays_t
 * @returns {enWeekDays_t}
 */
const fromString = (i_strWeekDay) => {
  let eResult = enWeekDays_t.eInValid;
  let enumValues = Object.values(enWeekDays_t);
  let enumKeys = Object.keys(enWeekDays_t);
  let index = SOFTCONFIG_DEV_WEEKDAYS.indexOf(i_strWeekDay);
  if (
    -1 < index &&
    enumValues.includes(index + 1) &&
    SOFTCONFIG_DEV_WEEKDAYS_MIN <= index + 1 &&
    SOFTCONFIG_DEV_WEEKDAYS_MAX >= index + 1
  ) {
    eResult = enumKeys[index];
  }

  return eResult;
};
/**
 * @brief Convert string to the proper enum enWeekDay if applicable,
 * The enum returned is based on the value of the string provided.
 * @param {number} i_uWeekDay -> The WeekDay to be converted to enWeekDays_t
 * @returns {enMenuCategory_t}
 */
const fromNumber = (i_uWeekDay) => {
  let eResult = enWeekDays_t.eInValid;
  if (
    SOFTCONFIG_DEV_WEEKDAYS_MIN <= i_uWeekDay &&
    SOFTCONFIG_DEV_WEEKDAYS_MAX >= i_uWeekDay
  ) {
    let enumValues = Object.values(enWeekDays_t);
    let enumKeys = Object.keys(enWeekDays_t);
    let index = enumValues.indexOf(i_uWeekDay);
    if (-1 < index) {
      eResult = enumKeys[index];
    }
  }
  return eResult;
};

export {
  enWeekDays_t,
  SOFTCONFIG_DEV_WEEKDAYS,
  SOFTCONFIG_DEV_WEEKDAYS_SHORT_LENGTH,
  SOFTCONFIG_DEV_WEEKDAYS_MIN,
  SOFTCONFIG_DEV_WEEKDAYS_MAX,
  toString,
  fromString,
  fromNumber,
};
