/**
 * @brief: main vue js project application root file
 * @details: the starting route and general app front end
 * home rendering implementation will be starting from here 
 * in a simple, short, modular and easily readable fashion.
 */
<template>
  <!-- 
    PreLoad Key fonts Best practices: https://blog.logrocket.com/best-practices-for-loading-fonts-in-vue/ 
    TODO: analyse key modules to be preloaded and improve(also the location to preload them as well as the preloader)
  -->
  <!-- main application front end  -->
  <v-app>
    <!-- Top Navigation bar -->
    <MainNavBar :m_bIsMobile="isMobile()" :m_bDisplayAttributes=getDisplayAttributes() />
    
    <!-- insert link to ()"HomeScreen") compnent that must be viewed when on root path "/" -->
    <router-view :m_bIsMobile="isMobile()" :m_bDisplayAttributes="getDisplayAttributes()"/>
  </v-app>
</template>

<script>
import { onMounted } from "vue";
import { useDisplay } from "vuetify";

import MainNavBar from "./components/MainNavBar.vue";

export default {
  name: "App",

  components: {
    MainNavBar,
  },

  computed: {},

  data: () => ({}),

  //Use setup option as the entry point to the Composition API
  setup() {

    
    /** THE FOLLOWING FEATURES HELP DETERMINING APPLICATIONS'S DISPLAY INFORMATION */
    // refeence allowing us to know , if this is a mobile or not
    const { mobile, name  } = useDisplay();
    

    onMounted(() => {
      // console.log("This is a mobile? " + mobile.value); // TODO: delete this line if not used. prio 2
    });

    function isMobile() {
      
      return mobile.value;
    }
    
    
    /**
     * @brief: use vue useDisplay features to provide attributes necessary for responsiveness
     * @details
     * //TODO: prio 7: remove isMobile?
     */
    function getDisplayAttributes() {
      // console.log(name)
      return { name: name.value, 
        xs: 'xs'==name.value,
        sm: 'sm'==name.value,
        md: 'md'==name.value,
        lg: 'lg'==name.value,
        xl: 'xl'==name.value,
      };
      // const instance = getCurrentInstance();
      // console.log(instance.proxy.$vuetify);
      // return  instance.proxy.$vuetify;
    }

    return { isMobile, getDisplayAttributes};
  },

};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss"; // $text-primary would be defined in that file

</style>
