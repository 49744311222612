const enCardMotionStatus_t = Object.freeze({
  eCardMotionStatus_none: 0,
  eCardMotionStatus_Left: 1,// moving left(after left arrow was pressed)
  eCardMotionStatus_Right: 2,// moving right(after right arrow was pressed)
  eCardMotionStatus_afterLeft: 3,// cycling right  to back of track after left arrow was pressed
  eCardMotionStatus_afterRight: 4,// cycling left  to front of track after right arrow was pressed
  eCardMotionStatus_afterLeftRight: 5,// cycling right  to back of track after left arrow was pressed
  eCardMotionStatus_afterRightLeft: 6,// cycling left  to front of track after right arrow was pressed
  eCardMotionStatus_Invalid: 255,
});

// /// @brief: Define the maximum orderStatus constant
// const SOFTCONFIG_DEV_ORDERSTATUS_MAX = enCardMotionStatus_t.eCardMotionStatus_Closed;
// /// @brief: Define the minimum orderStatus constant
// const SOFTCONFIG_DEV_ORDERSTATUS_MIN = enCardMotionStatus_t.eCardMotionStatus_Created;


export {
  enCardMotionStatus_t,
  
};
