import { createRouter, createWebHistory } from 'vue-router'

// import pagesPaths from '../../configurations/Configs.js'
import HomeScreen from '../../view/Home/HomeScreen.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeScreen
  },
  {
    path: '/ShoppingCart',//TODO: use global constants prio 7
    name: 'shoppingcart',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: 'about' */ '../../view/ShoppingCart/ShoppingCartScreen.vue')
  },
  {
    path: '/Restaurants',
    name: 'restaurants',
    component: () => import(/* webpackChunkName: 'about' */ '../../view/Restaurants/RestaurantsScreen.vue')
  },
  {
    path: '/Orders',
    name: 'orders',
    component: () => import(/* webpackChunkName: 'about' */ '../../view/Orders/OrdersScreen.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
})

export default router
