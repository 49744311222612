/** 
 * @brief: import vuetify settings, stles, material designs to define app design system app wide
 * @details:
 * https://vuetifyjs.com/en/styles/colors/#material-colors
 * https://vuetifyjs.com/en/features/theme/#customizing
 * https://www.rapidtables.com/convert/color/rgb-to-hex.html
 */
// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import { aliases, mdi } from 'vuetify/iconsets/mdi'

// Vuetify
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

//TODO: https://www.npmjs.com/package/storybook-addon-vuetify3?activeTab=readme prio 10
export const defaultLightandDarkTheme = {
  dark: false,
  colors: {
    background: "#FFFFFF",
    'on-background': "#00609C",
    surface: "#FFFFFF", 
    'on-surface': "#3D7CC9",
    primary: "#FF4713", //dark orange graphic charter Aturdo (255,71,19)
    'on-primary': "#FFFFFF",
    // "primary-darken-1": "#00609C",
    secondary: "#3D7CC9", // blue graphic charter Aturdo (61,124,201)
    'on-secondary': "#FFFFFF",
    // "secondary-darken-1": "#FF4713",

    accent: "#00609C", // dark blue graphic charter Aturdo (0,96,156)
    error: "#FF5252",
    'on-error': "#FFFFFF",
    info: "#FF9015", // orange graphic charter Aturdo (255,144,21)
    'on-info': "#FFFFFF",
    success: "#4CAF50", 
    'on-success': "#FFFFFF",
    warning: "#FFC107",
    'on-warning': "#FFFFFF",
  },
};

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {
    components,
    directives,
    theme: {
      defaultTheme: "defaultLightandDarkTheme",
      themes: {
        defaultLightandDarkTheme,
      },
    },
    icons: {
      defaultSet: 'mdi',
      aliases,
      sets: {
        mdi,
      }
    },

    // the thresholds option modifies the values used for viewport calculations
    // https://next.vuetifyjs.com/en/features/display-and-platform/
    // xs: 0sm: 600md: 960lg:1280 xl: 1920xxl: 2560
    display: {
      mobileBreakpoint: 'md',
      // thresholds: {
      //   xs: 0,
      //   sm: 340,
      //   md: 540,
      //   lg: 800,
      //   xl: 1280,
      //   xxl: 2560
      // },
    },

  }
);
