/* import Restaurants from './Restaurants'
import RestaurantsStorage from './RestaurantsStorage'
undefined */
import { createStore, createLogger } from 'vuex'
import ShoppingCart from './ShoppingCartStorage/index'

const debug = process.env.NODE_ENV !== 'production' //TODO: logging concept //TODO: activate this ony in debg mode prio 6

export default createStore({
  modules: {
    ShoppingCart,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})