<template>
  <span :class="{
    'container-smAndDown': m_bIsMobile,
    'container-mdAndUp': !m_bIsMobile,
  }">

    <!-- Home Research input field TODO: make texts dynamic from configurations TODO: Implement callback prio 3-->
      <span :style="m_pSearchBoxStyle">
        <v-responsive :style="m_pSearchResponsiveStyle"> 
            <v-text-field :style="m_pSearchInputStyle"
              :label=m_strHint 
              :rules="m_oRules" 
              v-model="m_strText"
              hide-details="auto"  
              v-on:keyup.enter="onSearchClicked"
              solo  >
            </v-text-field>  
        </v-responsive>
      </span>


      <span :class="{
            'searchBtnBox-smAndDown': m_bIsMobile,
            'searchBtnBox-mdAndUp': !m_bIsMobile,
          }">
      <!-- Using a dynamic class for hover effect on pc -->

      <v-card class="SearchBtnCard-smAndDown" elevation="0" @click="onSearchClicked" v-if="m_bIsMobile">
        <v-img src="@/assets/images/icons/loupe.svg" contain> </v-img>
      </v-card>

      <v-hover v-slot="{ isHovering, props }" v-else>
        <v-card class="SearchBtnCard-mdAndUp" v-bind="props" :elevation="isHovering ? 6 : 0" flat
          @click="onSearchClicked">
          <v-img src="@/assets/images/icons/loupe.svg" contain> </v-img>
        </v-card>
      </v-hover>
      <router-view />

    </span>
  </span>
</template>

<script>
import { ref } from "vue";
import { useRouter } from 'vue-router';


import SoftDevConfigs from "../../configurations/Configs.js";



export default {
  name: "landingPageBanner",
  components: {},

  props: {
    /**
     * @brief property for the component to know weither the device is a mobile
     */
    m_bIsMobile: {
      type: Boolean,
      required: true,
    },
    /**
      * @brief property for the component to know attributes necessary for responsiveness
      */
    m_bDisplayAttributes: {
      required: true,
    },
  },

  /**
    * @brief explicite declaration of events for proper documentation and visibility 
    * (and some maybe unknown reasons)
    */
    emits: [ SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent],

  /**
   * @brief composition API beforeCreate and data replacement
   */
  setup(props, {emit}) {

    /**
     * @brief
     * @details
     * //TODO: config and/or unit/integration tests must match this to top nav bar height    prio 6
     */
    function getTopMargininPercent() {
      let top = 4;
      let left = 0;
      let height = 100;
      let font = "xx-small";
      let width = 80;

      // console.log(props.m_bDisplayAttributes.name)
      //TODO: prio 6 some hard coded consts here are taken from MainnavBar component height and must be imprived
      switch (props.m_bDisplayAttributes.name) {
        case 'xs': {
          top = 3;
          height = 25*window.outerHeight/653; // 25% was optimal for heigth 653
          width = 59;
          break;
        }
        case 'sm': {
          top = 4;
          height = 60;
          width = 59;
          break;
        }
        case 'md': {
          height = 90;
          width = 87;
          break;
        }
        case 'lg': {
          top = 5;
          height = 95;
          width = 100;
          left = 5;
          break;
        }
        case 'xl': {
          height = 100;
          width = 90;
          top = 5;
          break;
        }
        case 'xxl': {
          height = 140;
          width = 90;
          top = 5;
          break;
        }
      }
      return {
        top: top,
        left: left,
        height: height,
        width: width,
        font: font,
      };
    }

    const m_pSearchBoxStyle = ref({
      order: 1,
      display: "flex",
      "flex-direction": "column",
      "margin-top": getTopMargininPercent().top.toString() + "% !important",
      "margin-left": getTopMargininPercent().left.toString() + "% !important",
      width: getTopMargininPercent().width.toString() + "% !important",
      height: getTopMargininPercent().height.toString() + "px",
      "font-size": getTopMargininPercent().font,
      // "border-style": "dashed",
      // "border-color": "red",
      background: "transparent !important",
    });


    function getResponsiveInputAttributes() {
      let top = 0;
      let left = 0;
      let height = 100;
      let font = "xx-small";
      let width = 80;

      // console.log(props.m_bDisplayAttributes.name)
      //TODO: prio 6 some hard coded consts here are taken from MainnavBar component height and must be imprived
      switch (props.m_bDisplayAttributes.name) {
        case 'xs': {
          top = 2.25;
          height = 100;
          width = 85;
          left = 5;
          break;
        }
        case 'sm': {
          top = 0;
          height = 80;
          width = 90;
          left = 5;
          break;
        }
        case 'md': {
          height = 90;
          width = 90;
          break;
        }
        case 'lg': {
          height = 95;
          width = 90;
          break;
        }
        case 'xl': {
          height = 150;
          width = 90;
          top = 0;
          left = 5;
          break;
        }
        case 'xxl': {
          height = 150;
          width = 90;
          top = 0;
          left = 5;
          break;
        }
      }
      return {
        top: top,
        left: left,
        height: height,
        width: width,
        font: font,
      };
    }
    const m_pSearchResponsiveStyle = ref({
      Order: 1,
      "margin-top": getResponsiveInputAttributes().top.toString() + "% !important",
      "margin-left": getResponsiveInputAttributes().left.toString() + "% !important",
      width: getResponsiveInputAttributes().width.toString() + "% !important",
      height: getResponsiveInputAttributes().height.toString() + "%",
      "font-size": getResponsiveInputAttributes().font,
      // "border-style": "dashed",
      // "border-color": "black",
      background: "transparent !important",
      "max-width": getResponsiveInputAttributes().width.toString() + "% !important",
      "max-height": getResponsiveInputAttributes().height.toString() + "% !important",
      // "padding-top": "15%",
    });

    
    function getTopMargininPercent3() {
      let top = 0;
      let left = 0;
      let height = 100;
      let font = "xx-large";
      let width = 100;

      // console.log(props.m_bDisplayAttributes.name)
      //TODO: prio 6 some hard coded consts here are taken from MainnavBar component height and must be imprived
      switch (props.m_bDisplayAttributes.name) {
        case 'xs': {
          top = -10;
          height = 50;
          width = 100;
          break;
        }
        case 'sm': {
          top = 0;
          height = 80;
          width = 100;
          break;
        }
        case 'md': {
          left = 10;
          height = 90;
          width = 100;
          break;
        }
        case 'lg': {
          height = 100;
          width = 100;
          break;
        }
        case 'xl': {
          height = 150;
          width = 100;
          top = 0;
          break;
        }
        case 'xxl': {
          height = 150;
          width = 100;
          top = 0;
          break;
        }
      }
      return {
        top: top,
        left: left,
        height: height,
        width: width,
        font: font,
      };
    }
    
    const m_pSearchInputStyle = ref({

      "margin-top": getTopMargininPercent3().top.toString() + "% !important",
      "margin-left": getTopMargininPercent3().left.toString() + "% !important",
      width: getTopMargininPercent3().width.toString() + "% !important",
      height: getTopMargininPercent3().height.toString() + "%",
      "font-size":"0.5%!important",// getTopMargininPercent3().font,
      color: "rgb(0, 97, 156) !important",
      // "border-style": "double",
      // "border-color": "white",
      // background: "transparent !important",
      "min-width": "0%",
      "min-height": "0%",
      // "border-radius": "50%",
      // "padding": "15%",
    });


    // use m_oRouter configuration
    const m_oRouter = useRouter()

    const m_eProductCategory = ref(0);
    const items = ref([]);
    const m_strHint = props.m_bIsMobile ?
            'Entrez un repas ici!': 
            'Pour chercher, entrez un nom de repas ici!';
    /**
     * @brief reference to research bvar main text input(double way binding)
     */
    const m_strText = ref("");

    /**
     * @brief rules validating the research text input
     * @details the search text must be at least 3 characters long
     */
    const m_oRules = [
      (value) => !!value || "Required.",
      (value) => (value && value.length >= 3) || "Min 3 characters",
    ];

     /**
      * @brief emit menu item data to restaurant's screen view model 
      * @details this will trigger the item to be sent via signal emission to restaurants screen
      * main parent view model.
      */ 
      function emitData(){

      emit(SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent, {
        m_strUsecase: SoftDevConfigs().CST_EVENT_NAMES.m_strSearchandFilterRequest,
        m_strFilter: m_strText.value
      });
    }


    /**
     * @brief called when pressed on search icon
     * @details the method will either trigger a search or launch navigation
     * to a private page
     * this is a method of SearchFieldViewModel
     */
    function onSearchClicked() {// SoftDevConfigs().m_sPagePaths.m_strhome

      if (SoftDevConfigs().m_sInternCodes.m_strRestaurants === m_strText.value) {
        m_oRouter.push({ name: 'restaurants', })
      }
      else if (SoftDevConfigs().m_sInternCodes.m_strOrders === m_strText.value) {
        m_oRouter.push({ name: 'orders', })
      } else {
        emitData();
      }

    }
    

    return {
      m_pSearchBoxStyle,
      m_pSearchResponsiveStyle,
      m_pSearchInputStyle,

      m_oRouter,

      m_oRules,

      m_strText,
      m_strHint,
      m_eProductCategory,
      items,

      onSearchClicked
    };
  },
};
</script>

<!-- id selector -> class selector -> element selector -->
<style lang="scss" scoped>
@import "@/styles/variables.scss"; // $text-primary would be defined in that file

.container-mdAndUp {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  // border-style: dashed;
  // border-color: red;
}

.container-smAndDown {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  justify-items: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.SearchInput-mdAndUp {
  order: 1;
  margin-top: 5% !important;
  margin-left: 3% !important;
  width: 80%;
  height: 100%;
}

.SearchInput-smAndDown {
  order: 1;
  margin-top: 1.5% !important;
  margin-left: 2% !important;
  width: 53%;
  height: 85%;
}
.text-field .v-input__slot {
  // padding-top: -30%;
  min-height: 0%;
  // padding: -10%;
  height: 20%;
  // min-width: 0%;
  // width: 95%;
  // // size: 10%;
  // // font-size: 100%;
  font-size: xx-small;
  // outline: none;
  // background: transparent !important;
}
// this is a general overwritte of v-text-field font size



.text-field-mdAndUp {
  min-height: 0%;
  padding: -10%;
  height: 60%;
  min-width: 0%;
  width: 95%;
  size: 10%;
  font-size: "large";
}

.text-field-smAndDown {
  min-height: 0%;
  height: 60%;
  min-width: 0%;
  width: 100%;
  size: 10%;
  font-size: "xx-small";
}

.searchBtnBox-mdAndUp {
  order: 2;
  width: 18%;
  height: auto;
  margin-top: 4%;
  margin-left: 0% !important;
}

.searchBtnBox-smAndDown {
  order: 2;
  width: 30%;
  height: 90%;
  margin-top: 0%;
  margin-left: 0% !important;
  // border-style: dotted;
  // border-color: chartreuse;
}

.SearchBtnCard-mdAndUp {
  width: 70%;
  height: 100%;
  margin-top: 0%;
  margin-left: 0% !important;
  /* background: none; */
  color: "transparent";
  background-color: transparent;
}

.SearchBtnCard-smAndDown {
  width: 30%;
  height: 80%;
  margin-top: 15%;
  margin-left: 0% !important;
  /* background: none; */
  color: "transparent";
  background-color: transparent;
  border-color: transparent !important;
  /* border-radius: 30%; */
}
</style>
