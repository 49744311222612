/** * * @brief single card view rendering on the restaurants' screen * @details * this
is a modular reusable implementation of each * shopping item (carousel element)
* on the landing page */
<template  >
  <!-- main card view shape and ids for styling-. "contain" key word IS QUITE IMPORTANT -->

  <v-card :class="{ 'rounded-card-smAndDown': m_bIsMobile, 'rounded-card-mdAndUp': !m_bIsMobile }" elevation="10" fluid>
    <DynamicMenuItemImage class="Img"  :m_bIsMobile="m_bIsMobile" :m_bDisplayAttributes=m_bDisplayAttributes
     :m_oMenuItemData="m_oMenuItemData" :m_eActionModes=3 />

    <!-- card view main label(name of the menu item) -->
    <span class="Container-1">
      <span :class="{
        'carViewTitle-smAndDown': m_bIsMobile,
        'carViewTitle-mdAndUp': !m_bIsMobile}">
        <h5 v-if="m_bIsMobile">{{ m_oMenuItemData.m_strMenuItemName }}</h5>
        <h3 v-else>{{ m_oMenuItemData.m_strMenuItemName.substr(0, m_uLabel_NbrofChars) }}</h3>
      </span>
      <!-- key card view row ui containing location and the numbe rof units manipulation buttons -->
      <span :class="{ 'container-12-smAndDown': m_bIsMobile, 'container-12-mdAndUp': !m_bIsMobile }"  >
        <span :class="{ 'CardViewLocalization-smAndDown': m_bIsMobile, 'CardViewLocalization-mdAndUp': !m_bIsMobile }">
          <body>{{ m_oMenuItemData.m_strLocalization.substr(0, m_uLocalization_NbrofChars) }}</body>
        </span>
        <span :class="{ 'BtnConainer-smAndDown': m_bIsMobile, 'BtnConainer-mdAndUp': !m_bIsMobile }">
          <v-btn :class="{ 'CardViewSignMinus-smAndDown': m_bIsMobile, 
                           'CardViewSignMinus-mdAndUp': !m_bIsMobile }"
                  id="minusButtonId" @click="onChangeNumber">
            -
          </v-btn><!-- //TODO: improve hard coded constants prio 10 "-" and "+" maybe use props to set those const as text for button too instead-->
          <v-btn :class="{ 'cardViewNbrofunits-smAndDown': m_bIsMobile, 
                           'cardViewNbrofunits-mdAndUp': !m_bIsMobile }">
                           {{m_uNbrofUnits}}
          </v-btn>
          <v-btn :class="{ 'CardViewSignPlus-smAndDown': m_bIsMobile, 
                           'CardViewSignPlus-mdAndUp': !m_bIsMobile }"
                  id="plusButtonId" @click="onChangeNumber">
                           +
          </v-btn>
        </span>
      </span>
      <!-- days as buttons for selection of the delivery days -->
      <span class="container-13">
        <v-btn v-for="(button, index) in m_lstDaysButtons" :key="index" 
          :active-class="button.m_bIsActive ? 'active' : 'no-active'" :class="{
            'CardViewDayBtn-Active-smAndDown': m_bIsMobile && button.m_bIsActive,
            'CardViewDayBtn-Active-mdAndUp': !m_bIsMobile && button.m_bIsActive,
            'CardViewDayBtn-InActive-smAndDown': m_bIsMobile && !button.m_bIsActive,
            'CardViewDayBtn-InActive-mdAndUp': !m_bIsMobile && !button.m_bIsActive
          }" outlined @click="onChangedDaySelections(button)">
          {{ button.m_strDayLabel }}
        </v-btn>
      </span>
      <!-- Display of the total price and the call-to-action button -->
      <!-- no v-card-actions, becaus eof the default space its use -->
      <span class="container-14">
        <v-combobox :class="{ 'Box-141-smAndDown': m_bIsMobile, 'Box-141-mdAndUp': !m_bIsMobile }"
          :items="m_lstOrderStatuses"  v-model="m_strOrderStatus"
          label="status"  return-object dense contain>
        </v-combobox>
        <v-text-field :class="{ 'Box-142-smAndDown': m_bIsMobile, 'Box-142-mdAndUp': !m_bIsMobile }"
          label="Autre destination?"
          outlined v-model="m_strDestination"
          @change="onDeliveryDataChanged" >
        </v-text-field>
      </span>
      <span class="container-15">
        <body class="Box-151">
          entre
        </body>
        <v-text-field
          class="Box-152" label="12h" outlined
          @change="onDeliveryDataChanged" v-model="m_strDeliveryTime1"
        ></v-text-field>
        <body class="Box-153">
          et
        </body>
        <v-text-field
          class="Box-154" label="14h" outlined
          @change="onDeliveryDataChanged" v-model="m_strDeliveryTime2"
        ></v-text-field>
      </span>
      <span class="container-16">
        <v-text-field
          class="Box-161" label="modifier le tel?" outlined
          @change="onDeliveryDataChanged" v-model="m_strPhoneNumber"
          :rules="m_oRules" >
        </v-text-field>
      </span>
    </span>
  </v-card>
</template>

<script>
import { ref, watch, onMounted } from "vue";


import SoftDevConfigs from "../../configurations/Configs.js";
import {toString as getWeekDayName, SOFTCONFIG_DEV_WEEKDAYS_SHORT_LENGTH, 
SOFTCONFIG_DEV_WEEKDAYS_MAX, SOFTCONFIG_DEV_WEEKDAYS_MIN} from "@/../Common/commontypes/enums/enWeekDays_t";
import { SOFTCONFIG_DEV_ORDERSTATUS_MIN, SOFTCONFIG_DEV_ORDERSTATUS_MAX,
  fromString as getOrderStatusfromString ,
  toString as getOrderName}//TODO: there should be a single way of importing public items from enums module prio 5
from "@/../Common/commontypes/enums/enOrderStatus_t";
import DynamicMenuItemImage from "../../src/components/DynamicMenuItemImage.vue"

export default {
  name: "HomeScreenCategoryCardView",
  components: {
    DynamicMenuItemImage
  },

  props: {
    /**
     * @brief property for the component to know weither the device is a mobile
     */
    m_bIsMobile: {
      type: Boolean,
      required: true,
    },
    /**
      * @brief property for the component to know attributes necessary for responsiveness
      */
      m_bDisplayAttributes: {
      required: true,
    },
    m_oMenuItemData: {
      required: true
    }
  },


   /**
    * @brief explicite declaration of events for proper documentation and visibility 
    * (and some maybe unknown reasons)
    */
    emits: [ SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent],

  //Use setup option as the entry point to the Composition API
  setup( props, {emit}) {

    
     /**
     * @brief used card width
     * @details
     * it might e an idea to have card width  as parameter prio 10.
     */
     function getLocalizationWidth() {
      let f64Val = SoftDevConfigs().CST_TEXTS.m_strLocationLengthMax;
      switch (props.m_bDisplayAttributes.name) {
        case 'xs': {
          f64Val = f64Val*2/3+2;
          break;
        }
        case 'sm': {
          f64Val = f64Val*2/3+2;
          break;
        }
        case 'md': {
          f64Val = f64Val*2/3+2;
          break;
        }
        case 'lg': {
          f64Val = f64Val*2/3+2;
          break;
        }
        case 'xl': {
          break;
        }
        case 'xxl': {
          break;
        }
      }

      f64Val *= 2; 
      return f64Val;
    }
    console.log(props.m_oMenuItemData)
    const m_uLocalization_NbrofChars = Math.min( getLocalizationWidth(),
      props.m_oMenuItemData.m_strLocalization.length);
    const m_uNMAX_LABEL_LENGTH = 64;
    const m_uLabel_NbrofChars =  Math.min( m_uNMAX_LABEL_LENGTH, 
                                  props.m_oMenuItemData.m_strMenuItemName.length);
    /**
     * @brief list of buttons representing days that can be selected
     * @details the starting states of the buttons are initialized with
     * the same value as within m_oMenuItemData.m_lstDays, knowing that
     * the first weekday enumeration constant is unknown and therewith the position
     * 0 here must be undefined.
     */
     var m_lstDaysButtons = new ref([]);
    for (let i = SOFTCONFIG_DEV_WEEKDAYS_MIN;
         (SOFTCONFIG_DEV_WEEKDAYS_MAX < props.m_oMenuItemData.m_lstDays.length) 
         && (i <= SOFTCONFIG_DEV_WEEKDAYS_MAX);  ++i) 
    {
      // copy current opening day inout state or initialize with true
      m_lstDaysButtons.value.push({
        m_eDay: i,
        m_strDayLabel: getWeekDayName(i).slice(0, SOFTCONFIG_DEV_WEEKDAYS_SHORT_LENGTH),
        m_bIsActive: props.m_oMenuItemData.m_lstDays[i],
      });
    }

    /// @brief holder for binding the text view of the menu item(being created) category
    var m_strOrderStatus = new ref(getOrderName(props.m_oMenuItemData.m_eOrderStatus));
    /**
     * @brief list of buttons representing days that can be selected
     */
    var m_lstOrderStatuses = new ref([]);
    for (let i = SOFTCONFIG_DEV_ORDERSTATUS_MIN; i <= SOFTCONFIG_DEV_ORDERSTATUS_MAX; ++i) {
      m_lstOrderStatuses.value.push({
        title: getOrderName(i),
      });
    }

     /**
      * @brief emit menu item data to restaurant's screen view model 
      * @details this will trigger the item to be sent via signal emission to restaurants screen
      * main parent view model.
      * //TODO: prio 10 implement event validations  IN ALL FILES https://vuejs.org/guide/components/events.html#declaring-emitted-events
      */ 
     function emitData(){
      console.log(m_strOrderStatus)
      console.log(props.m_oMenuItemData)
      console.log(getOrderStatusfromString(m_strOrderStatus.value))
      
      emit(SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent, {
        m_strUsecase: SoftDevConfigs().CST_EVENT_NAMES.m_strUpdateOrderItem,
        m_uOrderId: props.m_oMenuItemData.m_uOrderId,
        m_strBasketItemId_as_DateTime: props.m_oMenuItemData.m_strBasketItemId_as_DateTime,
        m_uMenuItemId: props.m_oMenuItemData.m_uMenuItemId,
        m_eOrderStatus: getOrderStatusfromString(m_strOrderStatus.value),
      });
    }

    /**
     * @brief reference double way binding the editable value for start delivery timerange
     */
    var m_strDeliveryTime1 = ref("");
    /**
     * @brief reference double way binding the editable value for end delivery timerange
     **/
    var m_strDeliveryTime2 = ref("");

    /**
     * @brief member variable implementing a double binding to the view representation of the destination
     * edit field
     **/
    var m_strDestination = ref("");

    var m_strPhoneNumber = ref("");

    /**
     * @brief called at corresponding lifecycle hook
     * @details
     * -initialized delivery times and destinations edit field displayed values.
     * -initialized watcher for order status changes.
     */
    onMounted(() => {
      m_strDestination.value = props.m_oMenuItemData.m_strDestination; 
      const words =  props.m_oMenuItemData.m_strDeliveryTime.split("-");
      m_strDeliveryTime1.value = words[0] ? words[0] : "";
      m_strDeliveryTime2.value = words[1] ? words[1] : "";
      m_strPhoneNumber.value = props.m_oMenuItemData.m_strPhoneNumber; 

      /* watch for changes of these values */
      watch([m_strOrderStatus], () => {
        emitData();
      }, { deep: true })//TODO: deep watch necessary ???? prio 3
    }); 

    /**
     * @brief member variable implementing a double binding to the view representation of the number of units
     *  */
     var m_uNbrofUnits = new ref(parseInt(props.m_oMenuItemData.m_uNbrofUnits));

    /**
     * @brief member variable implementing a double binding to the view representation of total price
     * @details
     * the total price is not a reference here because the double binding of te view element within 
     * the dynamic image component is actually currently linked to the main viwe model data of OrdersScreen.vue
     * //TODO: prio 5 is there a way to improve inserting unitprice/total price withing card views on top of images without having them in the DynamicImageComponent ?is it really better?
     */
    let m_uTotalPrice = 0;
    /**
     * @brief update the view model's number of unit items(therewith also the 
     * displayed number through reactivity)
     */
     function onChangeNumber(event) {
      // `event` is the native DOM event
      let step = 0;
      if (event) {
        if ("plusButtonId" === event.currentTarget.id) {
            step = 1;
        }
        else if ("minusButtonId" === event.currentTarget.id) {//TODO: improve this into a proper not hard coded constant
          step = (0 < m_uNbrofUnits.value) ? -1 : 0;// limit min price to 0
        }
        else {
          console.log("OrdersScreenCardView::onChangeNumber: CRITICAL:DEV: was called from invalid target"
            + event.currentTarget);
        }
        if (0 != step) {
          m_uNbrofUnits.value += step;
          m_uTotalPrice = m_uNbrofUnits.value*parseInt(props.m_oMenuItemData.m_uUnitPrice);


          emit(SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent, {
            m_strUsecase: SoftDevConfigs().CST_EVENT_NAMES.m_strUpdateOrderItem,
            m_uOrderId: props.m_oMenuItemData.m_uOrderId,
            m_uMenuItemId: props.m_oMenuItemData.m_uMenuItemId,
            m_strBasketItemId_as_DateTime: props.m_oMenuItemData.m_strBasketItemId_as_DateTime,
            m_uNbrofUnits: m_uNbrofUnits.value,
            m_uTotalPrice: m_uTotalPrice,
          });
        } else {//TODO: prio 3 comment out this log//TODO: prio 10 keep log in high log debug level
          console.log("OrdersScreenCardView::onChangeNumber:warning: no valid step for"
            + event.currentTarget + " m_uNbrofUnits " + m_uNbrofUnits.value);
        }
      }
      else {
        console.log("HomeScreenCategoryCardView::onChangeNumber: Warning: was called without valid event")
      }
    }

        
    /**
     * @brief update restaurant's screen view model when menu item configuration changes
     * @details this will trigger the item to be sent via signal emission to restaurants screen
     * main parent view model.
     * 
     * 
     */
     function onChangedDaySelections(i_obutton ) {
      
      // `event` is the native DOM event
      if (i_obutton ) {
        i_obutton.m_bIsActive = !i_obutton.m_bIsActive; // switch button stat

        emit(SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent, {
        m_strUsecase: SoftDevConfigs().CST_EVENT_NAMES.m_strUpdateOrderItem,
        m_uOrderId: props.m_oMenuItemData.m_uOrderId,
        m_uMenuItemId: props.m_oMenuItemData.m_uMenuItemId,
        m_strBasketItemId_as_DateTime: props.m_oMenuItemData.m_strBasketItemId_as_DateTime,
        m_eChangedDay: i_obutton.m_eDay,
      });
      }
      else {
        console.log("RestaurantsScreenCardView::onChangedDaySelections: Warning: was called without valid event")
      }
    }

    
    /**
     * @brief callback called hwen any basket item's data has been changed, that is not displayed on 
     * a cardview but withing the current shopping cart item's block
     */
    function onDeliveryDataChanged() {

      emit(SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent, {
        m_strUsecase: SoftDevConfigs().CST_EVENT_NAMES.m_strUpdateOrderItem,
        m_uOrderId: props.m_oMenuItemData.m_uOrderId,
        m_uMenuItemId: props.m_oMenuItemData.m_uMenuItemId,
        m_strBasketItemId_as_DateTime: props.m_oMenuItemData.m_strBasketItemId_as_DateTime,

        m_strDestination: m_strDestination.value,
        m_strDeliveryTime: m_strDeliveryTime1.value + "-" + m_strDeliveryTime2.value, //TODO: improve hard coded constant separator prio 4//TODO: prio 7 improve the implementation of time1+time 2 to combined time as same logic is also used in ShoppingcartStorage and may differ which will cause bugs
        m_strPhoneNumber: m_strPhoneNumber.value,
      });
    }

     
    /**
     * @brief pattern used to validate that a phone number ha sonly numbers
     *  using regular expression
     * //TODO: prio 4 move this const to a more suitable place because it is duplicated in ShoppingCartScreen.vue and later maybe even also in RestaurantsScreen
     */
     const m_strRegExPattern = /^[0-9]*$/;
    /**
     * @brief min number of characters a phone number must have
     * //TODO: prio 4 move this const to a more suitable place because it is duplicated in ShoppingCartScreen.vue and later maybe even also in RestaurantsScreen
     */
    const m_uNMIN_PHONE_NUMBER_LENGTH = 7;
    /**
     * @brief rules to be applied for phone number
     * @details
     * at least 7 characters must be provided for phone number
     * all characters must be numbers
     * the phone number must be provided(mandatory)
     * //TODO: prio 4 improve hard coded constants
     */
     let m_oRules = [
      (value) => !!value || "Required.",
      (value) => m_strRegExPattern.test(value) || "Seulement des chiffres!!!!",
      (value) => (value && m_uNMIN_PHONE_NUMBER_LENGTH <= value.length ) || "Au moins 7 nombres!!!",
      
    ];


    return {
      m_uLabel_NbrofChars,
      m_uLocalization_NbrofChars,
      m_uNbrofUnits,
      onChangeNumber,
      m_lstDaysButtons,
      onChangedDaySelections,
      m_strOrderStatus, m_lstOrderStatuses,
      m_strDestination,
      m_strDeliveryTime1, m_strDeliveryTime2, m_strPhoneNumber,
      onDeliveryDataChanged,
      m_oRules
    }
  },

  // computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss"; // $text-primary would be defined in that file

.rounded-card-mdAndUp {


  outline-width: 5px;
  border-radius: 5%;
  border-color: $color-secondary;
  // padding-left: 0%;
  // padding-right: 0%;
  // min-width: 0%;
  // min-height: 0%;
  width: 100%;
  height: 100%;
  font-family: "Rockwell", sans-serif;
  color: $color-on-surface;
  border: $color-secondary;
  border-style: none;
  box-shadow: $color-secondary;

  // margin-right: 0%;
  // margin-left: 0%;;
}

.rounded-card-smAndDown {

  outline-width: 5px;
  border-radius: 5%;
  border-color: $color-secondary;
  width: 184px;
  height: 175px;
  font-family: "Rockwell", sans-serif;
  color: $color-on-surface;
  border: $color-secondary;
  box-shadow: $color-secondary;

}

.Img {
  max-width: 100% !important;
  max-height: 20% !important;
}

.Container-1 {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: space-evenly;
  align-items: flex-start;
  align-content: flex-start;
  // border-style: dotted;
  // border-color: chartreuse;
}
.carViewTitle-smAndDown {
  order: 1;
  margin-top: 0%;
  margin-left: 0%;
  // border-style: dashed;
}
.carViewTitle-mdAndUp {
  order: 1;
  margin-top: 0%;
  margin-left: 3%;
  align-self: center;
}
h3 {
  color: $color-accent;
  font-size: large;
}
h5 {
  color: $color-accent;
  font-size: x-small;
}
.container-12-mdAndUp {
  order: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  width: 100%;
  // height: 50%;
  margin-top: 0%;
  margin-left: 1%;
  margin-bottom: 0%;
  // border-style: dashed;
}
.container-12-smAndDown {
  order: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: space-between;
  width: 100%;
  margin-top: 0%;
  margin-left: 1%;
  margin-bottom: 0%;
  // border-style: dashed;
  // border-color: chocolate;
}
.CardViewLocalization-mdAndUp {
  order: 1;
  font-size: large;
  width: 65%;
  height: 20%;
  // border-style: dotted;
  // border-color: chocolate;
  margin: 0%;
}
.CardViewLocalization-smAndDown {
  order: 1;
  font-size: x-small;
  width: 65%;
  height: 20%;
  // border-style: dotted;
  // border-color: chocolate;
  margin: 0%;
}
.BtnConainer-mdAndUp {
  order: 2;
  display: flex;
  flex-direction: row;
  margin-left: 0%;
  width: 30%;
  // border-style: dotted;
  // border-color: chocolate;
}
.BtnConainer-smAndDown {
  order: 2;
  display: flex;
  flex-direction: row;
  margin-left: 0%;
  width: 35%;
  // border-style: dotted;
  // border-color: chocolate;
}
.CardViewSignMinus-mdAndUp {
  order: 1;
  background: $color-secondary;
  color: $color-surface;
  min-width: 0%;
  min-height: 0%;
  width: fit-content;
  height: fit-content;
  margin-top: 0%;
  margin-left: 2%;
  padding-left: 9%;
  padding-right: 9%;
  padding-top: 1%;
  padding-bottom: 1%;
}

.CardViewSignMinus-smAndDown {
  order: 1;
  background: $color-secondary;
  color: $color-surface;
  min-width: 0%;
  min-height: 0%;
  width: fit-content;
  height: fit-content;
  margin-top: 0%;
  margin-left: 2%;
  padding-left: 9%;
  padding-right: 9%;
  padding-top: 1%;
  padding-bottom: 1%;
}

.cardViewNbrofunits-mdAndUp {
  order: 2;
  background: $color-secondary;
  color: $color-surface;
  min-width: 0%;
  min-height: 0%;
  width: fit-content;
  height: fit-content;
  margin-top: 0%;
  margin-right: 2%;
  margin-left: 2%;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 35%;
}

.cardViewNbrofunits-smAndDown {
  order: 2;
  background: $color-secondary;
  color: $color-surface;
  min-width: 0%;
  min-height: 0%;
  width: fit-content;
  height: fit-content;
  margin-top: 0%;
  margin-right: 2%;
  margin-left: 2%;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 1%;
  padding-bottom: 1%;
  border-radius: 35%;
}

.CardViewSignPlus-mdAndUp {
  order: 3;
  background: $color-secondary;
  color: $color-surface;
  min-width: 0%;
  width: fit-content;
  height: fit-content;
  margin-top: 0%;
  margin-right: 5%;
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 1%;
  padding-bottom: 1%;
}

.CardViewSignPlus-smAndDown {
  order: 3;
  background: $color-secondary;
  color: $color-surface;
  min-width: 0%;
  width: fit-content;
  height: fit-content;
  margin-top: 0%;
  margin-right: 5%;
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 1%;
  padding-bottom: 1%;
}
.container-13 {
  order: 3;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-wrap: nowrap;
  align-items: flex-start;
  align-content:flex-start ;
  justify-items: flex-start;
  justify-content: space-between;
  min-height: 0%;
  min-width: 0%;
  // width: 100%;
  // height: 3%;
  margin-top: 0%;
  // margin-left: 3%;
  margin-bottom: 0%;
  // border-style: dotted;
  // border-color: chocolate;
}
.CardViewDayBtn-Active-mdAndUp {
  min-width: 0%;
  min-height: 0%;
  
  width: 19%;
  height: 50%;
  margin-top: 0%;
  margin-right: 2.5%;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 1%;
  padding-bottom: 1%;
  
  border: thin solid;
  border-style: groove;
  color: $color-surface;
  background-color: $color-secondary;
  
  font-size: xx-small;
}
.CardViewDayBtn-Active-smAndDown {
  min-width: 0%;
  min-height: 0%;
  
  width: 3%;
  height: 50%;
  margin-top: 0%;
  margin-right: 2.5%;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 1%;
  padding-bottom: 1%;
  
  border: thin solid;
  border-style: groove;
  color: $color-surface;
  background-color: $color-secondary;
  font-size: xx-small;
}
.CardViewDayBtn-InActive-mdAndUp {
  min-width: 0%;
  min-height: 0%;
  
  width: 19%;
  height: 50%;
  margin-top: 0%;
  margin-right: 2.5%;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 1%;
  padding-bottom: 1%;
  
  border: thin solid;
  border-style: dashed;
  color: $color-secondary;
  background-color: $color-surface;
  font-size: xx-small;
}
.CardViewDayBtn-InActive-smAndDown {
  min-width: 0%;
  min-height: 0%;
  
  width: 3%;
  height: 50%;
  margin-top: 0%;
  margin-right: 2.5%;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 1%;
  padding-bottom: 1%;
  
  border: thin solid;
  border-style: dashed;
  color: $color-secondary;
  background-color: $color-surface;
  font-size: xx-small;
}

.container-14 {
  order: 4;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
  justify-items: flex-start;
  margin-top: 1%;
  width: 100%;
  
}
.Box-141-mdAndUp {
  order: 1;
  margin-left: 0%;
  margin-bottom: 0%;
  width: 45%;
  font-size: large;
  min-height: 0%!important;
}
.Box-141-smAndDown {
  order: 1;
  margin-left: 0%;
  margin-top: 0%;
  margin-bottom: 0%;
  font-size: small;
  min-height: 0%!important;
}
.Box-142-mdAndUp {
  order: 1;
  margin-left: 0%;
  margin-bottom: 0%;
  width: 45%;
  font-size: large;
  min-height: 0%!important;
}
.Box-142-smAndDown {
  order: 1;
  margin-left: 0%;
  margin-top: 0%;
  margin-bottom: 0%;
  font-size: small;
  min-height: 0%!important;
}

.container-15 {
  order: 5;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: center;
  // align-content: center;
  // justify-items: center;
  margin-top: 0%;
  margin-bottom: 0%;
  width: 100%;
}
.Box-151 {
  order: 1;
  margin-top: 0%;
  margin-left: 1%;
  flex-shrink: 0;
  width: 20%;
  min-width: 0%;
}
.Box-152 {
  order: 2;
  margin-top: 0%;
  margin-right: 1%;
  flex-shrink: 0;
  width: 20%;
  min-width: 0%;
}
.Box-153 {
  order: 3;
  margin-left: 1%;
  margin-top: 0%;
  flex-shrink: 0;
  width: 15%;
  min-width: 0%;
}
.Box-154 {
  order: 4;
  margin-top: 0%;
  margin-right: 1%;
  flex-shrink: 0;
  width: 20%;
  min-width: 0%;
}


.container-16 {
  order: 5;
  display: flex;
  flex-direction: row;
  align-items: center;
  // justify-content: center;
  // align-content: center;
  // justify-items: center;
  margin-top: 0%;
  margin-bottom: 0%;
  width: 95%;
}
.Box-161 {
  order: 1;
  margin-top: 0%;
  margin-left: 5%;
  flex-shrink: 0;
  width: 95%;
  min-width: 0%;
}
</style>
