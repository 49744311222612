/**
 * @brief: "Home Screen"'s card views data class
 * @details: this class describe the features all menu item's card views in the "home screen" must have
 * This file make use of composition api for code reusability and modularity
 * (through which also a kind of extension will be implemented when reusing this in other files)
 * NB: the references allows double way binding of data and thsis composition api based pattern
 * reinforce the fact that each instantiation of this class will be independent from the others
 */

import { ref } from "vue";

import jBaseOrderItemData from "../jBaseOrderItemData";
import {
  SOFTCONFIG_DEV_WEEKDAYS_MAX, SOFTCONFIG_DEV_WEEKDAYS_MIN
} from "@/../Common/commontypes/enums/enWeekDays_t";//TODO: make everything in enums module private that should be private and everything that must be public must e accessible only trhough /enums/index! prio 4
import {
  enViewItemType_t,
  enMenuCategory_t,
} from "@/../Common/commontypes/enums/index";

// will return separate instance of variables for each call
export default function jHomeScreenCategoryItemData(
  i_uMenuItemId = 0,
  i_eViewItemType0 = enViewItemType_t.eViewItemType_Unknown,
  i_strLabel = "",
  uCarouselPosition = 0,
  i_eMenuCategory = enMenuCategory_t.eMenuCategory_Unknown,
  i_strImageSourcePath = "",
  i_strLocalization = "",
  i_uUnitPrice = 0,
  i_uNbrofUnits = 0,
  i_uTotalPrice = 0,
  i_lstDays = [],
  i_strBasketItemId_as_DateTime = 0 // for home screen this is a non mandatory parameter
) {
  // simulate inheritance while using composition app by this call which instantiate base class
  const {
    m_strBasketItemId_as_DateTime,
    m_uMenuItemId,
    m_eCarouselItemType,
    m_strMenuItemName,
    m_uCarouselPosition,
    m_eProductCategory,
    m_strMenuItemImage,
    m_strLocalization,
    m_uUnitPrice,
    m_uNbrofUnits,
    m_uTotalPrice,
  } = jBaseOrderItemData(
    i_strBasketItemId_as_DateTime,
    i_uMenuItemId,
    i_eViewItemType0,
    i_strLabel,
    uCarouselPosition,
    i_eMenuCategory,
    i_strImageSourcePath,
    i_strLocalization,
    i_uUnitPrice,
    i_uNbrofUnits,
    i_uTotalPrice
  );

  /**
   * @brief: vector having as many days as there are configured week days , telling if on
   * each day a meal can be deliverred  or not from the current restaurant
   * @details: the first element represents enWeekDays_t.Unknown and is therewith invalid
   * the starting states of the buttons are either initialized with
   * the same value as within i_lstDays, or with true by default.
   */
  const m_lstDays = ref(
    undefined == i_lstDays || "" == i_lstDays || null == i_lstDays
      ? []
      : i_lstDays
  );
  if (0 === m_lstDays.value.length) m_lstDays.value.push(false);
  for (
    let i = SOFTCONFIG_DEV_WEEKDAYS_MIN;
    i <= SOFTCONFIG_DEV_WEEKDAYS_MAX;
    ++i
  ) {
    // if the number of days is not yet complete(input size of i_lstDays somehow is too small )
    // copy current opening days input states or initialize with true
    const bRes = i_lstDays.length > i && true == i_lstDays[i] ? true : false;
    if (i >= m_lstDays.value.length) m_lstDays.value.push(bRes);
    else m_lstDays.value[i] = bRes;
  }

  return {
    m_uMenuItemId,
    m_eCarouselItemType,
    m_strMenuItemName,
    m_uCarouselPosition,
    m_eProductCategory,
    m_strMenuItemImage,
    m_strLocalization,
    m_uUnitPrice,
    m_uNbrofUnits,
    m_uTotalPrice,
    m_lstDays,
    m_strBasketItemId_as_DateTime, // for home screen the basket id is not mandatory
  };
}
