import { enItemStatus_t } from "@/../Common/commontypes/enums/";
/**
 * @brief global software configurations
 * @details
 *
 * @export
 * @return {*}
 */
export default function SoftDevConfigs() {
  /** ************************* ROUTES AND SCREENS RELATED GLOBAL CONFIGS ******************************** */

  /**
   * @brief globally and really often used paths as strings
   * @details
   */
  var m_sPagePaths = {
    m_strhome: "/",
    m_strShoppingcart: "/ShoppingCart",
    m_strRestaurants: "/Restaurants",
    m_strOrders: "/Orders",
  };

  /**
   * @brief structure containing all labels, which later must be used as litterales and translated
   * @details https://phrase.com/blog/posts/vue-translation-with-vue-i18next/
   */
  var m_sTextLabels = {
    m_strSeeShoppingCart: "Voir Le Panier",
  };

  /**
   * @brief private codes to be used inide the application to trigger internal features
   * @details https://phrase.com/blog/posts/vue-translation-with-vue-i18next/
   */
  var m_sInternCodes = {
    m_strRestaurants: "123 45",
    m_strOrders: "123 46",
  };


  /******************************* REQUESTS PARAMS AND REQUESTS NAMES ************************************** */

  /**
   * @brief constant related to request attributes
   */
   var m_sRequestParams = {
    m_Request: "m_Request",
    m_strMenuItemImage: "m_strMenuItemImage",
    m_uMenuItemId: "m_uMenuItemId",
  };

  /**
   * @brief constant related to request attributes names
   */
   var m_sRequestNames = {
    allOrders: "AllOrders",
    menuItemImg: "MenuItemImg",
    allMenuItems: "AllMenuItems",
    allRestaurants: "AllRestaurants"
  };


  /** ************************* HOSTS AND SERVERS RELATED GLOBAL CONFIGS ******************************** */

  /**
   * @brief server addresses
   */
  var m_oHosts = {
    /// @brief local host for dev and debug usage!NOT FOR PRODUCTION
    m_strHttpLocalHost: "http://localhost/",
    /// @brief php server url to be used when working locally with both web app and php server running locally
    m_strHttpLocalPhpHost: "http://localhost:3000/",
    m_strHttRemoteHost: "https://www.aturdo.com/",
  };

  /**
   * @brief target server's php key configurations
   */
  var m_oPHP = {
    m_strHTTPHandler: "Model/ServerClient/Http/pHttpHandler.php",
  };

  /**
   * @brief http link to server
   */
  var CST_HTTP_SERVER = {
    m_strHost:
      (process.env.NODE_ENV !== "production"
        ? m_oHosts.m_strHttpLocalPhpHost
        : m_oHosts.m_strHttRemoteHost) + m_oPHP.m_strHTTPHandler,
  };

  /** ************************* DATA MODELS  RELATED GLOBAL CONFIGS ******************************** */
  /**
   * @brief http link to server
   */
  var CST_MODEL_DATABASE_TABLES_PRIMARY_KEYS_NUMBER = {
    /**
     *  the column 0(restaurant) (is the single the primary key)
     * **/
    m_nRestaurant: 1,
    /**
     *  the column 0(menu item name) and 1(restaurant) (must be in that
     * order) are the primary keys
     * **/
    m_nMenuItem: 1,
    /**
     *  the column 0(order date time), 1(menu item name) and 2(restaurant) (must be in that
     * order) are the primary keys
     * **/
    m_nOrderItem: 2,
  };
  /**
   * @brief configuration for app custom events emitted within components
   * @details
   * //TODO: some of these events need to be moved to a module maybe? prio 10
   * //TODO: stop event propagation at destination level, every where there is one custom event emitted prio 10
   */
  var CST_EVENT_NAMES = {
    /**
     * @brief name of the event to be sent when an user has changed
     *  a model value
     */
    m_strUserEvent: "CustomAppUserEvent",
    /**
     * @brief parameter identifying the event specific use case as a
     * change of a number of unit
     */
    m_strNbrofUnitEvent: "NbrofUnit",
    /**
     * @brief parameter identifying the event specific use case as an order
     *  item requesting to be added to basket
     */
    m_strAddtoBasket: "Add2Basket",
    /**
     * @brief parameter identifying the event specific use case as a modal requesting
     * to be hidden
     */
    m_strModalHide: "ModalHide",
    /**
     * @brief parameter identifying the event specific use case as an order
     *  item requesting to be added to basket
     */
    m_strAdd2Restaurant: "Add2Restaurant",
    /**
     * @brief parameter identifying the event specific use case as an order
     *  item requesting update of menu item data
     */
    m_strUpdateRestaurantItem: "UpdateRestaurantItem",
    /**
     * @brief parameter identifying the event specific use case as an order
     *  item requesting update of menu item data
     */
    m_strUpdateRestaurant: "UpdateRestaurant",
    /**
     * @brief parameter identifying the event specific use case as an order
     *  item requesting to be added to basket
     */
    m_strSaveRestaurantItemChanges: "SaveRestaurantItemChanges",
    /**
     * @brief parameter identifying the event specific use case as an order
     *  item requesting to be deleted or deactivated
     */
    m_strRemoveItem: "RemoveItem",
    /**
     * @brief parameter identifying the event specific use case as an order
     *  item requesting update of menu item data
     */
    m_strUpdateOrderItem: "UpdateOrderItem",
    /**
     * @brief parameter identifying the event specific use case as an order
     *  item requesting to be added to basket
     */
     m_strSaveOrderItemChanges: "SaveOrderItemChanges",
     /**
     * @brief parameter identifying the event specific use case as a search 
     * request
     */
     m_strSearchandFilterRequest: "SearchandFilter",
     
     
  };

  /**
   * @brief configuration for app custom events emitted within components
   * @details
   * //TODO: some of these events need to be moved to a module maybe? prio 10
   */
  var CST_TEXTS = {
    m_strPriceLengthMax: 6,
    m_strLocationLengthMax: 12,
    m_strImageFileStringDisplayLengthMax: 15
  };

  /**
   * @brief this is an emsemble of constants used in isTemplateNode, whihc cannot be imported
   * the normal way because somehow the template expressions may be evaluated before
   * the import of the conigs are consolidated
   */
  var CST_ENUMS = {
    ITEM_ACTIVATED: enItemStatus_t.eItemStatus_Activated,
    ITEM_DEACTIVATED: enItemStatus_t.eItemStatus_DeActivated,
    ITEM_DELETED: enItemStatus_t.eItemStatus_Deleted,
  };

  /**
   * @brief contsnt related to certain standard image assets
   */
  var CST_IMAGES = {
    DEFAULT_IMAGE: require(`../src/assets/images/MenuItems/NotAvailable.png`),
  };

  return {
    m_sPagePaths,
    m_sTextLabels,
    m_sInternCodes,
    m_sRequestParams,
    m_sRequestNames,
    CST_HTTP_SERVER,
    CST_MODEL_DATABASE_TABLES_PRIMARY_KEYS_NUMBER,
    CST_EVENT_NAMES,
    CST_TEXTS,
    CST_ENUMS,
    CST_IMAGES,
  };
}
