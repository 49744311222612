// import getters from './getters'
// import mutations from './mutations'
// import actions from './actions'

// export default {
//   namespaced: true,
//   state: {},
//   getters,
//   mutations,
//   actions
// }

import { enViewItemType_t } from "@/../Common/commontypes/enums/index";

import jShoppingCartMenuItemData from "@/../viewmodel/ShoppingCartScreenVM/jShoppingCartMenuItemData";

// initial state
const state = {
  m_lstShoppingCartItems: new Map(),
  //const m_lstShoppingCartItems = ref([]);
};

// getters
const getters = {
  getOrders(state) {
    return state.m_lstShoppingCartItems; //.filter(todo => todo.done)
  },
};

// mutations(sync))

const mutations = {
  /**
   * @brief: add order item to shopping cart's shared storage
   * @details: if the order item was already registered into the basket
   * the former attributes must be incremented(nbr of units)
   */
  addOrderItem(state, i_oOrderItem) {
    // use current datetime as order id
    let currentdate = new Date();
    //TODO: add date format constant(separators / and :) to global constants
    let strDatetime =
      currentdate.getDate() +
      "/" +
      (currentdate.getMonth() + 1) +
      "/" +
      currentdate.getFullYear() +
      " " +
      currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds() +
      ":" +
      currentdate.getMilliseconds();

    state.m_lstShoppingCartItems.set(
      strDatetime,
      jShoppingCartMenuItemData(
        strDatetime,
        i_oOrderItem.m_uMenuItemId,
        enViewItemType_t.eViewItemType_ShopingCart,
        i_oOrderItem.m_strMenuItemName,
        state.m_lstShoppingCartItems.length,
        i_oOrderItem.m_eProductCategory,
        i_oOrderItem.m_strMenuItemImage,
        i_oOrderItem.m_strLocalization,
        i_oOrderItem.m_uUnitPrice,
        i_oOrderItem.m_uNbrofUnits,
        i_oOrderItem.m_uTotalPrice,
        [], // no selected days yet
        "", // no destinations yet
        "" // no delivery target datetime yet
      )
    );
  },

  /**
   * @brief: change state of a stored order
   * @details:
   */
  updateNbrofUnit(state, i_oParams) {
    if (
      state.m_lstShoppingCartItems.has(i_oParams.m_strBasketItemId_as_DateTime)
    ) {
      const oItemProxy = state.m_lstShoppingCartItems.get(
        i_oParams.m_strBasketItemId_as_DateTime
      );

      if (undefined != i_oParams.m_uNbrofUnits) {
        oItemProxy.m_uNbrofUnits = i_oParams.m_uNbrofUnits;
        oItemProxy.m_uTotalPrice = oItemProxy.m_uUnitPrice*oItemProxy.m_uNbrofUnits;
        // console.log(
        //   state.m_lstShoppingCartItems.get(
        //     i_oParams.m_strBasketItemId_as_DateTime
        //   )
        // );//TODO: keep this in debug log level prio 6
      } else {
        console.log(
          "ShoppingCartStorage.index::updateNbrofUnit: missing attribute m_uNbrofUnits for: "
        );
        console.log(i_oParams); //TODO: keep this in debug log level prio 6
      }
    } else {
      console.log(
        "ShoppingCartStorage.index::updateNbrofUnit: could not find item matching key" +
          i_oParams.m_strBasketItemId_as_DateTime
      );
    }
  },

  /**
   * @brief: change state of a stored order
   * @details:
   */
  updateSelectedDays(state, i_oParams) {
    if (
      state.m_lstShoppingCartItems.has(i_oParams.m_strBasketItemId_as_DateTime)
    ) {
      const oItemProxy = state.m_lstShoppingCartItems.get(
        i_oParams.m_strBasketItemId_as_DateTime
      );

      if (undefined != i_oParams.m_lstChangedDay) {
        oItemProxy.m_lstDays[i_oParams.m_lstChangedDay] =
          !oItemProxy.m_lstDays[i_oParams.m_lstChangedDay]; //TODO: instead of receiving and copying the complete array just switch the index of the day that changed prio 6
        // console.log(
        //   state.m_lstShoppingCartItems.get(
        //     i_oParams.m_strBasketItemId_as_DateTime
        //   )
        // );//TODO: keep this in debug log level prio 6
      } else {
        console.log(
          "ShoppingCartStorage.index::updateNbrofUnit: missing attribute m_lstChangedDay for: "
        );
        console.log(i_oParams); //TODO: keep this in debug log level prio 6
      }
    } else {
      console.log(
        "ShoppingCartStorage.index::updateNbrofUnit: could not find item matching key" +
          i_oParams.m_strBasketItemId_as_DateTime
      );
    }
  },

  /**
   * @brief: change state of a stored order
   * @details:
   */
  updateDeliveryData(state, i_oParams) {
    if (
      state.m_lstShoppingCartItems.has(i_oParams.m_strBasketItemId_as_DateTime)
    ) {
      const oItemProxy = state.m_lstShoppingCartItems.get(
        i_oParams.m_strBasketItemId_as_DateTime
      );

      if (undefined != i_oParams.m_strDestination) {
        oItemProxy.m_strDestination = i_oParams.m_strDestination;
        oItemProxy.m_strDeliveryTime =
          i_oParams.m_strDeliveryTime1 + "-" + i_oParams.m_strDeliveryTime2; //TODO: improve hard coded constant separator prio 4
        // console.log(
        //   state.m_lstShoppingCartItems.get(
        //     i_oParams.m_strBasketItemId_as_DateTime
        //   )
        // );//TODO: keep this in debug log level prio 6
      } else {
        console.log(
          "ShoppingCartStorage.index::updateNbrofUnit: missing attribute m_strDestination for: "
        );
        console.log(i_oParams); //TODO: keep this in debug log level prio 6
      }
    } else {
      console.log(
        "ShoppingCartStorage.index::updateNbrofUnit: could not find item matching key" +
          i_oParams.m_strBasketItemId_as_DateTime
      );
    }
  },

  
};

// actions(async)
const actions = {
  // getShoppingCartItems ({ commit }) {
  //   shop.getProducts(products => {
  //     commit('setProducts', products)
  //   })
  // }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
