/**
 * @brief: generic carousel's card view data class
 * @details: this class describe the features all carousel's card views must have
 * This file make use of composition api for code reusability and modularity
 * (through which also a kind of extension will be implemented when reusing this in other files)
 * NB: the references allows double way binding of data and thsis composition api based pattern
 * reinforce the fact that each instantiation of this class will be independent from the others
 */

import { ref } from "vue"; //computed

import jCarouselBaseitemdata from "@/../viewmodel/jCarouselBaseitemdata";
import {
  enViewItemType_t,
  enMenuCategory_t,
} from "@/../Common/commontypes/enums/index";

// will return separate instance of variables for each call
export default function jCardViewData(
  i_uMenuItemId = 0,
  i_eViewItemType0 = enViewItemType_t.eViewItemType_Unknown,
  i_strLabel = "",
  i_uCarouselPosition = 0,
  i_eMenuCategory = enMenuCategory_t.eMenuCategory_Unknown,
  i_strImageSourcePath = ""
) {
  // simulate inheritance while using composition app by this call which instantiate base class
  const { m_uMenuItemId, m_eCarouselItemType, m_strMenuItemName, m_uCarouselPosition } =
    jCarouselBaseitemdata( i_uMenuItemId, i_eViewItemType0, i_strLabel, i_uCarouselPosition);

  const m_eProductCategory = ref(i_eMenuCategory);

  const m_strMenuItemImage = ref(i_strImageSourcePath);

  return {
    m_uMenuItemId,
    m_eCarouselItemType,
    m_strMenuItemName,
    m_uCarouselPosition,
    m_eProductCategory,
    m_strMenuItemImage,
  };
}
