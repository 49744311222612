const enQuality_t = Object.freeze({
  eQuality_Unknown: 0,
  eQuality_Bad: 1,
  eQuality_NotsoGood: 2,
  eQuality_Alright: 3,
  eQuality_Good: 4,
  eQuality_VeryGood: 5,
  eQuality_Excellent: 6,
  eQuality_Invalid: 255,
});

export default enQuality_t;
