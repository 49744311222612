const enItemStatus_t = Object.freeze({
  eItemStatus_Unknown: 0,
  eItemStatus_Created: 1,// TODO: restaurantsscreen must receive all equal or above to this
  eItemStatus_Activated: 2,// TODO: homescreen needs to receive only these
  eItemStatus_DeActivated: 3,
  eItemStatus_Deleted: 254,
  eItemStatus_Invalid: 255,
});

/// @brief: Define the maximum orderStatus constant
const SOFTCONFIG_DEV_ORDERSTATUS_MAX = enItemStatus_t.eItemStatus_Deleted;
/// @brief: Define the minimum orderStatus constant
const SOFTCONFIG_DEV_ORDERSTATUS_MIN = enItemStatus_t.eItemStatus_Created;

const ORDERS_STATUS = Object.freeze([
  'Inconnue',
  'Created',
  'Activated',
  'DeActivated',
  'Deleted',
  'En livraison',
  'Non valide',
]);

/**
 * @brief Convert the enum enItemStatus to string,
 * The string returned is based on the value of the enItemStatus_t defined.
 * @param {enItemStatus_t} i_eItemStatus -> The ItemStatus to be converted to string
 * @returns {String}
 */
const toString = (i_eItemStatus) => {
  let strItemStatus;
  if (
    i_eItemStatus < SOFTCONFIG_DEV_ORDERSTATUS_MIN ||
    i_eItemStatus > SOFTCONFIG_DEV_ORDERSTATUS_MAX
  ) {
    strItemStatus = ORDERS_STATUS[ORDERS_STATUS.length - 1];
  } else {
    strItemStatus = ORDERS_STATUS[i_eItemStatus];
  }
  return strItemStatus;
};

/**
 * @brief Convert string to the proper enum enItemStatus if applicable,
 * The enum returned is based on the value of the string provided.
 * @param {String} i_strItemStatus -> The ItemStatus to be converted to enItemStatus_t
 * @returns {enItemStatus_t}
 */
const fromString = (i_strItemStatus) => {
  let eResult = enItemStatus_t.eItemStatus_Invalid;
  let enumValues = Object.values(enItemStatus_t);
  let enumKeys = Object.keys(enItemStatus_t);
  let index = ORDERS_STATUS.indexOf(i_strItemStatus);
  if (
    -1 < index &&
    enumValues.includes(index + 1) &&
    SOFTCONFIG_DEV_ORDERSTATUS_MIN <= index + 1 &&
    SOFTCONFIG_DEV_ORDERSTATUS_MAX >= index + 1
  ) {
    eResult = enumKeys[index];
  }

  return eResult;
};

/**
 * @brief Convert string to the proper enum enItemStatus if applicable,
 * The enum returned is based on the value of the string provided.
 * @param {number} i_uItemStatus -> The ItemStatus to be converted to enItemStatus_t
 * @returns {enItemStatus_t}
 */
const fromNumber = (i_uItemStatus) => {
  let eResult = enItemStatus_t.eItemStatus_Invalid;
  if (
    SOFTCONFIG_DEV_ORDERSTATUS_MIN <= i_uItemStatus &&
    SOFTCONFIG_DEV_ORDERSTATUS_MAX >= i_uItemStatus
  ) {
    let enumValues = Object.values(enItemStatus_t);
    let enumKeys = Object.keys(enItemStatus_t);
    let index = enumValues.indexOf(i_uItemStatus);
    if (-1 < index) {
      eResult = enumKeys[index];
    }
  }
  return eResult;
};

export {
  enItemStatus_t,
  ORDERS_STATUS,
  SOFTCONFIG_DEV_ORDERSTATUS_MAX,
  SOFTCONFIG_DEV_ORDERSTATUS_MIN,
  toString,
  fromString,
  fromNumber,
};
