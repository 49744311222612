const enBasketItemType_t = Object.freeze({
  eMenuCategory_Unknown: 0,
  eMenuCategory_Starter: 1,
  eMenuCategory_SideDish: 2,
  eMenuCategory_MainCourse: 3,
  eMenuCategory_Dessert: 4,
  eMenuCategory_Drink: 5,
  eMenuCategory_Invalid: 255,
});

export default enBasketItemType_t;
