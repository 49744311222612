/**
 * @brief: "Home Screen"'s card views data class used and binded throughouts the home screen caroussel view model
 * @details: this class describe the features all menu item's card views in the "home screen" must have
 * This file make use of composition api for code reusability and modularity
 * (through which also a kind of extension will be implemented when reusing this in other files)
 * NB: the references allows double way binding of data and thsis composition api based pattern
 * reinforce the fact that each instantiation of this class will be independent from the others
 */


import { isRef, ref } from "vue";

// import jHomeScreenCategoryItemData from "@/../viewmodel/HomeScreenVM/jHomeScreenCategoryItemData";
// import {
//   enViewItemType_t,
//   enMenuCategory_t,
// } from "@/../Common/commontypes/enums/index";

/**
 * @brief: class(or pseudo class) for home screen view data model
 * @details: 
 * 
 * @export
 * @param {number} [i_uCategoryPosition=0] => enumeration id of the category and carousel position
 * @param {string} [i_strCategoryTitle=""] => 
 * @param {string} [i_strCategorySubTitle=""]
 * @param {*} [i_CategoryVariant=[]] => SINGLE OBJECT or ARRAY OF OBJECTS of type MenuItem(//TODO: which type exactly? prio 10)
 * @return {*} returns object which references are independent
 */
export default function jHomeScreencategoryCarouselData(
  i_uCategoryPosition = 0,
  i_strCategoryTitle = "",
  i_strCategorySubTitle = "",
  i_CategoryVariant = new Map()
) {
  // initialization of home screen view model data class members based on composition api
  const m_eProductCategory = ref(i_uCategoryPosition); // type: enMenuCategory_t

  const m_strTitle = ref(i_strCategoryTitle);

  const m_strSubTitle = ref(i_strCategorySubTitle);

  const m_lstCategoryItems = ref(new Map());
  if (i_CategoryVariant) {
    // if ((Array.isArray(i_CategoryVariant)) && ( 0 < i_CategoryVariant.value.length)){
    //   m_lstCategoryItems.value = i_CategoryVariant.value;// copy values
    // }
    if (isRef(i_CategoryVariant)) {
      m_lstCategoryItems.value = i_CategoryVariant.value;
    }
    else if ((undefined === i_CategoryVariant.length) &&
    (undefined === i_CategoryVariant.size)) {
      m_lstCategoryItems.value.set(i_CategoryVariant.m_uMenuItemId.value, i_CategoryVariant);
    }
  }
  
  // return all class features for clean modular code reusability
  return {
    m_eProductCategory,
    m_strTitle,
    m_strSubTitle,
    m_lstCategoryItems,
  };
}
