/**
 * @brief: base customer order Menu Item 's card view data class
 * @details: this class describe the features all customer order menu item's card views must have
 * This file make use of composition api for code reusability and modularity
 * (through which also a kind of extension will be implemented when reusing this in other files)
 * NB: the references allows double way binding of data and thsis composition api based pattern
 * reinforce the fact that each instantiation of this class will be independent from the others
 */

import { ref } from "vue"; //computed

import jMenuItemCardViewData from "@/../viewmodel/jMenuItemCardViewData";
import {
  enViewItemType_t,
  enMenuCategory_t,
} from "@/../Common/commontypes/enums/index";

// will return separate instance of variables for each call
export default function jBaseOrderItemData(
  i_strBasketItemId_as_DateTime = 0,
  i_uMenuItemId = 0,
  i_eViewItemType0 = enViewItemType_t.eViewItemType_Unknown,
  i_strLabel = "",
  uCarouselPosition = 0,
  i_eMenuCategory = enMenuCategory_t.eMenuCategory_Unknown,
  i_strImageSourcePath = "",
  i_strLocalization = "",
  i_uUnitPrice = 0,
  i_uNbrofUnits = 0,
  i_uTotalPrice = 0
) {
  // simulate inheritance while using composition app by this call which instantiate base class
  const {
    m_uMenuItemId,
    m_eCarouselItemType,
    m_strMenuItemName,
    m_uCarouselPosition,
    m_eProductCategory,
    m_strMenuItemImage,
    m_strLocalization,
    m_uUnitPrice,
  } = jMenuItemCardViewData(
    i_uMenuItemId,
    i_eViewItemType0,
    i_strLabel,
    uCarouselPosition,
    i_eMenuCategory,
    i_strImageSourcePath,
    i_strLocalization,
    i_uUnitPrice
  );

  // init member data with initial and/or default values
  const m_strBasketItemId_as_DateTime = ref(i_strBasketItemId_as_DateTime);

  const m_uNbrofUnits = ref(i_uNbrofUnits);

  const m_uTotalPrice = ref(i_uTotalPrice);
  if (i_uNbrofUnits && i_uUnitPrice){
    m_uTotalPrice.value = i_uUnitPrice*i_uNbrofUnits;
  }


  return {
    m_strBasketItemId_as_DateTime,
    m_uMenuItemId,
    m_eCarouselItemType,
    m_strMenuItemName,
    m_uCarouselPosition,
    m_eProductCategory,
    m_strMenuItemImage,
    m_strLocalization,
    m_uUnitPrice,
    m_uNbrofUnits,
    m_uTotalPrice,
  };
}
