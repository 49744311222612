/** @brief generic carousel model adapted for A&E internal use. The carousel
data passed as prop must all have the same data memeber structure! */
<template>
  <!-- main carousel module builder
  :class="{
      'carousel-outer-container-13-smAndDown': m_bIsMobile,
      'carousel-outer-container-13-mdAndUp': !m_bIsMobile,
    }" -->
  <!-- <h1>  {{m_uNMAXVisibles}}  {{m_bIsMobile}}</h1> -->
  <span :style="m_pCarouselStyle"  ref="m_pCarouselRef" draggable="true"
  @dragstart="onCarousselDragStart"
  @dragend="onCarousselDragEnd"
  >
    <!-- <v-img v-bind:src="m_strImageSourcePath1" ></v-img> -->
    <span ref="m_pInnerCarouselRef"  :style="m_pInnerCarouselStyle" >
      <span  ref="m_pTrackRef" :style="m_pTrackStyle">
        <!-- carousel items main iteration class="Box-1321-Carousel-track1" 
          is now set dynamically and can be deleted //TODO: prio 10 -->
        <!-- @dragstart="onPrevClicked"
          @dragend="onNextClicked"
          class="Box-1321i-cardContainer" -->
        <span v-for="(index, iMenuItem) in m_lstCardViews" :key="index[0]" :id="getCardId(index, iMenuItem)"
          ref="m_lstTrackCardRefs" :style="getCardDynamicStyle(index)" :elevation="m_uCARDELEVATION">
          <!-- <component v-bind:is="m_CardView"  :m_oMenuItemData="iMenuItem" :m_bIsMobile="m_bIsMobile"></component> -->
          <!-- for now the carousel model is not yet a fully independent compoennt but is reused throgh these ifs
            TODO: Make the component an independant component prio 7 -->
          <HomeScreenCategoryCardView v-if="1 == m_CardView && undefined != m_lstItems.get(index[1])" 
            :m_oMenuItemData="m_lstItems.get(index[1])"
            :m_bIsMobile="m_bIsMobile" :m_bDisplayAttributes=m_bDisplayAttributes
            @[userChangeEventListener]="onCustomAppUserEvent" />
          <RestaurantsScreenCardView v-if="2 == m_CardView" :m_oMenuItemData="m_lstItems.get(index[1])"
            :m_bIsMobile="m_bIsMobile" :m_bDisplayAttributes=m_bDisplayAttributes
            @[userChangeEventListener]="onCustomAppUserEvent" />
          <OrdersScreenCardView v-if="3 == m_CardView" :m_oMenuItemData="m_lstItems.get(index[1])"
            :m_bIsMobile="m_bIsMobile" :m_bDisplayAttributes=m_bDisplayAttributes
            @[userChangeEventListener]="onCustomAppUserEvent" />
        </span>
      </span>
    </span>
    <!-- Navigation Arrows-->
    <span
      :class="{
        'Box-131-smAndDown': m_bIsMobile,
        'Box-131-mdAndUp': !m_bIsMobile,
      }"
      ref="m_pLeftBtn"
    >
      <v-btn rounded size="fit-content" elevation="20"
      @dblclick="onLeftEndlessShift" 
      @click="onPrevClicked">
        <img id="prevImg" :src="m_strImageSourcePathPrev" alt="cannot load" />
      </v-btn>
    </span>

    <span :class="{
      'Box-133-smAndDown': m_bIsMobile,
      'Box-133-mdAndUp': !m_bIsMobile,
    }" ref="m_pRightBtn">
      <!-- <v-btn rounded size="fit-content" fab dark large outlined  @click="onNextClicked" > -->
      <v-btn rounded size="fit-content" elevation="20" 
      @click="onNextClicked"
      @dblclick="onRightEndlessShift" 
      >
        <img id="nextImg" :src="m_strImageSourcePathNext" alt="cannot load" />
      </v-btn>
    </span>
  </span>
</template>

<script>
import { ref, onMounted, onUnmounted, onBeforeMount, onUpdated, onBeforeUpdate } from "vue";


import SoftDevConfigs from "../../configurations/Configs.js";
import { enCardMotionStatus_t } from "../../Common/commontypes/enums/enCardMotionStatus_t"
// import CarouselComponent from "@/components/CarouselComponent.vue"
import HomeScreenCategoryCardView from "../../view/Home/HomeScreenCategoryCardView.vue";
import RestaurantsScreenCardView from "../../view/Restaurants/RestaurantsScreenCardView.vue";
import OrdersScreenCardView from "../../view/Orders/OrdersScreenCardView.vue";

export default {
  name: "CustomCarousel",
  components: {
    HomeScreenCategoryCardView,
    RestaurantsScreenCardView,
    OrdersScreenCardView,
  },

  props: {
    /**
     * @brief property for the component to know weither the device is a mobile
     */
    m_bIsMobile: {
      type: Boolean,
      required: true,
    },
    /**
      * @brief property for the component to know attributes necessary for responsiveness
      */
    m_bDisplayAttributes: {
      required: true,
    },

    /**
     * @brief contains all the necessary data to fill the carousel items
     */
    m_lstItems: {
      required: true,
    },
    /**
     * @brief tell us which card view design is to be used for the Carousel
     * @details for now we switch between cardview implementations based on
     * this parameter to reuse the carousel model.
     * //TODO: make this component more generic and completely decoupled of prio 7
     * those different cardview implementation
     */
    m_CardView: {
      required: true,
    },
  },


  setup(props, { emit }) {

    function getCardId(index) {
      return 'Card' + (index[0]).toString();
    }
    //TODO try const instead of let than v-img !!!!!!!
    const m_strImageSourcePathPrev = require(`../../src/assets/images/cardviews/prevCarrouselArrow.svg`);
    const m_strImageSourcePathNext = require(`../../src/assets/images/cardviews/nextCarrouselArrow.svg`);
    /**
     * @brief Data values that will be used to manipulate carousel drag left and right
     */
    const m_oCarousel = ref({ m_bPressed: false, m_nStartX: null, m_nX: null });
    /// @brief. total number of items provided to carousel component
    const m_NITEMS = ref(0);

    /**
     * @brief keep a map of all items positions to their keys as m_lstCardViews only containe dthe displayed ones
     */
    let m_lstDataItems = new Map();
    /*
    * @brief prepare a constant to keep a static number of Carousel cards which data will just be updated
    as Carousel motions are triggered
    */
    let m_uNCARDVIEWS = ref(0);

    const m_lstTrackCardRefs = ref([]);
    /**
     * @brief keep track of cards zero position, as each translation step is an absolute distance relative to its original position
     * @details we avoid choosing the track the cumulative sum of all translations to avoid overflows problems
     */
    let m_lstTrackCardStartLefts = new Map();
    /**
     * @brief map card indice within track to its calculated verified current left border position
     * @details this map a card indice(order) within the carousel to its relative position
     * This relative position is the sum of all translation since the inner track wa sinitially 
     * positionned. Hence relatively to position zero. It is herewith not the actual global position
     * of the card
     */
    let m_lstCardLefts = new Map();
    /**
     * @brief carousel card views' view model data(map carousel cardview index to a key within the 
     * complete provided input data model m_lstItems)
     * @detail for each index position in Carousel i, a corresponding key j of m_lstItems is mapped
     * Herewith , an index j can be a value for two keys within this map, so that we can use one data 
     * for one item and its proxy for its double in case we need to double certain items witout having
     * copies of same data. 
     * Furthermore only the key of m_lstCardViews entry should be used as v-for's :key and not the entry pair,
     * as this caused the dom through the v-for to actualize the complete track (thereby interrupting
     * effects/translations/transitions). 
     * 
     * NB: the cards models canot change the data within the carousel and needs to
     * send signals to the carousel parents for view model double way data bindings.
     * NB: a for over this map is only elligible as long as the sorted order of indices is irrelevant
     * 
     */
    const m_lstCardViews = ref(new Map());

    /**
     * @brief map associating the position of a card within the track in their display order(even if hidden)
     * to the reference of that card within the do elements references array m_lstTrackCardRefs
     * @details 
     * this became especially necessary because the orde rof the elements within m_lstTrackCardRefs
     * is not always sorted and can even change, therewith we need a more trustable map of the dom elements
     * and preferably in a sorted order of the keys matching the card order in the track
     * 
     * NB: always prefer this inan iteration/for lopp when the sorted order of indices is relevant
     * as the order in m_lstCardViews might not be sorted to avoid double dom updates!!!!!!!!!!!!!
     */
    const m_oMapTrackCardIndices = ref(new Map());


    /**
     * @brief reference to Track's dom element
     * @details
     *
     */
    // const m_oTrackRect = ref(null);
    const m_oInnerRect = ref(null);
    // const m_oCarousselRect = ref(null);
    const m_uMotionStep = ref(0);
    let m_uNUnVisibles = { left: 0, right: 0 };//[Nleft Nright] of visible track part
    /**
     * @brief exact number of cards to be displaced per motion arrow key press
     */
    const getNCARDS_PER_MOTION = () => { return (m_uNMAXVisibles.value) - 1 };//
    /**
     * @brief computed best number of visible cards within the inner section of the carousel
     */
    let m_uNMAXVisibles = ref(0); // TODO: improve hard coded constant and make it vary based on screen size prio 4
    /**
     * @brief factor influencing the model adaptation of the card views and the track
     * to allow smooth fast motion while properly conatining the card withing the logical 
     * motion model and space
     * @details this is a factor relatively to getNCARDS_PER_MOTION() to
     * compute the number of additional card views(after the strict numbe rof visible cards) 
     * This is factor by which the number of cards that can be moved in a single motion must be multiplied
     * to get the number of necessary hidden cards. the total obtained hidden cards
     * plus the visible cards determines the minimum tracj witdh before motion extention
     * we use here best guesses of how many items we may want max inside the track 
     * in order to allow fast cycling 
     * the const 2 was choosen to have as many hidden cards on the left than on the right of the track
     * //TODO: prio 10 at which speed does this number become not enough? starting which speed does we actually need it?
     * STRUCTURE OF TRACK WITH DIFFERENT VISIBILITY SECTIONS(where VIS1 means the visibility status on that section is 1):
     * [ EXTENSION 1 for motion to leftVIS1]+[HIDDEN PART VIS1..VIS4]+[VISIBLE PART VIS5]+[HIDDEN PART VIS3..VIS2]+[ EXTENSION for motion to right VIS2]
     */
    const m_uNITEM_FACTOR_4_FAST_CAROUSELMOTION = 2; // TODO: improve hard coded constant and make it vary based on screen size prio 4
    /**
     * @brief factor by which a motion step is multiplied to determine the (half)extention
     * section of the track width reserved to contained card after a single motion step on one hidden side
     * @details  factor by which the number of cards that can be moved in a single motion must be multiplied
     * to get the width to be added to the width of the track to always be able to conatin
     * all cards even after a motion step on one single side(considering here only one direction to avoid hard coded 2)
     * For now it is expected that this should be half the total width added to 
     * extended the track abov the visible section
     * * STRUCTURE OF TRACK WITH DIFFERENT VISIBILITY SECTIONS(where VIS1 means the visibility status on that section is 1):
     * [ EXTENSION 1 for motion to leftVIS1]+[HIDDEN PART VIS1..VIS4]+[VISIBLE PART VIS5]+[HIDDEN PART VIS3..VIS2]+[ EXTENSION for motion to right VIS2]
     * //TODO: prio 3 do we need to make this vary base on motion speed influenced by click speed?
     */
    const m_uFACTOR_4_MOTION_EXTENSION_SECTION = m_uNITEM_FACTOR_4_FAST_CAROUSELMOTION / 2; // TODO: improve hard coded constant and make it vary based on screen size prio 4

    /**
     *  @brief threashold to decide if a card is more on on side or the other if visibility is 3 or 4
     * **/
    const m_fCARD_VISIBILITY_THRESHOLD = 0.75;
     /**
      * @brief intervallId to repeat endless shif
      */
    let m_uEnlessShiftIntervallId = null;
    /**
     * time to repeat transition
     */
    let endlessShiftIntervall = 2000;

    /**
     * @brief reference to track dom element
     * @detail the with of this element is fit-content !!!!!!!!!!!1
     */
    const m_pTrackRef = ref(null);

    /**
     * @brief carousel's inner track total width
     * @detail this variable keeps the dynamic total inner track usable width
     * compute the width while mounting elements based on number of cards and their sizes
     * the width should be large enough to contain all cards even after a motion left or right
     * hence if there is N data items(within props parameter):
     * 1- the dome elements using the template v-for loop will initialize the width around 
     *    N*(CardWidths+Spacing)
     * 2- the width must be extended at least so that all mounted card views can be contained
     * if the track can be moved then N+m_uNITEM_FACTOR_4_FAST_CAROUSELMOTION*getNCARDS_PER_MOTION() 
     * cardviews will be mounted, so that when we move v hidden cards will be available
     * to become visible after motion, and getNCARDS_PER_MOTION() that where hidden on the other side will
     * be circled shifted to replace the ones that just got out of the shaddow
     * 3- the track inner width must be large enough to conatin all this cards even after motion
     *  and herewith must be even greater(m_uFACTOR_4_MOTION_EXTENSION_SECTION):
     * (m_uNMAXVisibles.value) * (m_f64CARDWIDTH + m_uCardSpacing.value) 
          + (m_uNITEM_FACTOR_4_FAST_CAROUSELMOTION+ m_uFACTOR_4_MOTION_EXTENSION_SECTION) 
          * (m_uCardSpacing.value +m_f64CARDWIDTH) *getNCARDS_PER_MOTION()
     * //TODO: prio 3 does/can the width of the visible part of the only partially visible cards changes? how does its behavior vary depending on even or oddd number of data items?
     */
    const m_pTrackWidth = ref(0);//fit-content
    /**
     * @brief motion step by which the track must be moved in the beginning 
     * @details the track might be initially shifted(start bias) if more cards than can be visible are mounted.
     * it will then be initially moved by a certain offset so that we have 
     * as many hidden space on the left as on the right of the track, and such that each of 
     * these two hidden parts are wide enough to contain a motion extension section, large enough
     * to contain as many cards (with space(s)) as can be moved in a single motion step.
     * its value will be m_uMotionStep.value*(1+m_uFACTOR_4_MOTION_EXTENSION_SECTION)
     * We add "-m_uNITEM_FACTOR_4_FAST_CAROUSELMOTION* m_uCardSpacing.value" so that the 
     * left border of the very first VISIBLE card will not be exactly alligned with the left
     *  inner track border , and therwith not just behind the arrow(not so good visually)
     * //TODO: prio 3 is it possible that this variable can allow us to remove the map of StartCardleft positions?? and replace its usage by using m_uTrackInitMotionOffset instead?
     */
    let m_uTrackInitMotionOffset = 0;
    /**
     * @brief start bias or offset of all cards which ois applied at beforeUpdate
     * to make sure the midle cards are visible at the beginning
     * @details
     * since the track is shifted backwards by twice the motion step, this should be equal to the motion step
     * Both of course might be changed by m_uNITEM_FACTOR_4_FAST_CAROUSELMOTION
     * a constant additional bias(one motion step) must be added to m_uCardInitialBiasInTrack  so that 
     * the first card will not be blocked by the arrow vertical-area(innertrack border area) 
     */
    let m_uCardInitialBiasInTrack = null;
    /**
     * @brief call to compute the value of m_uCardInitialBiasInTrack used for the initial offset 
     * of each cardview within the track
     */
    function __InitCardInitialBiasInTrack() {
      if ((m_uNMAXVisibles.value < m_uNCARDVIEWS.value)) {
        // first the card has been already shifted with the initial displacement
        //  of complete track. But when extending the bias of the initial position of the 
        // track by m_uFACTOR_4_MOTION_EXTENSION_SECTION*motionStep
        // the cards have been also thereby shifted more than necessary. they must be shifted 
        // again in the other direction but only by m_uFACTOR_4_MOTION_EXTENSION_SECTION*motionStep, 
        // so that as many card as can be moved in one motion will we hidden on both sides
        //  of the track while the motion extension parts of those hidden sections will
        // for now stay empty, ready to receive moved cards later.
        m_uCardInitialBiasInTrack = m_uFACTOR_4_MOTION_EXTENSION_SECTION * m_uMotionStep.value;
      } else {
        m_uCardInitialBiasInTrack = 0;
      }
      // a constant additional bias must be added to m_uCardInitialBiasInTrack  so that 
      // the first card will not be blocked by the arrow vertical-area(innertrack border area)  
      m_uCardInitialBiasInTrack += m_uCardSpacing.value;
    }

    
    /**
     * @brief structure used for dynamic styling of inner track's css properties
     */
    const m_pTrackStyle = ref({
      width: '100%',//props.m_bIsMobile? '650px': '1300px',
      order: 1,
      display: 'fleX',
      'flex-direction': 'row',
      overflow: 'hidden',
      // 'border-style': 'double',
      // 'border-color': 'red',
    });

    /* ********START*********** attributes and methods for mutex handling and future encapsulation of its concept ******************/

    /**
     * @brief maximum time a section might wait for m_oCarouselDataMapMutex release in milliseconds
     *  */
    const m_uNMutexTimeOut = 3000;
    /**
     * @brief mutex used to synchronize updates done to crucial maps
     * @details used to avoid race conditions when asynchronous calls can change
     * m_lstCardViews and/or m_oMapTrackCardIndices even just in quasi-concurrent calls
     * we are not using async-mutex because it doesnt actually force the caller to wait 
     * when acquiring mutex.
     * //TODO: prio 10 encapsulate custom mutex
     */
    let m_oCarouselDataMapMutex = {
      /**
       * @brief locking status of mutex object
       * @details this should be set to true simultaneously with the initialization of a valid token
       * when token is reetted, this must always be resetted as well.
       */
      m_bIsLocked: false,
      /**
       * @brief set of waiting calls(quasi/pseudo-parallel competing usage of mutex)
       * @details list of registered callbacks(kind of listeners) waiting for the mutex to be released
       */
      m_lstWaiters: new Set(),
      /**
       * @brief set of waiting calls that have been cancelled 
       * @details any callback preent here and being called from m_lstWaiters set, must avoid calling
       * the initially waiting target callback function; not waiting anymre since it has been cancelled 
       * because of: timeout.
       */
      m_lstCancelledWaiters: new Set(),
      /**
       * @brief token used to identify which call currently has the right to unlock the mutex
       * @details this should always be changed simultaneaously with the locking status
       * //TODO:  prio: 8 when encapsulating the locking status mght be returned later by a method but never the token
       */
      __m_oToken: null,
    };

    /**
     * @brief private method of custom mutex class to execute the actual mutex acquiring process
     * @details set lock status to and token to current Datetime
     * @param {*} oToken 
     * @param {*} releaseFct 
     */
    function __doAcquireMutex(oToken, releaseFct) {
      m_oCarouselDataMapMutex.m_bIsLocked = true;
      oToken = new Date();
      m_oCarouselDataMapMutex.__m_oToken = oToken;
      let waiterHandlerAsynccallhandler = null;
      releaseFct = Object.freeze(
        /**
        * @brief callback called by the aller who acquired the mutex in order to relase it
        * @returns true, if the mutex is unlocked and the token resetted
        */
        function mutexReleaseFct() {
          if (m_oCarouselDataMapMutex.__m_oToken === oToken) {// this is the proper releaser
            m_oCarouselDataMapMutex.m_bIsLocked = false;
            m_oCarouselDataMapMutex.__m_oToken = null;
          } else { // trigger the error/catch callback of the thenbable which the caller should use
            console.log(m_oCarouselDataMapMutex);
            throw new Error(
              "CustomCarousel::mutexReleaseFct(): CRITICAL ERROR: INCONSISTENCY IN mutexReleaseFct "); //TODO: prio 8: process exception properly
          }
          // call next callback waiting to acquire mutex asynchronously
          if (m_oCarouselDataMapMutex.m_lstWaiters.size) {
            waiterHandlerAsynccallhandler = new Promise(function (i_fctResolve, i_fctReject) {
              if (m_oCarouselDataMapMutex.m_lstWaiters.size) {
                const it = m_oCarouselDataMapMutex.m_lstWaiters.values();
                if (it.next().value) {
                  const fctHandler = it.next().value;
                  fctHandler();
                  i_fctResolve();
                } else
                  i_fctReject();
              } else
                i_fctReject();
            })
          }
          return (!m_oCarouselDataMapMutex.m_bIsLocked && null == m_oCarouselDataMapMutex.__m_oToken);
        });
      return { oToken, releaseFct, waiterHandlerAsynccallhandler };
    }

    /**
     * @brief create a handler to process the steps required when a previously locked mutex was released
     * and can now be acquired by the given waiter 
     * @param {*} oToken (call-by-reference)
     * @param {*} releaseFct (call-by-reference)
     * @param {*} i_fctTargetCallBack 
     */
    function __getWaiterHandlingFct(oToken, releaseFct, i_fctTargetCallBack) {
      let handleWaiter = () => {
        // execute actual mutex acquirement
        ({ oToken, releaseFct } = __doAcquireMutex(oToken, releaseFct));
        // if acquiring worked execute target callback
        if (null != oToken && null != releaseFct) {
          // check if waiting call has been cancelled or not before execution
          if (m_oCarouselDataMapMutex.m_lstCancelledWaiters.has(handleWaiter)) {
            // we may not check delete return because we may actually acquire it juste before maybe??
            m_oCarouselDataMapMutex.m_lstCancelledWaiters.delete(handleWaiter);
            console.log("__getWaiterHandlingFct: cancelling waiter " + oToken);
            console.log(handleWaiter);//TODO: prio 1 comment this //TODO: prio 10 keep only in debug level
          } else {
            // if the target waiting callback handler was properly released 
            // and if the waiter has not been cancelled, execute the waiting call
            i_fctTargetCallBack();
          }
        } else { // trigger the error/catch callback of the thenbable which the caller should use
          console.log(m_oCarouselDataMapMutex);
          throw new Error(
            "CustomCarousel::__getWaiterHandlingFct(): CRITICAL ERROR: FAILED ACQURING FOR WAITER "); //TODO: prio 8: process exception properly
        }
        if (m_oCarouselDataMapMutex.m_lstWaiters.has(handleWaiter)) {
          // we may not check delete return because we may actually acquire it juste before maybe??
          m_oCarouselDataMapMutex.m_lstWaiters.delete(handleWaiter);
        } else { // trigger the error/catch callback of the thenbable which the caller should use
          console.log(m_oCarouselDataMapMutex);
          throw new Error(
            "CustomCarousel::__getWaiterHandlingFct(): CRITICAL ERROR: INCONSISTENCY IN m_lstWaiters "); //TODO: prio 8: process exception properly
        }
      };
      return handleWaiter;
    }

    /**
     * @brief add target callback into list of waiters via a mutex callbcak function
     * that will handle acquiring the mutex and calling that function handle
     * @param {*} i_fctTargetCallBack fct to be called once the mutex has been acquired
     * @param {*} oToken token to be set when acquiring the mutex(call-by-reference)
     * @param {*} releaseFct 
     */
    function __registerWaiter(i_fctTargetCallBack, oToken, releaseFct) {//TODO: prio 8 move to external private method, improve naming!!!
      let handleWaiter = null;
      if (null === oToken && null === releaseFct) {
        handleWaiter = __getWaiterHandlingFct(oToken, releaseFct, i_fctTargetCallBack);
        m_oCarouselDataMapMutex.m_lstWaiters.add(handleWaiter);
      }
      return handleWaiter;
    }

    /**
     * @brief called to try acquiring a given custom mutex while the caller is waiting
     * for the provided max waiting time in milliseconds.
     * @details will either directly acquire the mutex if it is not locked,
     * or wait until it is free using settimeout with the given i_uNMutexTimeOut
     * if it is not unlocked the m_lstWaiters set will be updated with the new waiting
     * target callback
     * the caller will received a promise to which he can provide a thenable and/or a .catch
     * 
     * @param {*} i_fctTargetCallBack fct to be called once the mutex has been acquired
     * @param {*} i_uNTimeOut_ms threshold for how long the caller can wait
     */
    function acquireBlockingMutex(i_fctTargetCallBack, i_uNTimeOut_ms) {
      let oToken = null;//TODO: prio 10 what actually happens with this after return?
      // handle main features within a promise to return a promise expecting a thenable

      let releaseFct = null;// function to be used by the target callback to release the mutex
      let cancelFct = null;// function to be used by the target callback to cancel the mutex waiting process
      //TODO: prio 10 after encapsulating m_oCarouselDataMapMutex concept, this method will become acquire within the new class;shall we make m_oCarouselDataMapMutex a this or an argument?(i_oCarouselDataMapMutex)
      if (!m_oCarouselDataMapMutex.m_bIsLocked && null == m_oCarouselDataMapMutex.__m_oToken) {
        ({ oToken, releaseFct } = __doAcquireMutex(oToken, releaseFct));
        console.log("acquireBlockingMutex: acquired !!!" + oToken);//TODO: prio 1 comment this //TODO: prio 10 keep only in debug level HIGH
      } else {
        // if a timer was provided
        if (!isNaN(i_uNTimeOut_ms) && (0 < i_uNTimeOut_ms)) {
          // register target callback in the list of waiting calls
          const oWaiterhandler = __registerWaiter(i_fctTargetCallBack, oToken, releaseFct);
          cancelFct = () => {
            // we may not check delete return because we may actually acquire it juste before maybe??
            m_oCarouselDataMapMutex.m_lstCancelledWaiters.add(oWaiterhandler);// prepare release of waiter by notifying cancelation
            console.log("acquireBlockingMutex: cancelling target " + oToken);
            console.log(oWaiterhandler);//TODO: prio 1 comment this //TODO: prio 10 keep only in debug level
          };
          setTimeout(function waitforMutex() {
            if (m_oCarouselDataMapMutex.m_bIsLocked &&
              oToken != m_oCarouselDataMapMutex.__m_oToken) {// mutex can still not be locked
              if (m_oCarouselDataMapMutex.m_lstWaiters.has(oWaiterhandler)) {// if waiter not released yet
                cancelFct();
              }
            }
          }, i_uNTimeOut_ms);
        }// else i_fReject() will be called!
      }
      // keep returns at end of function 
      if (null != oToken && oToken === m_oCarouselDataMapMutex.__m_oToken) {
        console.log("acquireBlockingMutex: calling target " + oToken);//TODO: prio 1 comment this //TODO: prio 10 keep only in debug level
        i_fctTargetCallBack();// call the actual intended target function
      } else {
        console.log("acquireBlockingMutex: rejecting " + oToken);//TODO: prio 1 comment this //TODO: prio 10 keep only in debug level
      }

      return { releaseFct, cancelFct };
    }

    function cancelBlockingMutexWaiters() {
      console.log("cancelBlockingMutexWaiters: calling target " + m_oCarouselDataMapMutex.__m_oToken);
      acquireBlockingMutex(() => {
        m_oCarouselDataMapMutex.m_lstWaiters.clear();
        m_oCarouselDataMapMutex.m_lstCancelledWaiters.clear();
        m_oCarouselDataMapMutex.__m_oToken = null;
        m_oCarouselDataMapMutex.m_bIsLocked = false;
      }, m_uNMutexTimeOut);
    }
    /* *********END*********** attributes and methods for mutex handling and future encapsulation of its concept ******************/



    const m_pLeftBtn = ref(null);
    const m_pRightBtn = ref(null);
    const m_pInnerCarouselRef = ref(null);
    const m_f64INNERWIDTH = window.innerWidth * 0.94;//TODO: hard codeds const prio 4
    /**
     * @brief actual width of the physical/mounted card
     */
    const m_f64CARDWIDTH = getCardWidth();//TODO: hard codeds const to be synched with card view actual sizes prio 4
    /**
     * @brief total width reserved for each card(card width + spacing dynamically calculated)
     */
    function getCardViewtotalSpace() { return (m_uCardSpacing.value + m_f64CARDWIDTH); }

    /**
     * @brief used card width
     * @details
     * it might e an idea to have card width as parameter prio 10.
     */
    function getCardWidth() {
      let f64Val = props.m_bIsMobile ? 150 : 200;

      switch (props.m_bDisplayAttributes.name) {
        case 'xs': {
          f64Val = 1 / 5 * 577;
          break;
        }
        case 'sm': {
          f64Val = 1 / 4 * 577;
          break;
        }
        case 'md': {
          f64Val = 1 / 3 * 577;
          break;
        }
        case 'lg': {// v was optimal for 1920*1080
          f64Val = 1 / 3 * 577;
          break;
        }
        case 'xl': {
          f64Val = 2 / 5 * 577;
          break;
        }
        case 'xxl': {
          f64Val = 1 / 2 * 577
          break;
        }
      }

      if (1 < props.m_CardView) {
        f64Val *= 1.25;
      }

      return f64Val;
    }
    const m_f64CARDHEIGTH = getCardHeigth();
    /**
    * @brief used card heigth
    * @details
    * it might e an idea to have card width as parameter prio 10.
    */
    function getCardHeigth() {
      let f64Val = props.m_bIsMobile ? 150 : 230;//TODO:
      if (2 == props.m_CardView) {
        f64Val *= 1.5;
      } else if (3 == props.m_CardView) {
        f64Val *= 2;
      }
      return f64Val;
    }


    const m_pInnerCarouselStyle = ref({
      order: 2,
      // justify-content: center,
      // white-space: nowrap,
      // margin-left: 3%,
      // margin-right: 3%,
      // min-width: 1600px !important,
      // width: 185%!important,//TODO: make inner widths dynmic (track too must be resized at onmount based on numbe rof cards in one motion step) prio 1
      width: m_f64INNERWIDTH.toString() + 'px',// props.m_bIsMobile? '650px': '1300px', 
      overflow: 'hidden',
      // height: 100%,
      position: 'relative',
      // transform: 'translate(-1%, 0%)',
      // 'border-style': 'dotted',
      // 'border-color': 'black',
    });
    const m_pCarouselRef = ref(null);
    const m_pCarouselStyle = ref({
      width: (window.innerWidth * 0.98).toString() + 'px',// props.m_bIsMobile? '650px': '1300px', 
      // "border-style": "dashed",
      // "border-color": "chartreuse",
      display: "flex",
      "flex-direction": "row",
      cursor:"grab",
      position: "relative",
      overflow: "hidden",
    });

    /**
     * @brief initial smallest space between two cards in px
     */
    const m_uCardSpacing = ref(window.innerWidth * 0.01); //TODO: refactor hard coded constant as invalid position configuration constant prio 4
    /**
   * @brief transition process status
   * @details
   * 0: No motion, 1: move left, 2: moving right after Moved , 3: move right, 4: moving left after move right, 5: invalid
   */
    let m_eCardMotionStates = new Map(); // enum no transition //TODO: make i_eDirection and this the same enumeration prio 4

    /**
     * @brief default card transition speed in seconds for main motion (move track)
     */
    const m_uDefaultTransitionSpeed = 2;
    /**
     * @brief default card transition speed in seconds for after-motions
     */
    const m_uAfterMotionTransitionSpeed = m_uDefaultTransitionSpeed/4;

    const m_uCARDELEVATION = "5";
    /**
     *  @brief default card view flex box style dynamically updated for all cards
     *  */
    const m_pBox_1321iCardStyle0 = ref({
      order: 1,
      // transition: "transform " + toString(m_uDefaultTransitionSpeed) + "s",
      position: "relative",
      // transform: "translate(0%, 0%)",
      width: m_f64CARDWIDTH.toString() + "px",
      height: m_f64CARDHEIGTH.toString() + "px",
      "margin-right": m_uCardSpacing.value.toString() + "px",
      // display: flex,
      /* optional */
      // height: 100%,

      "background-color": "transparent",
      color: "white",
      "border-radius": "4px",
      // align-items: center,
      // justify-content: center,

      // "border-style": "dashed",
      // "border-color": "black",
    });

    /**
       *  @brief  card view flex box style dynamically updated for each cards
       *  */
    function getCardDynamicStyle() {// i_uPosIterator
      let cardStyle = m_pBox_1321iCardStyle0.value;
      // console.log(m_eLifeCycleHook+"!!!!!!!!!!!!!!!!****" +m_lstCardLefts.get(i_uPosIterator[0]));
      return cardStyle;
    }


    /**
     * @brief keep the status of the lifecycle hooks
     * @detail 
     * //TODO: introduvce a lifecycle hook enumeration with proper names instead of integers
     * for now 3 is onMounted, 4 is before update
     */
    let m_eLifeCycleHook = 0;


    /**
     * @brief pre initialize child components and reactive states before actual track is mounted
     * @details 
     * - init certain early consts
     * - initialize number of static items within caroussels
     * - initialize the data of those items to be loaded from the list of input data to be dispayed in total
     */
    onBeforeMount(() => {
      console.log(props.m_lstItems)
      m_NITEMS.value = props.m_lstItems.size;// the value 0 is to trigger proper error handling
      console.log(m_NITEMS.value)
      if (m_NITEMS.value) {
        // m_lstItems,m_lstCardViews
        // initialize the static number of items that must always be mounted no matter if visible or not
        // this number can be much smaller than the actual total number of card views to display.
        // In order to initialize this number, we need to estimate how many cards may be maximum visible
        // since beforeMount() doesnt yet allow access to real dom element, we can only estimate 
        // using a best card view width guess. this guess MUST(//TODO: prio 10) be verified in Mounted() hook.
        m_uNMAXVisibles.value = Math.floor(m_f64INNERWIDTH / (m_f64CARDWIDTH + 0.01 * m_f64INNERWIDTH));//TODO: prio 4 improve hard coded const; 0.01== 1% represent spacing default setting guess
        m_uNCARDVIEWS.value = (m_NITEMS.value > m_uNMAXVisibles.value) ?
          m_uNMAXVisibles.value + m_uNITEM_FACTOR_4_FAST_CAROUSELMOTION * getNCARDS_PER_MOTION() : m_NITEMS.value; //TODO: hard const multiplicatoon factor prio 2

        // now before the elements are mounted we need to initialize m_lstCardViews

        // since the iteration order of the map m_lstItems might not be in increaisng order of carousel 
        // positions depending on the type of primary key being used, and since we want to check that each
        // carousel position, is properly recognized as expected depending on the number of total items,
        // we will initialize a set of expected positions and check if they are all present only in the end
        let indexesExpected = new Set();
        let i = 0;
        props.m_lstItems.forEach(() => {
          if (i+1 <= m_uNCARDVIEWS.value) 
            indexesExpected.add(++i); 
        })        
        console.log(indexesExpected);
        for (let [iKey, oItem] of props.m_lstItems) {// key might be an item id, not suitable for ordering carousel
          m_lstDataItems.set(oItem.m_uCarouselPosition, iKey);// keep all item no matter if displayed or not
          if ((undefined != iKey) && (undefined != oItem) 
          && (m_uNCARDVIEWS.value > m_lstCardViews.value.size) ) {

            if (indexesExpected.has(oItem.m_uCarouselPosition)) {
              m_lstCardViews.value.set(oItem.m_uCarouselPosition, iKey);// we replace the keys with sorting integers#
             
              indexesExpected.delete(oItem.m_uCarouselPosition);
              console.log("CustomeCarousel::onBeforeMount item of index "
                + oItem.m_uCarouselPosition + " inserted... ")//TODO: prio 3 remove this
            } else {//TODO: prio 10 keep only in log level HIGH DEBUG
              console.log("CustomeCarousel::onBeforeMount critical Error: No item of index "
                + oItem.m_uCarouselPosition + " will be inserted for now; skipping: ")
            }
          }
        }

        if (indexesExpected.size) {
          console.log("CustomeCarousel::onBeforeMount: Following indexes were not used");//TODO:prio 8: keep only in debug log level
          console.log(indexesExpected);
        }

        // if we have enough items to need cycling but not enough to fill all static card views data
        // that are guessed necessary, we must fill the remaining items with same data and init coupling
        if (m_NITEMS.value > m_uNMAXVisibles.value) {// we need cycling
          if (m_uNCARDVIEWS.value > i) {// Nbr of items was smaller than necessary numbe rof card views
            let original = 0;
            while (m_uNCARDVIEWS.value >= ++i) {
              // m_lstCardDoubles.set( i, original);// data  index [original] is doubled at index i
              m_lstCardViews.value.set(i, m_lstCardViews.value.get(++original));// double entry of 
            }
          }
        }

        m_uNUnVisibles.right = (m_uNCARDVIEWS.value - m_uNMAXVisibles.value) / 2;
        m_uNUnVisibles.right = m_uNUnVisibles.right < 0 ? 0 : m_uNUnVisibles.right;
        m_uNUnVisibles.left = m_uNUnVisibles.right;

        console.log("m_uNMAXVisibles.value " + m_uNMAXVisibles.value);//TODO:
        console.log(m_uNCARDVIEWS.value);//TODO:
        console.log(m_lstCardViews.value);//TODO:
        console.log(m_uNUnVisibles);//TODO:
      }
      // update life cycle status
      m_eLifeCycleHook = 2;// onMounted called//TODO: prio 4 enumerize this
    })

    /**
     * @brief actions to be executed just when the component is mounted(and therewith its chid elements)
     * @details
     * 
     * - check that all expected card ids are there and initialize a proper maps
     * 
     */
    onMounted(() => {

      // initialize inner rect sizes
      if (null == m_oInnerRect.value && null != m_pInnerCarouselRef.value) {
        m_oInnerRect.value = m_pInnerCarouselRef.value.getBoundingClientRect();
        // m_oCarousselRect.value = m_pCarouselRef.value.getBoundingClientRect();//TODO: clean this up if not used prio 10
      }

      if (0 == m_uMotionStep.value) {
        // maybe the standard motion step should vary based on the maximum number of visible items?
        if (m_uNCARDVIEWS.value > m_uNMAXVisibles.value) {//TODO: for good cycling speed we may need to double some cards prio 10
          m_uMotionStep.value = getCardViewtotalSpace() * getNCARDS_PER_MOTION();
          // Math.max(m_uNMAXVisibles.value - 1, Math.ceil((m_uNCARDVIEWS.value - m_uNMAXVisibles.value) / 2));
          // (m_f64INNERWIDTH /   (m_uCardSpacing.value +m_f64CARDWIDTH) );
        } else {
          m_uMotionStep.value = 0;
        }

      }

      // extend the size of the track if the number of active card views demands it
      if ((null != m_pTrackRef.value) && (m_uNMAXVisibles.value < m_uNCARDVIEWS.value)
        && (0 == m_pTrackWidth.value)) {// m_pTrackWidth.value is used to do this only once
        // what should be the width of the track to contain all cards even after motion!?
        // the width track should be enough to contain all static card views plus enough
        // to still contain them after they are moved to the left or the right by 
        // given motion step(which is why m_uFACTOR_4_MOTION_EXTENSION_SECTION is added twice,
        // once for each hidden side[left,right])
        m_pTrackWidth.value = (m_uNMAXVisibles.value) * getCardViewtotalSpace()
          + m_uMotionStep.value * (m_uNITEM_FACTOR_4_FAST_CAROUSELMOTION
            + m_uFACTOR_4_MOTION_EXTENSION_SECTION + m_uFACTOR_4_MOTION_EXTENSION_SECTION);
        // how much percent more of the current width is that?
        let percent = (m_pTrackWidth.value / m_pTrackRef.value.getBoundingClientRect().width) * 100;
        m_pTrackStyle.value.width = percent.toString() + '%';// m_pTrackWidth.value.toString() + "px";
        //TODO: prio 2 remove the zero in the 2nd substraction here to activate the visual adaptation of first visible card
        m_uTrackInitMotionOffset = (1 + m_uFACTOR_4_MOTION_EXTENSION_SECTION) * m_uMotionStep.value
          - 0 * m_uNITEM_FACTOR_4_FAST_CAROUSELMOTION * m_uCardSpacing.value;
        m_pTrackRef.value.style.transform = `translateX(-${m_uTrackInitMotionOffset}px)`;

      } else {//TODO: prio 10: can this happen, and how is it related to other initializations?
        m_pTrackWidth.value = m_pTrackRef.value.getBoundingClientRect().width;
      }
      // init default offset of cards
      __InitCardInitialBiasInTrack(); 
      if (m_uNMAXVisibles.value >= m_uNCARDVIEWS.value) {
        // the method __InitorUpdateIndicesMaps() must be calle dat least once 
        // to apply  __InitCardInitialBiasInTrack results
        __InitorUpdateIndicesMaps();
      }

      // update life cycle status
      m_eLifeCycleHook = 3;// onMounted called
    });



    onUnmounted(() => {
      // cancel possible pending locks on mutex(es)
      cancelBlockingMutexWaiters();
    });




    /**
     * @brief initialize and/or update m_eCardMotionStates and m_oMapTrackCardIndices based
     * on m_lstTrackCardRefs without mutex usage.
     * @details initialize/update crucial map of card track position to card indice withing refs
     * such that the internal motion model has an initial motion state for each card and
     * we maintain a map of the cards' data to the dom reference index in
     * note that it is left to the caller to handle proper mutex/synchronization concepts
     * to avoid race conditions. this method just do the concrete executions
     * 
     * The method __InitIndicesMaps() must be called during onBeforeUpdate
     * to initialize key maps but also to set the start positions of the cardviews. if 
     * onMounted changes for example the inner track(which some times causes the order of the 
     * references in m_lstTrackCardRefs to change ), onBeforeUpdate will be called just after onMounted.
     * Later on another logic will rather be needed instead of __InitIndicesMaps()
     * to reorder the indices when the cards are moved. Herewith we need to know if 
     * beforeUpdate/onUpdated is being called for the first time in order to choose 
     * to call __InitIndicesMaps() or  __UpdateIndicesMaps()
     * notes that when the dom changes the order of references in the references map, 
     * it doesnt change anything in the data order
     * NB: we notice the array might have 20 elements while only 10 are actually 
     * valid due to the initial card transform(translation in css dynamic style)
     */
    function __InitorUpdateIndicesMaps() {
      console.log("__InitIndicesMaps:::::::::::::::" + m_lstTrackCardRefs.value.length + " " + m_eLifeCycleHook)

      // we want cards to be order in ascending position order
      let lstPos = [];
      for (let i_uCardRefIndex = 0; i_uCardRefIndex < m_lstTrackCardRefs.value.length; ++i_uCardRefIndex) {
        if (m_lstTrackCardRefs.value[i_uCardRefIndex].getBoundingClientRect().left){ 
          // the check for undefined was inseretd after we notice the array might have 20 elements
          // while only 10 are actually valid due to the initial card transform(translation in css dynamic style)
        lstPos.push({
          i: i_uCardRefIndex,
          pos: m_lstTrackCardRefs.value[i_uCardRefIndex].getBoundingClientRect().left
        });
        }
      }
      lstPos.sort((a, b) => { return a.pos < b.pos ? -1 : a.pos > b.pos ? 1 : 0; });
      if (lstPos.length) {
        console.log(lstPos)//TODO: prio 1 delete this
        for (let index = 0; index < lstPos.length; ++index) {
          // console.log(" at " +lstPos.at(index).i + " "+ lstPos.at(index).pos ) //TODO: prio 1 delete this
          let i_uCardKeyPosinTrack = index + 1;
          let strExpectedId = "Card" + i_uCardKeyPosinTrack;
          // m_lstTrackCardRefs might b a total chaos so we need to searc to find
          // the reference of the right matching card with the same id
          for (let i_uCardRefIndex = 0; i_uCardRefIndex < m_lstTrackCardRefs.value.length; ++i_uCardRefIndex) 
          {
            console.log( m_lstTrackCardRefs.value[i_uCardRefIndex].getBoundingClientRect().left)
            if (lstPos.at(index).pos && lstPos.at(index).pos ==
                m_lstTrackCardRefs.value[i_uCardRefIndex].getBoundingClientRect().left) 
            {// only process if card actually exists
              //TODO: optimize this nested for loops prio 7
              {
                console.log(m_lstCardLefts.get(i_uCardKeyPosinTrack))
                //TODO: prio 3 does the position change between onMounted and beforeUpdate initial calls rely on the track width initial change/extension?
                console.log("''''''''''''''check this out''''''''''''''''''''" + m_eLifeCycleHook)
               
                 // we need to init the start position both at 3 and 4, because the start positions
                // seems to change at 4 because of the changed trackwidth being reajusted 
                // which shall readjust card/spacing widths //TODO: prio 10 verify this in a proper test
                if (4 >= m_eLifeCycleHook) {
                  // the position stored at m_eLifeCycleHook=4 contaisn already the bias due to width change
                  m_lstTrackCardStartLefts.set(i_uCardKeyPosinTrack, 
                  m_lstTrackCardRefs.value[i_uCardRefIndex].getBoundingClientRect().left);
                } else {
                  m_lstTrackCardStartLefts.set(i_uCardKeyPosinTrack, m_lstTrackCardStartLefts.get(i_uCardKeyPosinTrack));
                }
                
                if (3 >= m_eLifeCycleHook) {
                   // see __InitCardInitialBiasInTrack()
                  m_lstCardLefts.set(i_uCardKeyPosinTrack, m_uCardInitialBiasInTrack);
                  // m_lstTrackCardRefs.value[i_uCardRefIndex].style.transform = `translateX(${m_uCardInitialBiasInTrack}px)`;
                } 
                if ((3 < m_eLifeCycleHook) || (m_uNMAXVisibles.value >= m_uNCARDVIEWS.value)){// rewrite m_lstCardLefts to keep ascending order of keys since their order changed during motion
                  m_lstCardLefts.set(i_uCardKeyPosinTrack, m_lstCardLefts.get(i_uCardKeyPosinTrack));
                  let X = m_lstCardLefts.get(i_uCardKeyPosinTrack) ;
                  m_lstTrackCardRefs.value[i_uCardRefIndex].style.transform = `translateX(${X}px)`;
                 
                }
                
                //TODO: prio 1(when debugging logs, when shifting inner track at mount, does this position chnage because of track initial shift? if yes we may want to check if the start position we need is the one at onBeforeUpdate or the one at onUpdate)
                console.log(m_lstTrackCardStartLefts.get(i_uCardKeyPosinTrack))
                console.log(m_lstCardLefts.get(i_uCardKeyPosinTrack) )
                console.log( m_lstTrackCardRefs.value[i_uCardRefIndex].getBoundingClientRect().left)
                console.log("''''''''''''''''''''''''''''''''''" + getCardWidth());
                if (m_uNMAXVisibles.value < m_uNCARDVIEWS.value) {
                  // we can just set state to none as long a this is called donly 
                  // in initial steps(mounted and just after ,mounted)
                  m_eCardMotionStates.set(i_uCardKeyPosinTrack,
                    enCardMotionStatus_t.eCardMotionStatus_none); // for each card index a motion state

                  // fill the id numbers used by the template to reference the cards
                  m_oMapTrackCardIndices.value.set(i_uCardKeyPosinTrack, i_uCardRefIndex);
                  console.log("updated " + m_lstTrackCardRefs.value[i_uCardRefIndex].id
                    + " ref " + i_uCardRefIndex + " pos " + i_uCardKeyPosinTrack +
                    "with pos " + m_lstTrackCardRefs.value[i_uCardRefIndex].getBoundingClientRect().left);
                }
              } 
              break; //TODO: replace with eresult prio 10
            } 
            else if (i_uCardRefIndex == m_lstTrackCardRefs.value.length-1){
              console.log("could not update " + strExpectedId + " " + i_uCardRefIndex +
                "with pos " + m_lstTrackCardRefs.value[i_uCardRefIndex].getBoundingClientRect().left);
            }
          }
        }
      }
    }

    /**
     * @brief callback called when secureInitIndicesMaps failed in acquiring mutex or some excception was thrown
     * @param {*} i_fctRelease 
     */
    function __onAcquiredBlockingMutexError(i_fctCancel) {//TODO: prio 8 move into external member private method
      console.log("__onAcquiredBlockingMutexError: error:");

      if (undefined != i_fctCancel && null != i_fctCancel &&
        ("function" == typeof i_fctCancel)) {
        i_fctCancel();//TODO: prio 10 test against cancel success???does canmcel makes sence here?(if timeout but throw occured maybe?)
        console.log("__onAcquiredBlockingMutexError: i_fctCancel called");
      } else {
        console.log("__onAcquiredBlockingMutexError: i_fctCancel not called");
      }

      console.log(m_oCarouselDataMapMutex.m_bIsLocked);//TODO: prio 1 comment //prio 10: keep in debug log level very high
    }

    /**
     * @brief callback called when secureInitIndicesMaps succeeded in acquiring mutex
     * @param {*} i_fctRelease 
     */
    function __onAcquiredBlockingMutexSuccess(i_fctRelease) {//TODO: prio 8 move into external member private method
      if (undefined != i_fctRelease && null != i_fctRelease &&
        ("function" == typeof i_fctRelease)) {
        i_fctRelease();//TODO: prio 10 test against release success???
        console.log("__onAcquiredBlockingMutexSuccess: i_fctRelease called " + m_oCarouselDataMapMutex.m_bIsLocked);
      } else {//TODO: prio 1 comment //prio 10: keep in debug log level very high
        console.log(i_fctRelease);
        console.log("__onAcquiredBlockingMutexSuccess: i_fctRelease not called " + m_oCarouselDataMapMutex.m_bIsLocked);
      }
    }

    /**
     * @brief calls __InitIndicesMaps() using await to acquire mutex m_oCarouselDataMapMutex
     */
    async function secureInitorUpdateIndicesMaps() {
      console.log("secureInitIndicesMaps:::::::::::::::::::::::"+ m_uNCARDVIEWS.value + " " + m_eLifeCycleHook);
      console.log(":21:::" + m_oCarouselDataMapMutex.m_bIsLocked + " " + m_uNCARDVIEWS.value);//TODO: prio 1 comment //prio 10: keep in debug log level very high

      let cancelFct1 = null;
      try {
        let { releaseFct, cancelFct } = acquireBlockingMutex(__InitorUpdateIndicesMaps, m_uNMutexTimeOut);
        if (releaseFct) {
          __onAcquiredBlockingMutexSuccess(releaseFct);
        } else if (cancelFct) {
          __onAcquiredBlockingMutexError(cancelFct1);
        }
      } catch (error) {
        console.log("secureInitIndicesMaps: error:");
        console.log(error);
      }

      console.log(":31:::" + m_oCarouselDataMapMutex.m_bIsLocked);//TODO: prio 1 comment //prio 10: keep in debug log level very high
    }


    // /**
    //  * @brief calls __UpdateIndicesMaps() using await to acquire mutex m_oCarouselDataMapMutex
    //  */
    // async function secureUpdateIndicesMaps() {
    //   console.log("secureUpdateIndicesMaps:::::::::::::::::::::::"+ m_uNCARDVIEWS.value + " " + m_eLifeCycleHook);
    //   console.log(":22:::" + m_oCarouselDataMapMutex.m_bIsLocked + " " + m_uNCARDVIEWS.value);//TODO: prio 1 comment //prio 10: keep in debug log level very high
    //   let cancelFct1 = null;
    //   try {
    //     let { releaseFct, cancelFct } = acquireBlockingMutex(__UpdateIndicesMaps, m_uNMutexTimeOut);
    //     if (releaseFct) {
    //       __onAcquiredBlockingMutexSuccess(releaseFct);
    //     } else if (cancelFct) {
    //       __onAcquiredBlockingMutexError(cancelFct1);
    //     }
    //   } catch (error) {
    //     console.log("secureUpdateIndicesMaps: error:");
    //     console.log(error);
    //   }
    //   console.log(":32:::" + m_oCarouselDataMapMutex.m_bIsLocked);//TODO: prio 1 comment //prio 10: keep in debug log level very high
    // }


    /**
     * @brief action to be taken before dom elements are updated
     * @details
     * - member maps needs to be sorted. especialy the ones used directly for display
     * sorting should not change any associations nor reduce the size of the map, just
     * reorder the keys such that when onUpdated() is calle the card views have the
     * expected ordering
     * NB: onBeforeUpdate might get call twice after onmounted due to initial translations
     */
    onBeforeUpdate(() => {
      console.log("onBeforeUpdate:::::::::::::::::::::::"+ m_lstTrackCardRefs.value.length + " " + m_eLifeCycleHook);
     
      if (5 > m_eLifeCycleHook) {//TODO: prio 4 improve hard coded constant
        secureInitorUpdateIndicesMaps();
      } 
      // else if (10 <= m_eLifeCycleHook) {//TODO: should this always be enumerized 13? prio 6
      //   secureUpdateIndicesMaps();
      // }
      console.log(m_lstTrackCardStartLefts);//TODO: prio 3 comment tis//TODO: prio 10 keep this only in log level HIGH DEBUG
      console.log(m_lstCardLefts);
      if (5 > m_eLifeCycleHook)
        m_eLifeCycleHook = 4;// onBeforeUpdate called just after onmounted //TODO: prio 4 hard coded const improve (enum)
      else {//TODO: prio 4 improve hard coded bias and enum
        // using a bias of 10 to diffe the very first calls of beforeUpdate and the one sjust after onmounted()
        m_eLifeCycleHook = 10 + 4;//TODO: prio 4 improve hard coded bias and enum
      }
    })

    /**
     * @brief update m_eCardMotionStates after transitionEnds
     */
    function __updateMotionState() {//TODO: prio 8 move this into proper view model file as private method
      for (let [iCardPos, iCardRef] of m_oMapTrackCardIndices.value.entries()) {
        let cardPos = m_lstTrackCardRefs.value[iCardRef].getBoundingClientRect().left;
        console.log(iCardPos + " # " + cardPos + " ## " + m_lstCardLefts.get(iCardPos) + " # " + iCardRef)
        if ((enCardMotionStatus_t.eCardMotionStatus_afterLeft 
              <= m_eCardMotionStates.get(iCardPos)) && 
              (enCardMotionStatus_t.eCardMotionStatus_afterRightLeft 
              >= m_eCardMotionStates.get(iCardPos))) {// if motion was properly postprocessed
          m_eCardMotionStates.set(iCardPos, enCardMotionStatus_t.eCardMotionStatus_none);
          // m_lstTrackCardRefs.value[iCardRef].style.opacity = 1;
        }
      }
      console.log(m_eCardMotionStates)
    }

    /**
     * @brief calls UpdateMotionState() using await to acquire mutex m_oCarouselDataMapMutex
     */
    async function secureUpdateMotionState() {
      console.log("secureUpdateMotionState:::::::::::::::::::::::");
      console.log(":23:::" + m_oCarouselDataMapMutex.m_bIsLocked + " " + m_uNCARDVIEWS.value);//TODO: prio 1 comment //prio 10: keep in debug log level very high
      let cancelFct1 = null;
      try {
        let { releaseFct, cancelFct } = acquireBlockingMutex(__updateMotionState, m_uNMutexTimeOut);
        if (releaseFct) {
          __onAcquiredBlockingMutexSuccess(releaseFct);
        } else if (cancelFct) {
          __onAcquiredBlockingMutexError(cancelFct1);
        }
      } catch (error) {
        console.log("secureUpdateMotionState: error:");
        console.log(error);
      }
      console.log(":33:::" + m_oCarouselDataMapMutex.m_bIsLocked);//TODO: prio 1 comment //prio 10: keep in debug log level very high
    }


    /**
     * @brief callback called as lifecycle hook everytime dom elements are updated
     * @details
     * - view model will trigger this call when updating carousel card positions/indices, which v-for should react upon
     */
    onUpdated(() => {
      console.log("onUpdated::::::::::::::::::::: " + m_lstTrackCardRefs.value.length 
      + " "  + m_lstCardViews.value.size 
      + " " + m_eLifeCycleHook);//TODO:
      console.log(m_oCarouselDataMapMutex.m_bIsLocked);

      if (4 === m_eLifeCycleHook) //TODO: prio 2 remove this commented part
      {
        // secure call to update some indices maps
        secureInitorUpdateIndicesMaps();
      }
      // secureUpdateIndicesMaps();
      if (10 < m_eLifeCycleHook) 
      {
        secureUpdateMotionState();//TODO: prio 3: either do tis only if hook status is 14 or put this in onBeforeUpdate(? what might be the impact? which test(even manual with logs) must still work?
      }
      if (4 >= m_eLifeCycleHook)
        m_eLifeCycleHook = 5;// onBeforeUpdate called just after onmounted //TODO: prio 4 hard coded const improve (enum)
      else {
        // using a bias of 10 to diffe the very first calls of beforeUpdate and the one sjust after onmounted()
        m_eLifeCycleHook = 10 + 5;//TODO: prio 4 improve hard coded bias and enum
      }
    })

    /* *************************** Methods necessary for Carousel motion management  *********************/

    /**
     * @brief detect card visibility mode to identify in which carousel area the card is
     * @details based on the the relative left and right location of the card's
     * getBoundingClientRect() returned value(s), plus the Carousel border locations,
     * this method defines 5 differents areas where the card might be based on which
     * certain motions might e necessary after an riginal translation of all cards
     * 
     * STRUCTURE OF TRACK WITH DIFFERENT VISIBILITY SECTIONS(where VIS1 means the visibility status on that section is 1):
     * [ EXTENSION 1 for motion to leftVIS1]+[HIDDEN PART VIS1..VIS4]+[VISIBLE PART VIS5]+[HIDDEN PART VIS3..VIS2]+[ EXTENSION for motion to right VIS2]
     *
     * 
     * @return:
     * //TODO: move these values into enumeration and improve this function comment prio 4
     * - 1: the card is fully hidden on the left
     * - 2: the card is fully hidden on the right
     * - 3: the card is partially hidden on the left of the carousel
     * - 4: the card is partially hidden on the right of the carousel
     * - 5: the card is fully visible
     */
    function getCardPosVisibilityStatus(i_uCardLeft, i_uCardRight) {
      let eRes = 0;
      if (m_oInnerRect.value.left >= i_uCardRight) {
        eRes = 1; // the card is fully hidden on the left
      } else if (m_oInnerRect.value.right <= i_uCardLeft) {
        eRes = 2; // the card is fully hidden on the right
      } else if (
        m_oInnerRect.value.left <= i_uCardLeft &&
        m_oInnerRect.value.right >= i_uCardRight
      ) {
        eRes = 5; // the card is fully visible
      } else if (
        m_oInnerRect.value.left < i_uCardRight &&
        m_oInnerRect.value.left > i_uCardLeft
      ) {
        eRes = 3; // the card is partially hidden on the left
      } else if (
        m_oInnerRect.value.right > i_uCardLeft &&
        m_oInnerRect.value.right < i_uCardRight
      ) {
        eRes = 4; // the card is partially hidden on the right
      }

      return eRes;
    }


    /**
     * @brief get which cards are the very first and very last based on their
     * x-positions within the track(since they can be in a different order 
     * than in the beginning)
     */
    function getFirstorLastCard(i_bRearorfront) {
      let uLastCardPosIndex = undefined;
      let uFirstCardPosIndex = undefined;
      let uLastorFirstCardPosIndex1 = undefined;
      let lstSortedGlobalPos = [];
      for (let iCardPos of m_lstTrackCardStartLefts.keys()) {
        lstSortedGlobalPos.push({
          pos: m_lstTrackCardStartLefts.get(iCardPos) + m_lstCardLefts.get(iCardPos),
          i: iCardPos
        });
      }
      // notes that sorting after indices must automatically also ALWAYS sort aftr positions (//TODO: prio 11 kind of module/integration test necessary for that ALWAYS word)
      lstSortedGlobalPos.sort((a, b) => { 
        return a.pos < b.pos ? -1 : a.pos > b.pos ? 1 : 0; 
      });
      console.log(lstSortedGlobalPos);

      if (lstSortedGlobalPos.length) {
        uFirstCardPosIndex = lstSortedGlobalPos.at(0).i;
        uLastCardPosIndex = lstSortedGlobalPos.at(lstSortedGlobalPos.length-1).i;
      }  
      if (i_bRearorfront) {
        uLastorFirstCardPosIndex1 = uLastCardPosIndex;
      } else {
        uLastorFirstCardPosIndex1 = uFirstCardPosIndex;
      }
      console.log("getFirstorLastCard: 1st " + uFirstCardPosIndex +
      " last " + uLastCardPosIndex + " lastorFirst " + uLastorFirstCardPosIndex1 );
      return {
        uFirstCardPosIndex,
        uLastCardPosIndex,
        uLastorFirstCardPosIndex1
      };
    }


    /**
     * @brief post process main motion to update key maps tracking cards states and/or
     * move (especially hidden) cards accordingly to a cylic carousel behavior
     * 
     * @details
     * This method is called on one single card and consider all other cards to currently 
     * be static within the position model computations, awaiting it t finish its motion
     * if we just moved to the left and needs to circle from left(front) to right(back) :
        - all cards with visibility 1 are outside on the left of the visible track part
         getNCARDS_PER_MOTION() cards might be moved on the left hidden part of track if
         moving left, or getNCARDS_PER_MOTION() might get out of the left hidden part of 
         track if moving right
       - all cards with visibility 2 are outside on the right of the visible track part
         getNCARDS_PER_MOTION() cards might be moved to the right hidden part of track if
         moving right, or getNCARDS_PER_MOTION() might get out of the right hidden part of track if moving left 
       - moving card to the right/left will cause the static number of mounted cards to be circled;,
       if they land in the motion extension hidden xection of the track
       Hence after a motion to the left, there can be for exmaple 2xgetNCARDS_PER_MOTION() cards hidden
       on the left, but only the very first getNCARDS_PER_MOTION() cards outside on the left will 
       be shifted to the right end of track, just after the very last card. the same is done in opposite
       direction when moving to the right
      - when this method is called by a card that has just executed its main motion left or right,
        if that card doesnt get outside of "main track section", ans also if that card was visible
        before the main motion and just got hidden(and therwith is not in the motion extension of the hidden
        track part(s), THEN nothing must be done, otherwise(if it does land in that motion extension section)
        the position of the current card  must be shifted to the end/beginning of track with a proper
        distance to current last/first card,
     *
     * NB: THE CALLER IS RESPONSEABLE FOR PROPERLY SYNCHRONIZING THIS CALL WITH ANY KIND OF EXTERNAL
     * UPDATES OF KEY DATA MEMBERS(m_oMapTrackCardIndices,m_eCardMotionStates, m_lstCardViews)
     * */
    function __UpdateCyclicCarousselModel(i_uCardKeyPosinTrack, i_uCardRefIndex) {

      if (!m_oMapTrackCardIndices.value.has(i_uCardKeyPosinTrack)) {
        throw new Error(
          "CustomCarousel::__UpdateCyclicCarousselModel: INCONSITENCY:" +
          " indice " +
          i_uCardKeyPosinTrack +
          "Having [first,Last]]=" +
          m_oMapTrackCardIndices.value[1] +
          " " +
          m_oMapTrackCardIndices.value[m_oMapTrackCardIndices.value.size]
        );
        //TODO: implement exception handling for this with proper strategy and enumeration prio 6
      }
      // ++count;//TODO: delete this prio 1
      //TODO: prio 2 maybe useful? !!!!!1  IMPLEMENT KIND OF SORTED MUTEX:wait while any preceding card is still not finished. avoid endless waiting???

      let eResult = 1;
      let bMovedLeft = enCardMotionStatus_t.eCardMotionStatus_afterLeft == m_eCardMotionStates.get(i_uCardKeyPosinTrack);
      let bMovedRight = enCardMotionStatus_t.eCardMotionStatus_afterRight == m_eCardMotionStates.get(i_uCardKeyPosinTrack);
      if (!bMovedLeft && !bMovedRight)
      throw new Error(
          "CustomCarousel::__UpdateCyclicCarousselModel:" + m_eCardMotionStates.get(i_uCardKeyPosinTrack)
          + " " +i_uCardKeyPosinTrack );//TODO: delete this prio 1
      if (bMovedLeft || bMovedRight) {
        // per default the non extra motion central section of track contains: m_uNCARDVIEWS cards
        // of those getNCARDS_PER_MOTION() should be hidden on each non visible side
        // of the track without being on the extra motion extension of the track width
        // hence this card is on the extendion part if getNCARDS_PER_MOTION() cards after it
        // we are not on the visible section   
        let direction = bMovedLeft ? 1 : -1;

        // if we moved left the visibility check value must be 1, else it must be 2
        const NCHECKVISIBLITYZONE = bMovedLeft ? 1 : 2;
        let bToCircleShift = false;
        let ownVisibiltyStatus = 0;

        // only card within the motion extension width of the track must be circled
        // the motion extention track section has a on each hidden side a size of 
        // (m_uFACTOR_4_MOTION_EXTENSION_SECTION*motionStep)
        // a card would be in the motion extention of the hidden track part, 
        // if when we move in opposite direction(opposite to the current main motion direction)
        // by a step as wide as m_uFACTOR_4_MOTION_EXTENSION_SECTION*motionStep, then its visibility
        // status is MOSTLY NOT visible(); a threshold will decide what "mostly" 
        // means in case it is visible in part.). if it is hidden after main motion
        // but not within the extended section, shifting 
        // it by that distance would have made it completely visible.
        // we must not forget  we must use not the relative position cumulated in m_lstCardLefts
        // but the global position relative to InnerTrack coordinates origin
        let XVisibleStart = (m_lstCardLefts.get(i_uCardKeyPosinTrack) 
        + m_lstTrackCardStartLefts.get(i_uCardKeyPosinTrack) )
        + direction*m_uFACTOR_4_MOTION_EXTENSION_SECTION*m_uMotionStep.value;        
        let XVisibleEnd = XVisibleStart + m_f64CARDWIDTH;
        ownVisibiltyStatus = getCardPosVisibilityStatus(XVisibleStart, XVisibleEnd);
        bToCircleShift = NCHECKVISIBLITYZONE == ownVisibiltyStatus;// first visibility check
        if (!bToCircleShift) {// if first logic was unsuccessfull try the more dynamic logic first too
          if (bMovedLeft && 3 == ownVisibiltyStatus) {// only part is visible? how much of it?
            // assess if the target check position is more hidden than visible
            XVisibleEnd = (XVisibleEnd - m_oInnerRect.value.left) / m_f64CARDWIDTH;
            bToCircleShift = m_fCARD_VISIBILITY_THRESHOLD > XVisibleEnd;
            ownVisibiltyStatus += XVisibleEnd;// just because of logging
          }//TODO: prio 10 which impact does the difference between > and >= has here?
          else if (!bMovedLeft && 4 == ownVisibiltyStatus) {// only part is visible? how much of it?
            // assess if the target check position is more hidden than visible
            XVisibleStart = (m_oInnerRect.value.right - XVisibleStart) / m_f64CARDWIDTH;//TODO: prio 11 unit test case where more is visible and/or mor eis hidden than the half
            bToCircleShift = m_fCARD_VISIBILITY_THRESHOLD > XVisibleStart;
            ownVisibiltyStatus += XVisibleStart;// just becaose of logging
          }//TODO: prio 10 which impact does the difference between > and >= has here?
        }
        if (!bToCircleShift) {
          // if both logic were unsuccessfull check if motion will be leaving
          // some empty space which willl need to be filled by shifting this card

        }

        //TODO: delete or keep only high level debug log?prio 10
        console.log("__UpdateCyclicCarousselModel(): shifting? " + bToCircleShift
          + " ownVisibiltyStatus " + ownVisibiltyStatus + " XVisibleStart " 
          + (m_lstCardLefts.get(i_uCardKeyPosinTrack) + m_lstTrackCardStartLefts.get(i_uCardKeyPosinTrack)
          + direction*m_uFACTOR_4_MOTION_EXTENSION_SECTION*m_uMotionStep.value) + " whereby startPos "
          +  m_lstTrackCardStartLefts.get(i_uCardKeyPosinTrack) 
          + " NCHECKVISIBLITYZONE" + NCHECKVISIBLITYZONE + " pos "
          + m_lstCardLefts.get(i_uCardKeyPosinTrack)
          + "Leftreal " + m_lstTrackCardRefs.value[i_uCardRefIndex].getBoundingClientRect().left
          + " id " + m_lstTrackCardRefs.value[i_uCardRefIndex].id);
        console.log(m_oInnerRect.value)
        console.log(m_f64CARDWIDTH)
        if (bToCircleShift) {// use result of vibility and shift necessity checks
          // if we moved left, this card will be repositioned to the end of unvisible track,
          // NB: the expressions/words "last card" and "end of track" here are for LeftRight post processing. 
          // For RightLeft after-right-motion-processing you should replace those words with "first card" 
          // and "beginning of track"....
          {
            //TODO: prio 10 can  som array parsing loops(explicits and implicits ones) be optimized? 
            // fetch indice of the cardview with the very first(after right motion)
            // or the very last (after left motion position)
            let {
              uFirstCardPosIndex,
                uLastCardPosIndex,
                uLastorFirstCardPosIndex1
            } =  getFirstorLastCard(bMovedLeft);

            console.log(uLastCardPosIndex + " last__first " + uFirstCardPosIndex
              + " motionState " + m_eCardMotionStates.get(uLastorFirstCardPosIndex1));

            const uLastorFirstCardRefIndex = m_oMapTrackCardIndices.value.get(uLastorFirstCardPosIndex1);
            console.log(uLastorFirstCardPosIndex1 + " lastorfirst-ref-> " + uLastorFirstCardRefIndex);

            // the current card when sifted will just get the next available data from data model
            // hence it doesnt matter what it rank or position or order was before it was shifted
            const deltaIndice = 1;// there was a more compley logic before based on card rank//TODO: prio 10 remove this const 1 is unnecessary

            // by the time we execute this, the last card may actually not yet have been moved,
            // therewith  m_lstCardLefts.get(uLastorFirstCardPosIndex) might be its position
            // before its main motion. Still, We don't need to predict its position after-motion 
            // here, since the current card to be shifted will be moved again after that lastorfirst card
            // will have been moved too
            // transform from local in global coordinates
            let newCardGlobalPosX = m_lstTrackCardStartLefts.get(uLastorFirstCardPosIndex1) +
            m_lstCardLefts.get(uLastorFirstCardPosIndex1);
            
            console.log("pos of 1st/last item " + uLastorFirstCardPosIndex1 + "  rel "
              + m_lstCardLefts.get(uLastorFirstCardPosIndex1) + " global " + newCardGlobalPosX)
            // compute from last card pos what should be this card's new (global) pos based on its
            // current global location (possibly hidden in the shaddow of the track)
            newCardGlobalPosX += deltaIndice * direction * getCardViewtotalSpace();
            // update card indices within member additional maps and m_lstCardViews
            console.log("newCardGlobalPosX" + newCardGlobalPosX + " Indice " + i_uCardKeyPosinTrack);
            // key display dom update!
            {
              // now we need to update that cards data item relationship based on the complete list of items
              // the id within m_lstItems found at m_lstCardViews.value.get(newCardIndice) may not be correct anymore
              // the current data item displayed at urrent last card view is m_lstCardViews.value.get(uLastorFirstCardPosIndex1)
              console.log("last/first item " + uLastorFirstCardPosIndex1 + " model position" +
                props.m_lstItems.get(m_lstCardViews.value.get(uLastorFirstCardPosIndex1)).m_uCarouselPosition);
              // check carousel references and position logic consistency:(nb: position in CardViews 
              // is not equal to position in data model)
              let lastorFirstCardModelDataItemIndex = props.m_lstItems.get(m_lstCardViews.value
                .get(uLastorFirstCardPosIndex1)).m_uCarouselPosition;
              console.log("lastorFirstCardModelDataItemIndex" + lastorFirstCardModelDataItemIndex)
              if ((undefined == lastorFirstCardModelDataItemIndex) ||
                (m_lstDataItems.get(lastorFirstCardModelDataItemIndex) != m_lstCardViews.value.get(uLastorFirstCardPosIndex1))) {
                throw new Error(
                  "CustomCarousel::__UpdateCyclicCarousselModel(): INCONSISTENCY IN position logical model "
                  + uLastorFirstCardPosIndex1 + "!= " + m_lstCardViews.value.get(uLastorFirstCardPosIndex1).m_uCarouselPosition
                  + " " + m_lstDataItems.get(lastorFirstCardModelDataItemIndex)
                );//TODO: prio 8
              }
              //TODO: logs...
              console.log("m_lstDataItems.get(lastorFirstCardModelDataItemIndex) " 
              + m_lstDataItems.get(lastorFirstCardModelDataItemIndex))
              console.log("m_lstCardViews.value.get(uLastorFirstCardPosIndex1) " 
              + m_lstCardViews.value.get(uLastorFirstCardPosIndex1))
              // note that carousel position value in data model m_lstdataItems and actual index in m_lstCardViews might 
              //differ since we do not display/nount everything
              // get the model data item matching the new card display position(it might be an item that has already been
              // in used, but it might also not)
              let newCardModelDataItemIndex = lastorFirstCardModelDataItemIndex + deltaIndice * direction;
              console.log("newCardModelDataItemIndex " + newCardModelDataItemIndex +
                "deltaIndice " + deltaIndice)
              if (!m_lstDataItems.has(newCardModelDataItemIndex)) {
                // newCardModelDataItemIndex might be too high or too small -> we need to cycle it
                // it cannot exceed the limits by more than  getNCARDS_PER_MOTION()  though!
                if (m_NITEMS.value < newCardModelDataItemIndex) {// circle to front (m_NITEMS.value + getNCARDS_PER_MOTION() >= newCardModelDataItemIndex)
                  newCardModelDataItemIndex -= m_NITEMS.value;
                } else if (1 > newCardModelDataItemIndex) // in negative zero will also be  new indice--> < and not <=
                {// circle to back (- getNCARDS_PER_MOTION() < newCardModelDataItemIndex)
                  newCardModelDataItemIndex += m_NITEMS.value;
                } else {
                  console.log("*******************************");
                  console.log(m_lstDataItems);//TODO: high debig level prio 10
                  console.log(m_lstCardViews);
                  console.log(props.m_lstItems);
                  throw new Error(
                    "CustomCarousel::__UpdateCyclicCarousselModel(): INCONSISTENCY IN new position model at"
                    + uLastorFirstCardPosIndex1 + "-->" +
                    props.m_lstItems.get(m_lstCardViews.value.get(uLastorFirstCardPosIndex1)).m_uCarouselPosition
                    + " not applicable new index  " + newCardModelDataItemIndex);
                }
                console.log(" circled newCardModelDataItemIndex in model to" + newCardModelDataItemIndex)
              }


              //TODO: prio 5 try to standardize repeating line of code in a generic function that can be reused for different maps

              if (!m_lstDataItems.has(newCardModelDataItemIndex) ||
                !props.m_lstItems.has(m_lstDataItems.get(newCardModelDataItemIndex))) {
                console.log("*******************************");
                console.log(m_lstDataItems);//TODO: high debig level prio 10
                console.log(m_lstCardViews);
                console.log(props.m_lstItems);
                throw new Error(
                  "CustomCarousel::__UpdateCyclicCarousselModel(): INCONSISTENCY IN target position model at "
                  + i_uCardKeyPosinTrack + " uLastorFirstCardPosIndex1 "
                  + uLastorFirstCardPosIndex1 + "-->" + lastorFirstCardModelDataItemIndex
                  + " not found  " + newCardModelDataItemIndex);
              }
              console.log("new model data index " + newCardModelDataItemIndex + " for shifted card  "
                + i_uCardKeyPosinTrack );

              // update display card view data model binding
              m_lstCardViews.value.set(i_uCardKeyPosinTrack, m_lstDataItems.get(newCardModelDataItemIndex));
              // m_lstCardViews.value.get(i_uCardKeyPosinTrack).value = m_lstDataItems.get(newCardModelDataItemIndex);
              // // clean up old indices//TODO: prio 3 delete this

              console.log(m_lstCardLefts.get(i_uCardKeyPosinTrack) + " " + m_lstTrackCardStartLefts.get(i_uCardKeyPosinTrack) +
                " " + m_uCardSpacing.value + " newCardGlobalPosX " + newCardGlobalPosX 
                + " newCardLocalX " + (newCardGlobalPosX - m_lstTrackCardStartLefts.get(i_uCardKeyPosinTrack)));//TODO: prio 10 keep this only in high debug but first //TODO:comment it prio 1
              
              if (m_lstCardLefts.has(i_uCardKeyPosinTrack)) {
                m_lstCardLefts.set(i_uCardKeyPosinTrack, newCardGlobalPosX - m_lstTrackCardStartLefts.get(i_uCardKeyPosinTrack));
                // // clean up old indices//TODO: prio 3 delete this
                // if (!m_lstCardLefts.delete(i_uCardKeyPosinTrack)) {
                //   console.log("__UpdateCyclicCarousselModel(): failed to update m_lstCardLefts at " + i_uCardKeyPosinTrack);
                // }
              } else {
                console.log(m_lstCardLefts);
                throw new Error(
                  "CustomCarousel::__UpdateCyclicCarousselModel(): INCONSISTENCY IN m_lstCardLefts "
                  + i_uCardKeyPosinTrack + " Not Found and/or found " + i_uCardKeyPosinTrack
                );//TODO: prio 8: process exception properly
              }

              let motionStep = m_lstCardLefts.get(i_uCardKeyPosinTrack);
              m_lstTrackCardRefs.value[i_uCardRefIndex].style.transition =
                "transform " + m_uAfterMotionTransitionSpeed.toString() + "s ease";
              // m_lstTrackCardRefs.value[i_uCardRefIndex].style.opacity  = 0;
              // m_lstTrackCardRefs.value[i_uCardRefIndex].style.visibility   = 'hidden';
              m_lstTrackCardRefs.value[i_uCardRefIndex].style.transform = `translateX(${motionStep}px)`;
              
            }



            //const uNbrofCardnonVisible = deltaTrackUnVisibleRestSection /(m_uCardSpacing.value + m_f64CARDWIDTH);
            if (bMovedLeft) {
              m_eCardMotionStates.set(i_uCardKeyPosinTrack,
                enCardMotionStatus_t.eCardMotionStatus_afterLeftRight);
            } else {
              m_eCardMotionStates.set(i_uCardKeyPosinTrack,
                enCardMotionStatus_t.eCardMotionStatus_afterRightLeft);
            }
            console.log("__UpdateCyclicCarousselModel(): shifting i_uCardKeyPosinTrack " + i_uCardKeyPosinTrack +
              " i_uCardRefIndex " + i_uCardRefIndex + " uLastorFirstCardRefIndex " + uLastorFirstCardRefIndex);
            console.log("__UpdateCyclicCarousselModel(): deltaIndice " + deltaIndice +
              " newCardIndice " + i_uCardKeyPosinTrack);
            console.log("__UpdateCyclicCarousselModel(): newCardGlobalPosX " + newCardGlobalPosX +
              " newCardIndice " + i_uCardKeyPosinTrack);




            eResult = 3;


            console.log("m_eCardMotionStates.get(newCardIndice)" + m_eCardMotionStates.get(i_uCardKeyPosinTrack))
          }
          // else if ((1 == targetVisibiltyStatus)||(2 == targetVisibiltyStatus))  {
          //   throw new Error(
          //     "CustomCarousel::__UpdateCyclicCarousselModel(): targetVisibiltyStatus "
          //     + targetVisibiltyStatus + " inconsistent for "  + i_uCardKeyPosinTrack
          //   );//TODO: prio 8: process exception properly
          // }
        } else { //if ((3 == targetVisibiltyStatus)||(4 == targetVisibiltyStatus)||(5 == targetVisibiltyStatus)) 

        }

        console.log(
          [...m_oMapTrackCardIndices.value.keys()],
          [...m_oMapTrackCardIndices.value.values()]
        );
        console.log(
          [...m_lstCardViews.value.keys()],
          [...m_lstCardViews.value.values()]
        );
        console.log(
          [...m_lstCardLefts.keys()],
          [...m_lstCardLefts.values()]
        );
        console.log(
          [...m_lstTrackCardStartLefts.keys()],
          [...m_lstTrackCardStartLefts.values()]
        );
        console.log(
          [...m_eCardMotionStates.keys()],
          [...m_eCardMotionStates.values()]
        );
        if (0 < eResult) {
          console.log("__UpdateCyclicCarousselModel(): shifting i_uCardKeyPosinTrack " + i_uCardKeyPosinTrack +
            " i_uCardRefIndex " + i_uCardRefIndex + " Done! " + eResult);
        }
      } else {
        eResult = 0;
      }
      if (0 != eResult) {
        console.log(
          "CustomCarousel::__UpdateCyclicCarousselModel: Moved OK: direction=" +
          m_eCardMotionStates.get(i_uCardKeyPosinTrack)
        );
      } else {
        console.log(
          "CustomCarousel::__UpdateCyclicCarousselModel: Moving NotOk: direction=" +
          m_eCardMotionStates.get(i_uCardKeyPosinTrack)
        );
      }
      return eResult;
    }

    /**
     * @brief do necessary motion model updates after UpdateCyclicCarousselModel
     * has been called
     * @details the motion state LeftRight/Righteft is changed to the state
     * RightLeftUpdatePending.
     * In case orrurs occured the motion state is set to none.
     * 
     * @param {*} iCardPos 
     * @param {*} iCardRef 
     * @param {*} i_strErr 
     */
    function postUpdateCyclicCarousselModel(iCardPos, iCardRef, i_strErr) {
      if (null === i_strErr || 0 == i_strErr.length) {
        // after motion post processing worked and we need to clean up
        //TODO: prio 5 analyse and improve or possibly delete commented lines?
        // if (enCardMotionStatus_t.eCardMotionStatus_afterLeft == m_eCardMotionStates.get(iCardPos))
        // {
        //     m_eCardMotionStates.set(iCardPos, 
        //     enCardMotionStatus_t.eCardMotionStatus_afterMotionUpdatePending); // stop the motion process completely
        // } 
        console.log("CustomCarousel::postUpdateCyclicCarousselModel: set " + iCardRef + " " + iCardPos
            + " " + m_eCardMotionStates.get(iCardPos));
      }
      else {
        console.log(i_strErr);
        console.log("CustomCarousel::postUpdateCyclicCarousselModel: error catched " + iCardRef + " " + iCardPos
          + " " + m_eCardMotionStates.get(iCardPos));
        if (0 < m_eCardMotionStates.get(iCardPos)) {
          //TODO: analyse and improve catch features prio 3
          m_eCardMotionStates.set(iCardPos, enCardMotionStatus_t.eCardMotionStatus_none); // stop the motion process completely
        }
      }
      
    }

    /**
     * @brief calls UpdateCyclicCarousselModel() using await to acquire mutex m_oCarouselDataMapMutex
     * //TODO: prio 3 the 3 secure***** methods can be combined into one generic method based on secureUpdateCyclicCarousselModel
     * @param {*} i_fctPostProcess callback called after try or catch if not null.
     */
    async function secureUpdateCyclicCarousselModel(i_fctTarget, i_fctPostProcess) {
      console.log("secureUpdateCyclicCarousselModel:::::::::::::::::::::::");
      console.log(":23:::" + m_oCarouselDataMapMutex.m_bIsLocked + " " + m_uNCARDVIEWS.value);//TODO: prio 1 comment //prio 10: keep in debug log level very high
      let cancelFct1 = null;
      let strError = null;
      try {
        let { releaseFct, cancelFct } = acquireBlockingMutex(i_fctTarget, m_uNMutexTimeOut);
        if (releaseFct) {
          __onAcquiredBlockingMutexSuccess(releaseFct);
        } else if (cancelFct) {
          __onAcquiredBlockingMutexError(cancelFct1);
        }
      } catch (error) {
        console.log("secureUpdateCyclicCarousselModel: error:");
        console.log(error);
        strError = error;
      } finally {
        // call post processing call back if any
        if (undefined != i_fctPostProcess && ("function" == typeof i_fctPostProcess)) {
          i_fctPostProcess(strError);
        }
      }
      console.log(":33:::" + m_oCarouselDataMapMutex.m_bIsLocked);//TODO: prio 1 comment //prio 10: keep in debug log level very high
    }


    /**
     * @brief callback to similarly called event, to be called after each card view's main motion finishes
     * @details the call should assess the motion status and apply circular motion model via a call of
     *  __UpdateCyclicCarousselModel(direction) to update the cyclic  carousel model for all cards. 
     * during these calls no other components shall be allowed to suceesfully use acquireBlockingMutex()
     * until for the current card the postprocessing is done!
     * Once the motion is done with success the next card motion must be triggered via MoveNextcard() call
     */
    function onTransitionEnd(iCardPos, iCardRef) {//event
      // if (!event.target.classList.contains("Box-1321i-cardContainer")) {//TODO: use a check like this or not? prio 10
      //   return;
      // }
      // wait until last card for now
      console.log("CustomCarousel::onTransitionEnd: event " + " "
        + iCardRef + " " + iCardPos + " " + m_eCardMotionStates.get(iCardPos));
      let eResult = 1; //iCardPos == m_oMapTrackCardIndices.value.size;
      // check arguments before parsing
      if (iCardRef != m_oMapTrackCardIndices.value.get(iCardPos)) {
        eResult = -2;
      }
      else if ((enCardMotionStatus_t.eCardMotionStatus_Left == m_eCardMotionStates.get(iCardPos))
        || (enCardMotionStatus_t.eCardMotionStatus_Right == m_eCardMotionStates.get(iCardPos))) {

        // stop new last from listening to carousel track motions
        m_lstTrackCardRefs.value[iCardRef].removeEventListener(
          "transitionend", onTransitionEnd
        );
        m_lstTrackCardRefs.value[iCardRef].removeEventListener(
          "transitioncancel", onTransitionCancel
        );
        // increase motion step status and remove transition listener
        m_eCardMotionStates.set(iCardPos,
          (enCardMotionStatus_t.eCardMotionStatus_Left == m_eCardMotionStates.get(iCardPos))
            ? enCardMotionStatus_t.eCardMotionStatus_afterLeft : enCardMotionStatus_t.eCardMotionStatus_afterRight);
        console.log("CustomCarousel::onTransitionEnd: removed listeners!!! "
          + iCardRef + " " + iCardPos + " " + m_eCardMotionStates.get(iCardPos));
      } else {
        eResult = -1;
      }
      // check arguments before parsing
      if (1 == eResult) {
        console.log("CustomCarousel::m_oCarouselDataMapMutex" + m_oCarouselDataMapMutex.m_bIsLocked);
        console.log(iCardPos + "--" + m_eCardMotionStates.get(iCardPos));

        secureUpdateCyclicCarousselModel(() => { __UpdateCyclicCarousselModel(iCardPos, iCardRef); },
          (i_strError) => { postUpdateCyclicCarousselModel(iCardPos, iCardRef, i_strError); });
        // launch next transition if any
        MoveNextcard(iCardPos, iCardRef);
      }
      console.log("CustomCarousel::onTransitionEnd: result" + eResult + " "
        + iCardRef + " " + m_lstTrackCardRefs.value[iCardRef].id);

    }

    /**
     * 
     * @param {*} iCardPos 
     * @param {*} iCardRef 
     */
    function onTransitionCancel(iCardPos, iCardRef) {
      console.log(
        "CustomCarousel::onTransitionCancel: direction=" +
        m_eCardMotionStates.get(iCardPos)
        + " iCardPos " + iCardPos + " iCardRef " + iCardRef
      );
      //TODO: prio 3 can onTransitionCancel be called without having properly executed onTransitionEnd with success? and/or without onMounted be called again? what are those possible use cases and how invalid might the motion status of some cards get through that case??

      // m_eCardMotionStates.value[iCardPos] = 0; // TODO: any other side effects if transition is cancelled? why might this happen? prio 1
      //TODO: move track to position 0???? prio 1
    }
    
    /**
     * @brief get id of the card coming next after the given card, in the same 
     * motion direction as this predecessor.
     * @details the starting pos and the relative positions of all cards are summed up
     * before sorting therewith their global positions. then the current card is located
     * and the next one is choosen depending pon motion direction. If the predecessor
     * was just circularly shifted, his next is the current car at the other end were 
     * the predecessor was just right now before its onTransitionend event was processed
     * 
     * @param {*} i_uPredecessorPosIndex 
     */
    function getSuccessor(i_uPredecessorPosIndex, i_bMovedLeft) {
      let lstSortedGlobalPos = [];
      for (let iCardPos of m_lstTrackCardStartLefts.keys()) {
        lstSortedGlobalPos.push({
          pos: m_lstTrackCardStartLefts.get(iCardPos) + m_lstCardLefts.get(iCardPos),
          i: iCardPos
        });
      }
      // notes that sorting after indices must automatically also ALWAYS sort aftr positions (//TODO: prio 11 kind of module/integration test necessary for that ALWAYS word)
      lstSortedGlobalPos.sort((a, b) => { return a.pos < b.pos ? -1 : a.pos > b.pos ? 1 : 0; });
      console.log(lstSortedGlobalPos);
      
      // search for the predecessor in the sorted items list and fet´ch the sucessor
      let next = null;
      for (let i = 0; (i < lstSortedGlobalPos.length) && (null==next); ++i) {
        if (i_uPredecessorPosIndex == lstSortedGlobalPos.at(i).i) {
          if (i_bMovedLeft) {
            if (lstSortedGlobalPos.length > (i+1)) {
              next = lstSortedGlobalPos.at(i + 1).i;
            } else if (enCardMotionStatus_t.eCardMotionStatus_afterLeftRight
                        == m_eCardMotionStates.get(i_uPredecessorPosIndex)) {// his next is current last
              next = lstSortedGlobalPos.at(0).i;
            } else {
              next = undefined;
            }
          } else {
            if (0 <i) {
              next = lstSortedGlobalPos.at(i - 1).i;
            } else if (enCardMotionStatus_t.eCardMotionStatus_afterRightLeft
                        == m_eCardMotionStates.get(i_uPredecessorPosIndex))  {// his next is current first
              next = lstSortedGlobalPos.at(lstSortedGlobalPos.length-1).i;
            } else {
              next = undefined;
            }
          }
        }
      }
      if (!next) {
        console.log( "CustomCarousel::getSuccessor(): no next at "
        + i_uPredecessorPosIndex + " " + i_bMovedLeft + " " + next);//TODO: prio 3 comment this//TODO: prio 10 keep this in high debug level
      }
      return next;
    }

    /**
     * @brief move the card coming next after the given card, in the same 
     * motion direction as this predecessor.
     * @details the current state of the global card positions computed by adding 
     * the card starting pos to the sum of their translations so far is used to sort
     * the card within their current dynanamic position model and to find out which
     *  card succeeds this one next. 
     * NB: the next card can be the card a previously circularly shitfted card; for
     * example the card 10 might be moved from rear to front just before the card 1;
     * therewith the successor of card 1 will be card 10 even though card 10 has 
     * already been processed. It will have to be moved again just after card 1 to keep
     * it following just right behind card 1 with proper const spacing.
     * 
     * @param {*} i_uPredecessorPosIndex 
     */
    function MoveNextcard(i_uPredecessorPosIndex) {
      let i_eDirection = enCardMotionStatus_t.eCardMotionStatus_none ;
      if ( (enCardMotionStatus_t.eCardMotionStatus_afterLeft ==
        m_eCardMotionStates.get(i_uPredecessorPosIndex)) || 
        (enCardMotionStatus_t.eCardMotionStatus_afterLeftRight ==
        m_eCardMotionStates.get(i_uPredecessorPosIndex)) ) {
        i_eDirection = enCardMotionStatus_t.eCardMotionStatus_Left;
      } else if ((enCardMotionStatus_t.eCardMotionStatus_afterRight ==
        m_eCardMotionStates.get(i_uPredecessorPosIndex)) || 
        (enCardMotionStatus_t.eCardMotionStatus_afterRightLeft ==
        m_eCardMotionStates.get(i_uPredecessorPosIndex)) ) {
        i_eDirection = enCardMotionStatus_t.eCardMotionStatus_Right;
      }
      let eResult = 1; //TODO: replace with eReturning**Success prio 4
      // depending on the direction choos efirst or last card
      const uCardPosIndex = getSuccessor(i_uPredecessorPosIndex, 
        enCardMotionStatus_t.eCardMotionStatus_Left == i_eDirection) ;
      if (uCardPosIndex && enCardMotionStatus_t.eCardMotionStatus_none != i_eDirection) {
        const uCardRefIndex = m_oMapTrackCardIndices.value.get(uCardPosIndex);
        // do nothing if state is not right
        // NB: transitionend can change the value of a card's motion state, hence the next if is quite critical
        // otherwise we may have here an endless loop between moveTrack step and transitionend.
        if ( (enCardMotionStatus_t.eCardMotionStatus_none
          == m_eCardMotionStates.get(uCardPosIndex)) || 
          (enCardMotionStatus_t.eCardMotionStatus_afterLeftRight
          == m_eCardMotionStates.get(uCardPosIndex)) || 
          (enCardMotionStatus_t.eCardMotionStatus_afterRightLeft
          == m_eCardMotionStates.get(uCardPosIndex))){
            console.log("CustomCarousel::MoveNextcard: with eCardMotion "
            + m_eCardMotionStates.get(uCardPosIndex) + " for iCardPos "
            + uCardPosIndex + " while i_eDirection " + i_eDirection 
            + "moving after predecessor " + i_uPredecessorPosIndex);
          eResult = moveCard(uCardPosIndex, uCardRefIndex, i_eDirection);
        } else {
          console.log("CustomCarousel::MoveNextcard: CRITICAL BUG wrong eCardMotion "
            + m_eCardMotionStates.get(uCardPosIndex) + " for iCardPos "
            + uCardPosIndex + " while i_eDirection " + i_eDirection 
            + " after predecessor " + i_uPredecessorPosIndex + " with motion state "
            +  m_eCardMotionStates.get(i_uPredecessorPosIndex) );
          eResult = 0; //TODO: replace with proper error enumeration based handling prio 4
        }
      } else {
        eResult = -1;//TODO: replace with eReturning_ErrNextCardError prio 4(its value must NOT be -1 in the enumeration)
      }
      console.log("CustomCarousel::MoveNextcard: result" + eResult + 
      " pos " + uCardPosIndex + " direction " + i_eDirection);
      return eResult;
    }

    /**
     * @brief execute motion of a single given card if its motion state is default 
     * and the requested direction is ok!
     * @details
     * the motion will not occured if the motion state is already left or right
     * @param {*} i_uCardPosIndex 
     * @param {*} i_uCardRefIndex 
     * @param {*} i_eDirection 
     */
    function moveCard(i_uCardPosIndex, i_uCardRefIndex, i_eDirection) {
      let eResult = 1;
      // if (enCardMotionStatus_t.  == m_eCardMotionStates.get(i_uCardPosIndex)) 
      {

        console.log("CustomeCarousel::moveCard: will start moving pos "
          + i_uCardPosIndex + " ref " + i_uCardRefIndex + " pos " +
          m_lstTrackCardRefs.value[i_uCardRefIndex].getBoundingClientRect().left);
        console.log(m_lstCardLefts.get(i_uCardPosIndex));
        console.log(m_uMotionStep.value);

        // move in the Carousel track using a constant step 
        if (enCardMotionStatus_t.eCardMotionStatus_Left == i_eDirection) 
        {
          // update position model since system updates are sometimes slow
          m_lstCardLefts.set(i_uCardPosIndex,
            m_lstCardLefts.get(i_uCardPosIndex) - m_uMotionStep.value);
        } else if (enCardMotionStatus_t.eCardMotionStatus_Right == i_eDirection) {
          // update position model since system updates are sometimes slow
          m_lstCardLefts.set(i_uCardPosIndex,
            m_lstCardLefts.get(i_uCardPosIndex) + m_uMotionStep.value);
        } else {
          eResult = 0; //TODO: replace with proper error enumeration based handling prio 4
          console.log(
            "CustomCarousel::moveCard: will stop motion because of wrong direction " +
            i_eDirection
          );
        }
        console.log(m_lstCardLefts.get(i_uCardPosIndex));
        if (0 != eResult) {
          console.log(
            "CustomCarousel::moveCard: i_eDirection " +
            i_eDirection
          );
          m_eCardMotionStates.set(i_uCardPosIndex, i_eDirection);

          // setup post processing for each newly moved card
          m_lstTrackCardRefs.value[i_uCardRefIndex].addEventListener(
            "transitionend",
            onTransitionEnd(i_uCardPosIndex, i_uCardRefIndex),
            {
              once: true,
              passive: true,
            }
          );
          m_lstTrackCardRefs.value[i_uCardRefIndex].addEventListener(
            "transitioncancel",
            onTransitionCancel(i_uCardPosIndex, i_uCardRefIndex),
            {
              once: true,
              passive: true,
            }
          );

          let motionStep = m_lstCardLefts.get(i_uCardPosIndex);
          m_lstTrackCardRefs.value[i_uCardRefIndex].style.transition =
          "transform " + m_uDefaultTransitionSpeed.toString() + "s ease";
          m_lstTrackCardRefs.value[i_uCardRefIndex].style.transform = `translateX(${motionStep}px)`;

        }
      }
      return eResult;
    }

    /**
     * @brief launch and run track motion depending on the direction
     * @details actually we first move the very last card if moving to right
     * or the very first card if moving to left. Then each card triggers the motion 
     * of the next card in a chain until there is no remianing card to be moved.
     * This allows us to first deal with circular motion issues so that the last card 
     * (last in motion direction)can be circled to the other extremity of track 
     * and then moved again after the previously first card(first in motion direction) 
     * has been moved also.
     * Notes that, the motion step is calculated based on track width and the actual current number of
     * visible items during onMounted(). 
     */
    function moveTrack(i_eDirection) {
      
      let eResult = 1; //TODO: replace with eReturning**Success prio 4
      // find out the index of first card to be moved
      let { uLastorFirstCardPosIndex1  } = getFirstorLastCard(
        enCardMotionStatus_t.eCardMotionStatus_Left != i_eDirection
      );
      const uLastorFirstCardRefIndex = m_oMapTrackCardIndices.value.get(uLastorFirstCardPosIndex1);
      if (uLastorFirstCardPosIndex1) {
        // do nothing if state is not right
        // NB: transitionend can change the value of a card's motion state, hence the next if is quite critical
        // otherwise we may have here an endless loop between moveTrack step and transitionend.
        if (enCardMotionStatus_t.eCardMotionStatus_none
            == m_eCardMotionStates.get(uLastorFirstCardPosIndex1)) {
          eResult = moveCard(uLastorFirstCardPosIndex1, uLastorFirstCardRefIndex, i_eDirection);
        } else {
          console.log("CustomCarousel::moveTrack: CRITICAL BUG wrong eCardMotion "
            + m_eCardMotionStates.get(uLastorFirstCardPosIndex1) + " for iCardPos "
            + uLastorFirstCardPosIndex1 + " while i_eDirection " + i_eDirection);
          eResult = 0; //TODO: replace with proper error enumeration based handling prio 4
        }
      } else {
        eResult = -1;//TODO: replace with eReturning_ErrBadStatus prio 4(its value must NOT be -1 in the enumeration)
      }
      console.log("CustomCarousel::moveTrack: result" + eResult);
      return eResult;
    }

    /**
     * @brief process any request to move left or right by setting the motion up and engaging the process appropriately
     * @details
     *
     * */
    function processMoveRequest(i_eDirection) {
      let eResult = 1;//TODO: replace with eReturn**Success... prio 4
      if (m_uNMAXVisibles.value < m_lstTrackCardRefs.value.length) {//0 == m_eCardMotionStates.value &&
        // TODO: instead of bloquing new motions we need to implement queuing/waiting and speeding instead as the clicks increase prio 3
        new Promise((resolve, reject) => {
          {
            // set status and block new incoming transition procedures
            eResult = moveTrack(i_eDirection);
          }
          if (0 <= eResult) {
            resolve(
              "CustomCarousel::processMoveRequest: Moving OK: direction=" +
              i_eDirection + "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"
            );
          } else
            reject(
              "CustomCarousel::processMoveRequest: Moving NotOk" +
              i_eDirection + "!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!"
            );
        })
          .then(function (i_strResult) {
            // motion launch worked , event listener will do the reset
            if (null != i_strResult) console.log(i_strResult);
          })
          .catch(function (i_strErr) {
            // Promise failed
            if (null != i_strErr) console.log(i_strErr);
          });
      } else {
        eResult = 0;
        console.log("CustomCarousel::processMoveRequest:m_uNMAXVisibles", m_uNMAXVisibles);
        console.log(m_lstTrackCardRefs);
      }
      console.log("CustomCarousel::processMoveRequest: result" + eResult);
      return;
    }

    /**
     * @brief shift Carousel element to the left
     * @details
     *
     * */
    function onPrevClicked() {
      console.log(
        "CustomCarousel::onPrevClicked: *****************************************"
      );

      //clear intervallId for setTimeOut to stop endlessShift
      clearInterval(m_uEnlessShiftIntervallId);

      processMoveRequest(enCardMotionStatus_t.eCardMotionStatus_Left);
    }
    /**
     * @brief shift Carousel element to the right
     * @details
     * */
    function onNextClicked() {
      console.log(
        "CustomCarousel::onNextClicked: *****************************************"
      );

      //clear intervallId for setTimeOut to stop endlessShift
      clearInterval(m_uEnlessShiftIntervallId);

      processMoveRequest(enCardMotionStatus_t.eCardMotionStatus_Right);
    }



    /**
     * @brief event listeners for any card view's event signaling a change
     */
    const userChangeEventListener = SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent;
    /**
    * @brief call back used by the listener for any card view's event signaling a change
    * @details used by userChangeEventListener when it receives a signaled event
    * The method actually just forward the emission to parent
    */
    function onCustomAppUserEvent(i_oParams) {
      // Bubble event with arguments 
      emit(SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent, i_oParams);
    }

    /**
     * @brief when the caroussel left button is double clicked, start endless shift to left
     */
    function onLeftEndlessShift(){
      //clear last Intervall Execution 
      clearInterval(m_uEnlessShiftIntervallId);

      //start EndlessShift(e){
      //clear new Intervall Execution 
      m_uEnlessShiftIntervallId = setInterval(function(){
        processMoveRequest(enCardMotionStatus_t.eCardMotionStatus_Left);
      }, endlessShiftIntervall);
    }

    /**
     * @brief when the caroussel right button is double clicked, start endless shift to right
     */
     function onRightEndlessShift(){
      //clear last Intervall Execution 
      clearInterval(m_uEnlessShiftIntervallId);

      //start EndlessShift(e){
      //clear new Intervall Execution 
      m_uEnlessShiftIntervallId = setInterval(function(){
        processMoveRequest(enCardMotionStatus_t.eCardMotionStatus_Right);
      }, endlessShiftIntervall);
    }

    /**
     * @brief when the caroussel start grabbing , set mouse cursor to grabbing ,
     * and init m_oCarousel values
     */
    function onCarousselDragStart(e){

        m_oCarousel.value.m_nStartX = e.offsetX;
        m_pCarouselRef.value.style.cursor = "grabbing";

        console.log("start dragging ,  offsetX: " + m_oCarousel.value.m_nStartX);
    }

    /**
     * @brief when the caroussel end grabbing , set mouse cursor to grab ,
     * and init m_oCarousel values
     */
    function onCarousselDragEnd(e){
      const diff = e.offsetX - m_oCarousel.value.m_nStartX;
      m_pCarouselRef.value.style.cursor = "grab";

      console.log("start dragging ,  offsetX: " +e.offsetX);

      //stop endless shift caroussel 
      clearInterval(m_uEnlessShiftIntervallId);

      if(diff>0){
        processMoveRequest(enCardMotionStatus_t.eCardMotionStatus_Right);
      }
      else if(diff<0){
        processMoveRequest(enCardMotionStatus_t.eCardMotionStatus_Left);
      }

      
    }


    /**
     * @brief When the mouse of move down on the carousel,
     * set the mouse cursor to "grabbing"
     * @param {Event0} e -> DOM event
     */
    function onMouseDown(e) {
      m_oCarousel.value.m_bPressed = true;
      m_oCarousel.value.m_nStartX =
        e.offsetX - m_pInnerCarouselRef.value.offsetLeft;
      m_pCarouselRef.value.style.cursor = "grabbing";
    }

    /**
     * @brief Mouse was entered on the carousel container
     */
    function onMouseEnter() {
      m_pCarouselRef.value.style.cursor = "grab";
    }

    /**
     * @brief Mouse move up the carousel container
     */
    function onMouseUp() {
      m_pCarouselRef.value.style.cursor = "grab";
      m_oCarousel.value.m_bPressed = false;
    }

    /**
     * @brief When the mouse move on the carousel container left or right
     * @param {Event} e -> DOM event
     */
    function onMouseMove(e) {
      if (m_oCarousel.value.m_bPressed) {
        e.preventDefault();
        m_oCarousel.value.m_nX = e.offsetX;
        m_pInnerCarouselRef.value.style.left = `${m_oCarousel.value.m_nX - m_oCarousel.value.m_nStartX
          }px`;
        checkBoundary();//TODO: ????
      }
    }

    /**
     * @brief This method is to check that the movement on the carousel container
     * doesn't get out of it bounds
     */
    function checkBoundary() {
      let outer = m_pCarouselRef.value.getBoundingClientRect();
      let inner = m_pInnerCarouselRef.value.getBoundingClientRect();
      if (0 < parseInt(m_pInnerCarouselRef.value.style.left)) {
        m_pInnerCarouselRef.value.style.left = "0px";
      }
      if (inner.right < outer.right) {
        m_pInnerCarouselRef.value.style.left = `-${inner.width - outer.width
          }px`;
      }
    }

    // return whatever you wanted to use in template
    return {
      m_lstCardViews,
      m_uNMAXVisibles,
      m_uMotionStep,
      m_uCardSpacing,
      getCardId,
      m_uCARDELEVATION,
      m_pCarouselStyle,
      m_pTrackRef,
      m_pTrackStyle,
      m_pInnerCarouselRef,
      m_pInnerCarouselStyle,
      getCardDynamicStyle,
      m_pLeftBtn,
      m_pRightBtn,

      m_pCarouselRef,
      m_lstTrackCardRefs,
      m_strImageSourcePathPrev,
      m_strImageSourcePathNext,
      m_oMapTrackCardIndices,
      moveTrack,
      onPrevClicked,
      onNextClicked,

      userChangeEventListener,
      onCustomAppUserEvent,
      onLeftEndlessShift,
      onRightEndlessShift,
      onCarousselDragStart,
      onCarousselDragEnd,
      onMouseDown,
      onMouseEnter,
      onMouseUp,
      onMouseMove,
    };
  },
};
</script>

<!-- id selector -> class selector -> element selector -->
<style lang="scss" scoped>
@import "@/styles/variables.scss"; // $text-primary would be defined in that file

.carousel-outer-container-13-mdAndUp {
  width: 100% !important;
  height: 100%;

  // border-style: dashed;
  // border-color: chartreuse;
  display: flex;
  flex-direction: row;

  position: relative;
  overflow: hidden;
}

.carousel-outer-container-13-smAndDown {
  width: 100% !important;
  height: 100%;

  // border-style: dashed;
  // border-color: chartreuse;
  display: flex;
  flex-direction: row;
  align-content: center;
  position: relative;
  overflow: hidden;
  // flex-wrap: nowrap;
  // justify-content: center;
  // justify-items: flex-start;
  // align-content: flex-start;
  // align-items: flex-start;
}

.Box-131-mdAndUp {
  order: 1;
  min-height: 0%;
  min-width: 0%;
  width: 4%;
  // height: fit-content;
  margin-top: 0%;
  // border-style: dashed;
  // border-color: darkorchid;
  color: transparent;
  background-color: transparent;
  position: relative;
  transform: translate(50%, 25%);
}

.Box-131-smAndDown {
  order: 1;
  min-height: 0%;
  min-width: 0%;
  width: 8%;
  // height: fit-content;
  margin-top: 0%;
  // border-style: dashed;
  // border-color: darkorchid;
  position: relative;
  transform: translate(50%, 30%);
  // transform: translateY( 100px);//TODO: determine this dynamically based on card height prio 1
}

#prevImg {
  width: 100%;
}

.Box-133-mdAndUp {
  order: 3;
  min-height: 0%;
  min-width: 0%;
  width: 4%;
  // height: fit-content;
  margin-top: 0%;
  margin-right: 0%;
  padding-right: 0%;
  // border-style: dashed;
  // border-color: darkorchid;
  position: relative;
  transform: translate(-50%, 25%);
}

.Box-133-smAndDown {
  order: 3;
  min-height: 0%;
  min-width: 0%;
  width: 8%;
  // height: fit-content;
  // margin-top: 4%;
  // border-style: dashed;
  // border-color: darkorchid;
  position: relative;
  transform: translate(-50%, 30%);
}

#nextImg {
  width: 100%;
}
</style>
