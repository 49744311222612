  /**
   * @brief: enumeration of the possible views in the application
   * @details: this is not really an enumeratio as it is not yet 
   * supported by js, but it may be improved in the future
   */
const enViewItemType_t = Object.freeze({
    eViewItemType_Unknown : 0,
    eViewItemType_LandingpageMenuItem : 1,
    eViewItemType_ShopingCart : 2,
    eViewItemType_OrdersPageMenuItem : 3,
    eViewItemType_RestaurantPageMenuItem : 4,
    eViewItemType_Invalid : 255
  });

  export default enViewItemType_t;
  