

/**
 * @brief enumeration for types usefull to convert variable in a specific type 
 */
const enVariableConversionTypes_t = Object.freeze({
    eVariableType_UnknownType: 0,
    eVariableType_Boolean: 1,
    eVariableType_String: 3,
    eVariableType_Int: 4,
    eVariableType_Float: 5,
    eVariableType_Date: 6,
    eVariableType_DateTime: 7,
    eVariableType_TimeStamp: 8,
    eVariableType_Time: 9,
    eVariableType_Set: 10,
    eVariableType_Array: 11,
    eVariableType_Map: 12,
    eVariableType_Enum: 13,

  });
  
  export default enVariableConversionTypes_t;
  