const enStaffTypes_t = Object.freeze({
  eStaffType_Unknown: 0,
  eStaffType_SimpleStaff: 1,
  eStaffType_Manager: 2,
  eStaffType_Admin: 3,
  eStaffType_Developer: 4,
  eStaffType_Courier: 5,
  eStaffType_Sales: 6,
  eStaffType_AfterSales: 7,
  eStaffType_Invalid: 255,
});

export default enStaffTypes_t;
