/** * @brief implementation of the home screen front end design *  @details
embedded are here in a modular way, the key components * of the home screen,
dynamically mounted and rendered based on available data. */
<template>
  <!-- home screen main banner component on home screen-->
  <landingPageBanner :m_bIsMobile=m_bIsMobile :m_bDisplayAttributes=m_bDisplayAttributes
                      @[userChangeEventListener]="onCustomAppUserEvent"/>
  <!-- main content container to organize the dynamic components on home screen -->
  <span class="container-1" fluid>
    <span class="Box-1-smAndDown" v-if="m_bIsMobile">

      <body>
        Commandez des plats savoureux en quelques clics!
      </body>
    </span>
    <span class="Box-1-mdAndUp" v-else>

      <body>
        Découvrez des plats savoureux et faites vous livrer un repas copieux en
        quelques clics
      </body>
    </span>

    <!--        Rendering of each category of menu item available    
      IT IS IMPORTANT TO REMEMBER THAT ICATGEORY CAN BE INCREASED AND MUST NOT BE USED
      CARELESSLY TO RETRIEVE ACTALY CATEGORY INFOS   -->
    <span class="Box-2" >
      <span :class="{ 'Box-2i-smAndDown': m_bIsMobile, 'Box-2i-mdAndUp': !m_bIsMobile }"
        v-for="[iCategory, iMenuItemsCategory] in m_lstMenuItemsCategories" :key="iCategory" >
        <HomeScreenCategoryCarousel v-if="0<iMenuItemsCategory.m_lstCategoryItems.size"
        :m_oCategoryCarouselData="iMenuItemsCategory" 
        :m_bIsMobile="m_bIsMobile" :m_bDisplayAttributes=m_bDisplayAttributes
          :m_CardView=1 @[userChangeEventListener]="onCustomAppUserEvent" />
      </span>
    </span>
  </span>
</template>

<script>
import { ref,  onBeforeMount } from "vue";
import { useStore } from 'vuex'


import SoftDevConfigs from "../../configurations/Configs.js";
import { toString as menuCategorytoString, getMenuCategorySubTitle }
  from "@/../Common/commontypes/enums/enMenuCategory_t";
import { enViewItemType_t } from "@/../Common/commontypes/enums/index";
import jHttpHandler from "../../Model/ServerClient/Http/jHttpHandler";
import isPromise from "../../Common/TaskUtils/AsyncTaskUtils";
import { convertCSVtoMapRef } from "../../Common/SystemHandling/csvFileHandling";
// import {mapMenuItemsCategories} from "../../Common/tempTestData/CarousselsSimData";
import landingPageBanner from "../../view/Home/landingPageBanner.vue";
import HomeScreenCategoryCarousel from "./HomeScreenCategoryCarousel.vue";
import jHomeScreenCategoryItemData from "@/../viewmodel/HomeScreenVM/jHomeScreenCategoryItemData";
import jHomeScreencategoryCarouselData from "@/../viewmodel/HomeScreenVM/jHomeScreencategoryCarouselData";
import {enHttpResponseStatusCode_t} from "@/../Common/commontypes/enums/index";


export default {
  name: "HomeScreen",
  components: {
    landingPageBanner,
    HomeScreenCategoryCarousel,

  },

  /**
   * @brief property for the component to know weither the device is a mobile
   */
  props: {
    m_bIsMobile: {
      type: Boolean,
      required: true,
    },
    /**
      * @brief property for the component to know attributes necessary for responsiveness
      */
      m_bDisplayAttributes: {
      required: true,
    },
  },


  setup() {

    /**initialize set of menu item categroes with category database */
    const m_lstMenuItemsCategories = ref(new Map());

    const m_lstRestaurants = ref(new Map());
    const m_lstRawMenuItems = ref(new Map());//TODO: prio 10 can this be used only temporarely locally? technically this is quite redundant data either it is used to be store in app storage shared, or it must be removed; this may require not using an external central convertion from csv anymore(whihc will disappear at some point anyway since we are not supposed to know it is csv))


    // launch initialization of key app data
    setupScreenData();


    /**
     * @brief parse raw menu item into proper caroussel data depending on its category
     * @details this method does the data representation conversion from Model to ViewModel
     * 
     * NB: CURRENTLY IF AN ITEM IS ALREADY WITHIN A CAROUSEL IT WILL JUST BE OVERWRITTEN(DATA COPY).
     * FOR NOW WE KEEP THIS FOR ITS DEFENSIVE NATURE; NOT ENOUGH GOOD REASONS TO DO IT DIFFERENTLY
     */
    function ItemtoCaroussel(i_oRawMenuItem, i_uItemId) {
      
      let oHomeScreenCategoryItemData = [];
      //  console.log(i_oRawMenuItem.m_eProductCategory);
      if (m_lstMenuItemsCategories.value.has(i_oRawMenuItem.m_eProductCategory)) {
        // create new home screen view model"s menu item
        oHomeScreenCategoryItemData = jHomeScreenCategoryItemData(
          i_uItemId,
          enViewItemType_t.eViewItemType_LandingpageMenuItem,
          i_oRawMenuItem.m_strMenuItemName,
          m_lstMenuItemsCategories.value.get(i_oRawMenuItem.m_eProductCategory).m_lstCategoryItems.size + 1,
          i_oRawMenuItem.m_eProductCategory,
          i_oRawMenuItem.m_strMenuItemImage,
          m_lstRestaurants.value.get(i_oRawMenuItem.m_uRestaurantId).m_strRestaurantLocation,
          i_oRawMenuItem.m_uUnitPrice,
          0,
          0,
          i_oRawMenuItem.m_lstDays,
        );
        // todo log this only in debug mode
        console.log("HomeScreen::ItemtoCaroussel: found Category " +
          menuCategorytoString(i_oRawMenuItem.m_eProductCategory) + " for " + i_oRawMenuItem.m_strMenuItemName);
        console.log(m_lstMenuItemsCategories.value.get(i_oRawMenuItem.m_eProductCategory)
          .m_lstCategoryItems);//TODO: remove this prio 1
        // push new item into home screen view data model; FYI: IT SEEMS WHEN REUSING A REFERENCE IT BECOMES A PROXYS
        m_lstMenuItemsCategories.value.get(i_oRawMenuItem.m_eProductCategory)
          .m_lstCategoryItems.set(i_uItemId, oHomeScreenCategoryItemData);       
      }
      else {// if the category doesnt exists yet in the list of carousels to be displayed, add it!
        oHomeScreenCategoryItemData = jHomeScreenCategoryItemData(
          i_uItemId,
          enViewItemType_t.eViewItemType_LandingpageMenuItem,
          i_oRawMenuItem.m_strMenuItemName,
          1,// the category is yet empty
          i_oRawMenuItem.m_eProductCategory,
          i_oRawMenuItem.m_strMenuItemImage,
          m_lstRestaurants.value.get(i_oRawMenuItem.m_uRestaurantId).m_strRestaurantLocation,
          i_oRawMenuItem.m_uUnitPrice,
          0,
          0,
          i_oRawMenuItem.m_lstDays
        );
        // cerate and add a new category object iinto the carousels categories map
        m_lstMenuItemsCategories.value.set(i_oRawMenuItem.m_eProductCategory,
          jHomeScreencategoryCarouselData(i_oRawMenuItem.m_eProductCategory,
            menuCategorytoString(i_oRawMenuItem.m_eProductCategory),
            getMenuCategorySubTitle(i_oRawMenuItem.m_eProductCategory),
            oHomeScreenCategoryItemData));

        console.log("HomeScreen::ItemtoCaroussel: Adding new; Category " +
          menuCategorytoString(i_oRawMenuItem.m_eProductCategory) + " for " + i_oRawMenuItem.m_strMenuItemName + " is null!");
      }

      //  console.log(m_lstMenuItemsCategories.value.get(i_oRawMenuItem.m_eProductCategory).m_lstCategoryItems);
      return false; //TODO: improve error handling. prio 10
    }

    
    
    /**
     * @brief apply filter on selected raw menu item to find out if it is an item matching
     * user's filter search pattern(s)
     * @details the method use string.match to apply diffeent filter objectives depending
     * on the use case
     * - an attribute i_oSearchedItem.m_eProductCategory will be used to match categories,
     *   if there is a provided regex in i_sFilter.m_strSearchRegexPattern
     * - an attribute i_oSearchedItem.m_strMenuItemName will be used to match item name,
     *   if there is a provided regex in i_sFilter.m_strSearchRegexPattern
     * if multiple of these attributes are available, aftr the first positive mathc the
     * string.match will not be used on the remaining attribute's target string to search
     */
     function checkIfMatchesFilter( i_oSearchedItem, i_sFilter) {
        let bMatch = false;
        let str2Search = null;
        // check if at least one of exüpected search objectives is there
        if (i_oSearchedItem.m_eProductCategory || i_oSearchedItem.m_strMenuItemName) {
          // setup proper search target depending on use case and appyply it
          if (i_oSearchedItem.m_eProductCategory) {
            str2Search =  menuCategorytoString(i_oSearchedItem.m_eProductCategory) 
            + " " + getMenuCategorySubTitle(i_oSearchedItem.m_eProductCategory);
            bMatch = null != str2Search.match(i_sFilter.m_strSearchRegexPattern);
          }
          if (!bMatch && i_oSearchedItem.m_strMenuItemName) {//only if not matched yet
            str2Search =  i_oSearchedItem.m_strMenuItemName;
            bMatch = null != str2Search.match(i_sFilter.m_strSearchRegexPattern);
          }  
        
        } else  {//TODO: prio 3 comment this out //TODO: prio 10 keep log ponly in high level debug? 
          console.log("HomeScreen::checkIfMatchesFilter: cannot apply filter!");
          console.log(i_oSearchedItem)
          console.log(i_sFilter);
        }
        // if any search target was computed apply filter on it
        // if (bMatch) 
        // {//TODO: prio 10 improve and keep log ponly in high level debug?       
        //   console.log(i_sFilter);
        //   console.log(i_oSearchedItem);//m_lstMenuItemsCategories.value.get(eCategory);  
        // }
        return bMatch;
      }

    /**
     * @brief parse user received menu items and restaurants data to initialize the caroussels data
     * @details
     * if a filter has been provided filter after the specified attributes
     * the map m_lstMenuItemsCategories will alwaqys be cleared each time to force complete dom reset
     * and thereby before-mount and mount lifecycle hook calls
     */
    function updateCarousselsData(i_sFilter) {
      m_lstMenuItemsCategories.value.clear();
      if ( m_lstRawMenuItems.value.size) {
        m_lstRawMenuItems.value.forEach(
          /**
           * @brief apply filter if any provided before inserting raw menu item into a caroussel
           * @details 
           * @param {*} i_oRawValue 
           * @param {*} i_uItemId 
           */
          function parseRawMenuItemtocaroussel(i_oRawMenuItem, i_uItemId) {
            // apply filter if any 
            let bDoIt = i_sFilter ? checkIfMatchesFilter( i_oRawMenuItem, i_sFilter) : true;            
            if (bDoIt) {
              ItemtoCaroussel(i_oRawMenuItem, i_uItemId);
            } else {
              console.log("HomeScreen::parseRawMenuItemtocaroussel: info: will not be displayed "
              + i_uItemId + " " + i_oRawMenuItem.m_strMenuItemName)
            }
          });
        console.log(m_lstRawMenuItems)
      }
      else {
        console.log("HomeScreen::updateCarousselsData: this is critical: ");
        console.log(m_lstMenuItemsCategories.value);
        console.log(m_lstRawMenuItems.value);
      }
    }

    /**
     * @brief implementation of key initialization steps which must be launched
     * as early as possible.
     *  @details when menu items data are asynchronously received, 
     * we can initialize the caroussel data with a proper callback from View Model
     * - fetch menu items data 
     * - parse server response if any (//TODO: what about timeout? prio 10 )
     * - convert csv database data into js objects
     * - initialize caroussel data
     */
    function setupScreenData() {
      let bRes = true;//TODO: improve with proper enumeration prio 1
      {
        jHttpHandler.requestRestaurants()
          .then(async function (response) {
            let oResultData = {
              m_eResponseStatus: response.status,
            };
            if (enHttpResponseStatusCode_t.eStatusCode_Ok === response.status) {//TODO: improve hard coded const prio 1
              console.log("HomeScreen::setupScreenData: jHttpHandler.requestRestaurants: succeeded " +
                " Response Status:=" + response.status + " Response:=");
              // console.log(response);//TODO: deactivate(this log) if not debug modus?? prio 6   
              let data = await response.json();
              oResultData.m_strResponseMsg = data.m_strMessage;
              oResultData.m_lstRestaurants = data.m_lstData;
            } else {//TODO: replavce 2000 with constant; prio 4 //TODO: log only in debg modus to file?? prio 1
              console.log("HomeScreen::setupScreenData: jHttpHandler.requestRestaurants: failed " +
                " because response NotOk! Status:=" + response.status + " Response:=");
              console.log(response);//TODO: deactivate(this log) if not debug modus?? prio 6
              bRes = false; //TODO: not active yet prio 1
              oResultData.m_strResponseMsg = await response.text();
            }
            return oResultData;
          })
          .then((i_oResponse) => {// this is the method that is called when the data are ready in this view model
            if ((undefined != i_oResponse) && (undefined != i_oResponse.m_eResponseStatus)
              && (enHttpResponseStatusCode_t.eStatusCode_Ok === i_oResponse.m_eResponseStatus))// todo. improve hard coded constants
            {
              // console.log(i_oResponse.m_strResponseMsg);//TODO: remove this? prio 4
              if (convertCSVtoMapRef(SoftDevConfigs()
                .CST_MODEL_DATABASE_TABLES_PRIMARY_KEYS_NUMBER.m_nRestaurant,
                i_oResponse.m_lstRestaurants, m_lstRestaurants)) {
                console.log("HomeScreen::setupScreenData:Conversion into m_lstRestaurants worked!");
              } else {
                console.log("HomeScreen::setupScreenData:Conversion into m_lstRestaurants failed!");
                bRes = false; //TODO: not active yet prio 10
              }
            } else {//TODO: replavce 2000 with constant; prio 4  //TODO: log only in debg modus to file?? prio 10
              console.log(i_oResponse);//TODO: only log in debug modus. prio 6
              bRes = false; //TODO: not active yet prio 10
            }
            if (bRes && isPromise(i_oResponse.m_strResponseData)) {
              return i_oResponse.m_strResponseData;
            }
          })
          .catch(function (e) {
            console.log(e); // TODO: move this to an error handler prio 7
          });
      }
      if (bRes) {
        jHttpHandler.requestMenuItems()
          .then(async function (response) {
            let oResultData = {
              m_eResponseStatus: response.status,
            };
            if (enHttpResponseStatusCode_t.eStatusCode_Ok === response.status) {//TODO: improve hard coded const prio 4
              console.log("HomeScreen::setupScreenData: jHttpHandler.requestMenuItems: succeeded " +
                " Response Status:=" + response.status + " Response:=");
              // console.log(response);//TODO: deactivate(this log) if not debug modus??    prio 7   
            
            let data = await response.json();
              oResultData.m_strResponseMsg = data.m_strMessage;
              oResultData.m_lstRawMenuItems = data.m_lstData;
            } else {//TODO: replavce 2000 with constant; prio 4 //TODO: log only in debg modus to file?? prio 7
              console.log("HomeScreen::setupScreenData: jHttpHandler.requestMenuItems: failed " +
                " because response NotOk! Status:=" + response.status + " Response:=");
              console.log(response);//TODO: deactivate(this log) if not debug modus?? prio 7
              bRes = false; //TODO: not active yet prio 7
              oResultData.m_strResponseMsg = await response.text();
            }
            return oResultData;
          })
          .then((i_oResponse) => {// this is the method that is called when the data are ready in this view model
            if ((undefined != i_oResponse) && (undefined != i_oResponse.m_eResponseStatus)
              && (enHttpResponseStatusCode_t.eStatusCode_Ok === i_oResponse.m_eResponseStatus))// todo. improve hard coded constants
            {
              // console.log(i_oResponse.m_lstRawMenuItems);//TODO: remove this? prio 4
              if (convertCSVtoMapRef(SoftDevConfigs()
                .CST_MODEL_DATABASE_TABLES_PRIMARY_KEYS_NUMBER.m_nMenuItem,
                i_oResponse.m_lstRawMenuItems, m_lstRawMenuItems)) {
                console.log("HomeScreen::setupScreenData:Conversion into m_lstRawMenuItems worked! size"
                  + m_lstRawMenuItems.value.size);
                  console.log("!!!!!!!!!!!!!")
                  console.log(m_lstRawMenuItems)
                /** ! this is where we actually fill the caroussels !*************** */
                updateCarousselsData();//TODO: return a value from that init method and use it here prio 5
              }
              else{
                console.log("HomeScreen::setupScreenData:Error or data Empty? No caroussel will be dispayed");
                //TODO: what should be displayed in this case??? prio 10
                bRes = false; //TODO: not active yet prio 7
              }
            } else {//TODO: replavce 2000 with constant; prio 4 //TODO: log only in debg modus to file?? prio 10
              console.log(i_oResponse);//TODO: only log in debug modus. prio 6
              bRes = false; //TODO: not active yet prio 7
            }
            if (bRes && isPromise(i_oResponse.m_strResponseData)) {
              return i_oResponse.m_strResponseData;
            }
          })
          .catch(function (e) {
            console.log(e); // TODO: move this to an error handler prio 5
            bRes = false; //TODO: not active yet prio 7
          });
      }
      return bRes;
    }

    
    /**
     * @brief list of the last set of categories which where affected by the search filter applicated
     */
     let lstKey2Update = new Set();
     /**
      * @brief step by which keys are incremented to force dom update
      */  
     const m_uStepChangeKeys = 1000;
    /**
     * @brief parse user received menu items and restaurants data to initialize the caroussels data
     * @details
     * if a filter has been provided filter after the specified attributes
     */
     function applyFilter(i_sFilter) {
      let bRes = false;
      // m_lstMenuItemsCategories.value.set(i_oRawMenuItem.m_eProductCategory,
      //     jHomeScreencategoryCarouselData
      if (i_sFilter && m_lstMenuItemsCategories.value.size) {
        
        m_lstMenuItemsCategories.value.forEach(          
          /**
           * @brief apply filter if any provided and decide weither a category carousel
           *  or certain menu items within each carousel should be further displayed
           * @details each filter objective is applied and any element not matching
           * that objective is removed from the map.
           * - first the category will be provided to  checkIfMatchesFilter
           * - then each menu item within the category are passed to checkIfMatchesFilter
           * 
           * @param {*} i_oCategory 
           * @param {*} i_eProductCategory 
           */
          function applyFilteronCategoryCarouselData(i_oCategory, i_eProductCategory) {
            // apply filter if any 
            let oSearchedItem = {m_eProductCategory: i_eProductCategory};
            let bDoIt = i_sFilter ? checkIfMatchesFilter( oSearchedItem, i_sFilter) : true; 
                     
            if (bDoIt) {
              console.log("HomeScreen::applyFilteronCategoryCarouselData: info: will not change "
              + i_oCategory + " using filter " + i_sFilter);//TODO: comment this out prio 3//TODO: prio 10 keep this in high level debug only
            } else {// next filter category content 
              m_lstMenuItemsCategories.value.get(i_eProductCategory)
                         .m_lstCategoryItems.forEach(  
              /**
               * @brief apply filter if any provided and decide weither a category carousel
               *  or certain menu items within each carousel should be further displayed
               * @details each filter objective is applied and any element not matching
               * that objective is removed from the map.
               * - first the category will be provided to  checkIfMatchesFilter
               * - then each menu item within the category are passed to checkIfMatchesFilter
               * 
               * @param {*} i_oItemData 
               * @param {*} i_uItemId 
               */
              function applyFilteronMenuItem(i_oItemData, i_uItemId, i_oMap) {
                let oSearchedItem = {m_strMenuItemName: i_oItemData.m_strMenuItemName};
                let bDoIt = i_sFilter ? checkIfMatchesFilter( oSearchedItem, i_sFilter) : true;            
                if (!bDoIt) {
                  lstKey2Update.add(i_eProductCategory);
                  if (i_oMap.delete(i_uItemId)) {
                    console.log("HomeScreen::applyFilteronCategoryCarouselData: info: will not display "
                      + i_oItemData.m_strMenuItemName + " using filter " + i_sFilter);//TODO: comment this out prio 3//TODO: prio 10 keep this in high level debug only
                  }
                }
              });             
            }
          });
          console.log("applyFilter*************************************")
         
          // update the keys to force dom rerendering
          lstKey2Update.forEach( (i_eProductCategory) => {
            m_lstMenuItemsCategories.value.set(
              i_eProductCategory+m_uStepChangeKeys, 
              m_lstMenuItemsCategories.value.get(i_eProductCategory)
            );
            console.log(m_lstMenuItemsCategories)
            m_lstMenuItemsCategories.value.delete(i_eProductCategory);
          });
          
      }
      else {//TODO: comment this out prio 3//TODO: prio 10 keep this in high level debug only
        console.log("HomeScreen::applyFilter: NOTHING TO DO ");
        console.log(i_sFilter);
        console.log(m_lstMenuItemsCategories);
      }
      return bRes;
    }

    /**
     * @brief search possible matches for the current search string and apply filter
     * @details
     * - the search text can be a category name, a menu item name, a price range:
     *  A- to filter after categories we need to match the text against the name of 
     *     all possible categories(title and subitle). If there is a match, we check if
     *     that category has items and if it does we must SWITCH OFF ALL OTHER CATEGORIES, 
     *  B- to filter after menu item name, we must have a list of all menu items, search
     *     all matches for that string and/or pattern, UPDATE THE LIST OF ITEMS OF CATEGORIES
     *     to contain only the matches. the dom will then rerender the caroussels
     *  C- to filter after price range we must go through a list of all menu items, fetch
     *     their prices, find the ones in range, UPDATE THE LIST OF ITEMS OF CATEGORIES
     * NB:
     * - the searchfield (nested) component will have to emit event to the homescreen
     * super parent component so that 
     * if i_strSearchFilter is a string surrounded with "" each " will be replaced with \"
     * withhin the search pattern; the search string must be trimmed first!
     * We can eiter use applyFilter at the very first usage in home screen, or 
     * updateCarousselsData when follow up searches are done (//TODO: prrio 3 in search result page) 
     * lstKey2Update can be used to detec for now if a search was done but later we shall 
     * route to a proper 
     * result page that will not have lstKey2Update but will no that for sure a srearch ha salready 
     * been donw and will only therefore use updateCarousselsData
     */
     function doSearchandFilter(i_strSearchFilter) {
    
      let sFilter = {};
      let bRes = undefined != i_strSearchFilter && null != i_strSearchFilter && 0 < i_strSearchFilter.length;
      if (bRes) {//TODO: prio 4 improve hard coded constant
        // define dynamic regular expression object
        let strSearchFilter = i_strSearchFilter.trim();
        let bIsWord = strSearchFilter.startsWith("\"") && strSearchFilter.endsWith("\"") ;
        bIsWord ||=  strSearchFilter.startsWith("'") && strSearchFilter.endsWith("'");
        let regex = null;
        
        if (bIsWord) {// in this case a word must be excatly matched
          //TODO: prio 4 improve hard coded constant
          regex = new RegExp(strSearchFilter.replaceAll("\"", ''),"i");
        } else {
          regex = new RegExp(".*" + strSearchFilter + ".*","i");
        }  
        // set 
        sFilter.m_strSearchRegexPattern = regex;
        if ( sFilter.m_strSearchRegexPattern ) {// some categories where found that match
          if (!lstKey2Update.size)
            applyFilter(sFilter);
          else
            updateCarousselsData(sFilter);
        } else {// nocategory found that matches
          console.log("HomeScreen::doSearchandFilter failed to prepare filter "
          + " attribute to apply " + i_strSearchFilter);
          console.log(strSearchFilter) 
          console.log(regex) 
        }
      }
      return bRes ? sFilter : null;
    }

    /**
     * @brief inject shared store dependency
     */
    const m_oStore = useStore();
    /**
     * @brief event listeners for any card view's event signaling a change
     */
    const userChangeEventListener = SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent;
    /**
     * @brief call back used by the listener forany child request
     * @details this callback is used by userChangeEventListener when it receives a signaled event
     * - called when user change a number of menu item units.
     * - called when user press on button to add item to shopping cart
     * - called when the user has issued a custom search string to filter the displayed items
     */
    function onCustomAppUserEvent(i_oParams) {

      // if we are receiving an updated number of unit from a certain menu item's card view
      if (SoftDevConfigs().CST_EVENT_NAMES.m_strNbrofUnitEvent === i_oParams.m_strUsecase) {
        if (m_lstMenuItemsCategories.value.has(i_oParams.m_eProductCategory)) {
          const oCategory = m_lstMenuItemsCategories.value.get(i_oParams.m_eProductCategory);
          if (oCategory.m_lstCategoryItems.has(i_oParams.m_strMenuItemId)) {
            oCategory.m_lstCategoryItems.get(i_oParams.m_strMenuItemId)
              .m_uNbrofUnits = i_oParams.m_uNbrofUnits;
          } else {
            console.log("HomeScreen::onCustomAppUserEvent: found category " + i_oParams.m_eProductCategory
              + " but not MenuItem key " + i_oParams.m_strMenuItemId);
          }
        } else {
          console.log("HomeScreen::onCustomAppUserEvent: could not find category "
            + i_oParams.m_eProductCategory + " for MenuItem key " + i_oParams.m_strMenuItemId);
        }
      }
      // if we are receiving an order item from a certain menu item's card view
      else if (SoftDevConfigs().CST_EVENT_NAMES.m_strAddtoBasket === i_oParams.m_strUsecase) {
        if (m_lstMenuItemsCategories.value.has(i_oParams.m_eProductCategory)) {
          const oCategory = m_lstMenuItemsCategories.value.get(i_oParams.m_eProductCategory);
          if (oCategory.m_lstCategoryItems.has(i_oParams.m_strMenuItemId)) {
            const oItem = oCategory.m_lstCategoryItems.get(i_oParams.m_strMenuItemId);
            if (oItem.m_uNbrofUnits)
              m_oStore.commit('ShoppingCart/addOrderItem', oItem);
            else {
              console.log("HomeScreen::onCustomAppUserEvent: will do nothing because " + oItem.m_strMenuItemName
                + " has N:=" + oItem.m_uNbrofUnits);//TODO: activate this ony in debug mode prio 7
            }
          } else {
            console.log("HomeScreen::onCustomAppUserEvent: found category " + i_oParams.m_eProductCategory
              + " but not MenuItem key " + i_oParams.m_strMenuItemId);//TODO: activate this ony in debug mode prio 7
          }
        } else {//TODO: activate this ony in debug mode prio 7
          console.log("HomeScreen::onCustomAppUserEvent: could not find category "
            + i_oParams.m_eProductCategory + " for MenuItem key " + i_oParams.m_strMenuItemId);
        }
      }
      // if receiving an event from searchfield
      else if (SoftDevConfigs().CST_EVENT_NAMES.m_strSearchandFilterRequest === i_oParams.m_strUsecase) {
        if ( undefined != i_oParams.m_strFilter )  {
          doSearchandFilter(i_oParams.m_strFilter);
        } else {
          console.log("HomeScreen::onCustomAppUserEvent: Error: invalid Filter!");//TODO: proper error/exception enum based handling! prio 6
          console.log(i_oParams);
        }
      } 
      else {
        console.log("HomeScreen::onCustomAppUserEvent: Error: wrong param! Urgent debugging!");//TODO: proper error/exception enum based handling! prio 6
        console.log(i_oParams);
      }
    }

    /**
     * @brief 
     */
    onBeforeMount(() => {//(to, from) =>

    });




    // return whatever you wanted to use in templat;
    return { m_lstMenuItemsCategories, userChangeEventListener, onCustomAppUserEvent };
  },
};
</script>

<!-- id selector -> class selector -> element selector -->
<style lang="scss" scoped>
@import "@/styles/variables.scss"; // $text-primary would be defined in that file

.container-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  justify-items: flex-start;
  justify-content: flex-start;
  margin-left: 0% !important;
  width: 100%;
  // overflow: hidden;
  // flex-grow: 0;
  // flex-shrink: 0;
  // background-size: cover;
  background-repeat: no-repeat;
  // background-size: 100% 100%;
  border-style: groove;
  border-color: black;
}

.Box-1-mdAndUp {
  // flex: 1;
  order: 1;
  flex-grow: 0;
  flex-shrink: 0;
  width: 90%;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 0%;
  margin-bottom: 5%;
  font-family: "Rockwell", sans-serif;
  font-size: xx-large;
  // border-style: groove;
  // border-color: black;
}

.Box-1-smAndDown {
  // flex: 1;
  order: 1;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  margin-left: 3% !important;
  margin-right: 0%;
  margin-top: 0%;
  margin-bottom: 2%;
  font-family: "Rockwell", sans-serif;
  font-size: small;
}
.Box-2{
  order: 2;
  display: flex;
  flex-direction: column;
}
.Box-2i-mdAndUp {
  // flex: 1;
  // order: 2;
  flex-grow: 0;
  flex-shrink: 0;
  font-family: "Rockwell", sans-serif;
  font-size: x-large;
  margin-bottom: 1%;
  margin-left: 0% !important;
  width: 100%;
  height: fit-content;
}

.Box-2i-smAndDown {
  // flex: 1;
  order: 2;
  flex-grow: 0;
  flex-shrink: 0;
  font-family: "Rockwell", sans-serif;
  font-size: small;

  margin-bottom: 1%;
  margin-left: 0% !important;
  width: 100%;
  height: fit-content;
  // border-style: solid;
  // border-color: darkgoldenrod;
}
</style>
