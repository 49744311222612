<template>
  <!-- the top bar is made of a row containing on the left side the loog and the right, the basket symbol with
    a clickable label. The design must changed based on size of screen AppBar-mdAndUp
   [ m_bIsMobile? '100' : '200' ] {'90px': m_bIsMobile, '90px': !m_bIsMobile} -->
  <v-app-bar :style="m_pAppBarStyle">
    <!-- {{m_bDisplayAttributes.name}} -->
    <!-- {{m_text}} -->
    <!--//TODO elevation :height="[ m_bIsMobile? '80' : '125' ]" -->
    <span :class="{ 'container-1-smAndDown': m_bIsMobile, 'container-1-mdAndUp': !m_bIsMobile }">
      <!-- <v-layout align-center justify-end row fill-height /> -->
      <!-- :class="{ 'Box-1-smAndDown': m_bIsMobile, 'Box-1-mdAndUp': !m_bIsMobile }" 
        TODO: make styles separable in css prio 1--> 
      <span :style="m_pLogoBoxStyle">
        <!-- <v-img src="@/assets/images/logo/Logo.svg" width="70%" contain> </v-img> 
        style="text-decoration: none"-->
        <RouterLink :to=this.m_oSoftDevConfigs.m_sPagePaths.m_strhome active-link="linkHoverColor"
          class="commonItemColor navLink">
          <v-img :class="{ 'logoImg-smAndDown': m_bIsMobile, 'logoImg-mdAndUp': !m_bIsMobile }"
            src="@/assets/images/logo/Logo.svg" contain>
          </v-img>
        </RouterLink>
      </span>
      <span :class="{ 'Box-2-smAndDown': m_bIsMobile, 
                      'Box-2-mdAndUp': !m_bIsMobile }" 
        @mouseover="m_bHover = true"
        @mouseleave="m_bHover = false" @click="onBasketClicked">
        <span :style="m_pBasketBoxStyle">
          <v-img class="BasketImg" src="@/assets/images/icons/toBasket.svg">
          </v-img>
          <v-badge class="badge" v-if="0 < m_nBasketItemCount" 
           :content="m_nBasketItemCount" :value="m_nBasketItemCount"
            color="primary" overlap>
          </v-badge>
        </span>
      </span>
    </span>
  </v-app-bar>
</template>

<script>
import { RouterLink, useRouter, useRoute } from "vue-router";
import { ref, onMounted, watch } from "vue";
import { useStore } from 'vuex'


import SoftDevConfigs from "../../configurations/Configs.js";

export default {
  name: "MainNavBar",
  components: { RouterLink },

  props: {
    /**
     * @brief: property for the component to know weither the device is a mobile
     */
    m_bIsMobile: {
      type: Boolean,
      required: true,
    },
    /**
      * @brief: property for the component to know attributes necessary for responsiveness
      */
    m_bDisplayAttributes: {
      required: true,
    },

  },

  setup(props) {
    // use m_oRouter configuration
    const m_oRouter = useRouter();
    /**
     * @brief: reference to the route to watch route params changes
     */
    const m_oRoute = useRoute();
    /**
     * @brief: hovering over basket image state
     *   //TODO: config and/or unit/integration tests must match this to landingpage top margin      prio 6
     */
    const m_bHover = new ref(false);

    // use app shared store to retrieve data to initialize/update shopping cart
    const m_oStore = useStore();
    // use store state getter
    const storeOrders = m_oStore.getters['ShoppingCart/getOrders'];
    /**
     * @brief:
     * @detail:
     * a small analysis with google chrome has shown that the height in percentage of the 
     * top nav bar changes too much based on heidth even when the width is the same
     * (and there by the percentage constant). hence we should make the percentage vary depending on the 
     * inner height from the screen original where test were made
     * (lenovi thinkpad official data:: 1200(H)x1920(W);vuejs data:: innerWidth 1280; innerheight577)
     */
    function getHeight() {
      let f64Val = props.m_bIsMobile ? window.innerHeight * 0.09 : window.innerHeight * 0.2;
      console.log(props.m_bDisplayAttributes.name)
      switch (props.m_bDisplayAttributes.name) {
        case 'xs': {
          f64Val = 1 / 10 * 577;
          break;
        }
        case 'sm': {
          f64Val = 1 / 6 * 577;
          break;
        }
        case 'md': {
          f64Val = 1 / 4 * 577;
          break;
        }
        case 'lg': {// v was optimal for 1920*1080
          f64Val = 1 / 4 * 577;
          break;
        }
        case 'xl': {
          f64Val = 1 / 3 * 577;
          break;
        }
        case 'xxl': {
          f64Val = 1 / 2 * 577
          break;
        }
      }

      return f64Val;
    }
    const m_text = ref(window.innerHeight + " " + window.innerWidth
      + " " + window.outerHeight + " " + window.outerWidth);

    const m_pAppBarStyle = ref({
      height: getHeight().toString() + 'px',

    });

    function getImgBoxWidth() {
      
      let width = 0.24 * window.innerWidth;
      let top = 10;
      // console.log(props.m_bDisplayAttributes.name)
      // Basket-noHover-smAndDown': m_bIsMobile && !m_bHover
      switch (props.m_bDisplayAttributes.name) {
        case 'xs': {
          width = 1 / 5 * 577;
          break;
        }
        case 'sm': {
          width = 1 / 3 * 577;
          break;
        }
        case 'md': {
          width = 1 / 2 * 577;
          top *= 2;
          break;
        }
        case 'lg': {// v was optimal for 1920*1080
          width = 1 / 2 * 577;
          // top *= 2;
          break;
        }
        case 'xl': {
          width = 3/ 4 * 577;
          top *= 1.5;
          break;
        }
        case 'xxl': {
          width = 9/ 10 * 577;
          top *= 5;
          break;
        }
      }

      return { 
        width: width, 
        top: top, 
      };
    }
    /**
     * @brief
     */
    const m_pLogoBoxStyle = ref({
      order: 1,
      "justify-self": "flex-start",
      "margin-left": "2%",
      "margin-top": getImgBoxWidth().top.toString() + "px",
      // margin-bottom: 1%,
      // margin-right: auto,
      width: getImgBoxWidth().width.toString() + "px",
      
      // height: 50%,
      // "border-style": "dashed",
      // "border-color": "black",
    });

    function getBasketBoxWidth() {
      let width = 0.24 * window.innerWidth;
      let top = 20;
      let left = 25;
      let height = 50;
      // console.log(props.m_bDisplayAttributes.name)
      // Basket-noHover-smAndDown': m_bIsMobile && !m_bHover
      switch (props.m_bDisplayAttributes.name) {
        case 'xs': {
          width = 1 / 4 * 577;
          top = 7;
          left = 0;
          break;
        }
        case 'sm': {
          width = 1 / 3 * 577;
          height = 55;
          break;
        }
        case 'md': {
          width = 1 / 2 * 577;
          top *= 1.5;
          break;
        }
        case 'lg': {// v was optimal for 1920*1080
          width = 577;
          height = 70;
          break;
        }
        case 'xl': {
          width = 577;
          top *= 1.5;
          break;
        }
        case 'xxl': {
          width = 2*577;
          top *= 2;
          height = 120;
          break;
        }
      }

      return { 
        width: width, 
        top: top, 
        left: left,
        height: height ,
      };
    }
    const m_pBasketBoxStyle = ref({
      
      order: 1,
      width: getBasketBoxWidth().width.toString() + "px",
      height: getBasketBoxWidth().height.toString() + "px",
      "margin-left": getBasketBoxWidth().left.toString() + "%",
      "margin-top": getBasketBoxWidth().top.toString() + "px",
      "margin-right": "5%",
      // "border-style": "dashed",
      // "border-color": "blueviolet",
      display: "flex",
      "flex-direction": "row",
    });


    /**
     * @brief: number of items in basket
     */
    const m_nBasketItemCount = new ref(0);
    /* watch for changes of these values */
    watch([m_oStore.getters['ShoppingCart/getOrders']], () => {
      m_nBasketItemCount.value = 0;
      for (let storeOrder of storeOrders) {// init basket item(s)
        m_nBasketItemCount.value += storeOrder[1].m_uNbrofUnits;
      }
      console.log(m_nBasketItemCount.value)
    }, { deep: true })//TODO: deep watch necessary ???? prio 3


    onMounted(async () => {
    })

    /**
    * @brief: called when pressed on search icon
    * @details: the method will either trigger a search or launch navigation
    * to a private page
    * this is a method of SearchFieldViewModel
    */
    function onBasketClicked() {// SoftDevConfigs().m_sPagePaths.m_strhome

      m_oRouter.push({
        name: 'shoppingcart',
        query: {
          ...m_oRoute.query,
        },
      })

    }

    /**
     * @brief: post process each change of page path
     * @details:
     * - app bar must be black on shopping cart page
     */
    watch(
      () => m_oRoute.path, () => {

        // change app bar color to black
        let pAppBar = document.querySelector(".AppBar-smAndDown");
        if ((undefined == pAppBar) || (null == pAppBar)) {
          pAppBar = document.querySelector(".AppBar-mdAndUp");
        }

        if ((undefined != pAppBar) && (null != pAppBar)) {
          if (SoftDevConfigs().m_sPagePaths.m_strShoppingcart === m_oRoute.path) {
            pAppBar.style.setProperty('background-color', 'black');
          }
          else {
            pAppBar.style.setProperty('background-color', 'white');
          }
        }
      }
    )



    return {
      m_text,

      m_pAppBarStyle,
      m_pLogoBoxStyle,
      m_pBasketBoxStyle,
      m_bHover, m_nBasketItemCount,
      onBasketClicked,

    };
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss"; // $text-primary would be defined in that file

.container-1-mdAndUp {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;

  width: 100%;
  height: 100%;
  // max-height: 1200px;
  margin-top: 0%;
  margin-bottom: 0%;
  margin-left: 0%;
  margin-right: 0%;
  padding-left: 0%;
  padding-right: 0%;
  // border-style: dotted;
}

.container-1-smAndDown {
  display: flex;
  flex-direction: row;
  justify-items: flex-start;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;

  width: 100%;
  height: 100%;
  // max-height: 1200px;
  margin-top: 0%;
  margin-bottom: 0%;
  margin-left: 0%;
  margin-right: 0%;
  padding-left: 0%;
  padding-right: 0%;
  // border-style: dotted;
}

.Box-1-mdAndUp {
  order: 1;
  justify-self: flex-start;
  margin-left: 2%;
  margin-top: 1%;
  // margin-bottom: 1%;
  // margin-right: auto;
  width: 24%;
  // height: 50%;
  border-style: dashed;
  border-color: black;
}

.Box-1-smAndDown {
  order: 1;
  justify-self: flex-start;
  margin-left: 1%;
  margin-top: 0%;
  // margin-bottom: 1%;
  // margin-right: auto;
  width: 40%;
  // height: 50%;
  // border-style: dashed;
  // border-color: black;
}

.logoImg-mdAndUp {
  width: 100%;
  // margin-left: 0%;
}

.logoImg-smAndDown {
  width: 100%;
  // margin-top: 7%;
}

.Box-2-mdAndUp {
  order: 2;
  display: flex;
  flex-direction: row;
  // border-style: dashed;
  // border-color: yellow;

  margin-top: 2%;
  margin-right: 3%;
  overflow: visible;
  width: 40%;
  height: 100%;
}


.Box-2-smAndDown {
  order: 2;
  display: flex;
  flex-direction: row;
  // border-style: dashed;
  // border-color: yellow;

  margin-top: 0%;
  margin-right: 3%;
  overflow: visible;
  width: 40%;
  height: 100%;
}


.BasketImg {
  order: 1;
  width: 100%;
  // margin-bottom: 10%;
  // border-style: double;
  // background: $color-primary;
  // color: aquamarine;
}
.BasketImg:hover {
  cursor: pointer;
  opacity: 0.75;
  background-blend-mode: darken;
}

.badge {
  order: 2;
  position: relative;
  // transform: translate(-100%, 25%);
  margin-left: -25%;
  margin-top: 2%;
  // background: $color-primary;
  // color: aquamarine;
}

</style>
