const enStoreType_t = Object.freeze({
  eStoryType_Unknown: 0,
  eStoryType_Restaurant: 1,
  eStoryType_Groceries: 2,
  eStoryType_Furnitures: 3,
  eStoryType_Electronic: 4,
  eStoryType_Supermarket: 5,
  eStoryType_Invalid: 255,
});

export default enStoreType_t;
