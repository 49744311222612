const enStoreStatus_t = Object.freeze({
  eStoryStatus_Unknown: 0,
  eStoryStatus_Created: 1,
  eStoryStatus_Registered: 2,
  eStoryStatus_RegisteredActive: 3,
  eStoryStatus_VerifiedInactive: 4,
  eStoryStatus_VerifiedActive: 5,
  eStoryStatus_Open: 6,
  eStoryStatus_Closed: 7,
  eStoryStatus_UnRegistered: 8,
  eStoryStatus_Forbidden: 9,
  eStoryStatus_Invalid: 255,
});

export default enStoreStatus_t;
