/**
 * @brief Enumeration of common return values
 */

const enReturnValues_t = Object.freeze({
  /** Unknown, erroneous or un-described error  (first of the failure\error return values */
  eReturnValue_ErrUnknown: 0, // return value for undefined\erroneous result
  eReturnValue_ErrNotEnoughInputs: 1, // return value when certain input size was too small, or was omitted completely

  /**System handler related error */
  ReturnValue_ErrInconsistentProcessStatus: 2, // return value when the process status doesn't allow the action intended

  /**File handler related errors */
  eReturnValue_ErrFileNotFound: 3, // return value when to file to be write into is not found

  /** Unknown exception first exception error value */
  eReturnValue_ErrInputsInconsistent: 4, // return value when the input composition is invalid/inconsistent
  eReturnValue_ErrIntegerInvalid: 5, // Incorrect integer value

  /**Enumeration codes for standard success return value */
  eReturnValue_NothingToDO: 6, // return value when the feature was already initialized
  eReturning_ErrInvalid: 255, // place holder to catch negative number wrong casting and other overflows
});

export default enReturnValues_t;
