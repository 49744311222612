const enOrderStatus_t = Object.freeze({
  eOrderStatus_Unknown: 0,
  eOrderStatus_Created: 1,
  eOrderStatus_validated: 2,
  eOrderStatus_Ready: 3,
  eOrderStatus_Shipped: 4,
  eOrderStatus_InDelivery: 5,
  eOrderStatus_Delivered: 6,
  eOrderStatus_Paid: 7,
  eOrderStatus_Cancelled: 8,
  eOrderStatus_Aborted: 9,
  eOrderStatus_Blocked: 10,
  eOrderStatus_Closed: 11,
  eOrderStatus_Invalid: 255,
});

/// @brief: Define the maximum orderStatus constant
const SOFTCONFIG_DEV_ORDERSTATUS_MAX = enOrderStatus_t.eOrderStatus_Closed;
/// @brief: Define the minimum orderStatus constant
const SOFTCONFIG_DEV_ORDERSTATUS_MIN = enOrderStatus_t.eOrderStatus_Created;

const ORDERS_STATUS = Object.freeze([
  'Inconnue',
  'Établie',
  'Validé',
  'Prêt',
  'Expédié',
  'En livraison',
  'Livré',
  'Payé',
  'Annulé',
  'Avorté',
  'Bloqué',
  'Fermé',
  'Non valide',
]);

/**
 * @brief Convert the enum enOrderStatus to string,
 * The string returned is based on the value of the enOrderStatus_t defined.
 * @param {enOrderStatus_t} i_eOrderStatus -> The OrderStatus to be converted to string
 * @returns {String}
 */
const toString = (i_eOrderStatus) => {
  let strOrderStatus;
  if (
    i_eOrderStatus < SOFTCONFIG_DEV_ORDERSTATUS_MIN ||
    i_eOrderStatus > SOFTCONFIG_DEV_ORDERSTATUS_MAX
  ) {
    strOrderStatus = ORDERS_STATUS[ORDERS_STATUS.length - 1];
  } else {
    strOrderStatus = ORDERS_STATUS[i_eOrderStatus];
  }
  return strOrderStatus;
};

/**
 * @brief Convert string to the proper enum enOrderStatus if applicable,
 * The enum returned is based on the value of the string provided.
 * @param {String} i_strOrderStatus -> The OrderStatus to be converted to enOrderStatus_t
 * @returns {enOrderStatus_t}
 */
const fromString = (i_strOrderStatus) => {
  let eResult = enOrderStatus_t.eOrderStatus_Invalid;
  let enumValues = Object.values(enOrderStatus_t);
  let enumKeys = Object.keys(enOrderStatus_t);
  let index = ORDERS_STATUS.indexOf(i_strOrderStatus);
  if (
    -1 < index &&
    enumValues.includes(index) &&
    SOFTCONFIG_DEV_ORDERSTATUS_MIN <= index &&
    SOFTCONFIG_DEV_ORDERSTATUS_MAX >= index
  ) {
    eResult = enOrderStatus_t[enumKeys[index]];
  }else {
    eResult = enOrderStatus_t.eMenuCategory_Invalid;
  }

  return eResult;
};


/**
 * @brief Convert string to the proper enum enOrderStatus if applicable,
 * The enum returned is based on the value of the string provided.
 * @param {number} i_uOrderStatus -> The OrderStatus to be converted to enOrderStatus_t
 * @returns {enOrderStatus_t}
 */
const fromNumber = (i_uOrderStatus) => {
  let eResult = enOrderStatus_t.eOrderStatus_Invalid;
  if (
    SOFTCONFIG_DEV_ORDERSTATUS_MIN <= i_uOrderStatus &&
    SOFTCONFIG_DEV_ORDERSTATUS_MAX >= i_uOrderStatus
  ) {
    let enumValues = Object.values(enOrderStatus_t);
    let enumKeys = Object.keys(enOrderStatus_t);
    let index = enumValues.indexOf(i_uOrderStatus);
    if (-1 < index) {
      eResult = enumKeys[index];
    }
  }
  return eResult;
};

export {
  enOrderStatus_t,
  ORDERS_STATUS,
  SOFTCONFIG_DEV_ORDERSTATUS_MAX,
  SOFTCONFIG_DEV_ORDERSTATUS_MIN,
  toString,
  fromString,
  fromNumber,
};
