const enRequestNames_t = Object.freeze({
    eRequestNames_AllRestaurants: 0,
    eRequestNames_AllMenuItems: 1,
    eRequestNames_MenuItemImg: 2,
    eRequestNames_AllOrders:  3
  });
  
  
  /// @brief: Define the maximum orderStatus constant
  const SOFTCONFIG_DEV_REQUESTNAMES_MAX = enRequestNames_t.eRequestNames_AllOrders;
  /// @brief: Define the minimum orderStatus constant
  const SOFTCONFIG_DEV_REQUESTNAMES_MIN = enRequestNames_t.eRequestNames_AllRestaurants;
  
  const REQUEST_NAMES = Object.freeze([
      'AllRestaurants',
      'AllMenuItems',
      'MenuItemImg',
      'AllOrders',
    ]);
  
  
    /**
   * @brief Convert the enum enRequestNames_t to string,
   * The string returned is based on the value of the i_eRequestName defined.
   * @param {enRequestNames_t} i_eRequestName -> The requestName to be converted to string
   * @returns {String}
   */
  const toString = (i_eRequestName) => {
      let strRequestParam;
      if (
        i_eRequestName < SOFTCONFIG_DEV_REQUESTNAMES_MIN ||
        i_eRequestName > SOFTCONFIG_DEV_REQUESTNAMES_MAX
      ) {
        strRequestParam = REQUEST_NAMES[REQUEST_NAMES.length - 1];
      } else {
        strRequestParam = REQUEST_NAMES[i_eRequestName];
      }
      return strRequestParam;
    };
    
    /**
     * @brief Convert string to the proper enum enItemStatus if applicable,
     * The enum returned is based on the value of the string provided.
     * @param {String} i_strRequestParam -> The ItemStatus to be converted to i_eRequestName
     * @returns {enRequestNames_t}
     */
    const fromString = (i_eRequestName) => {
      let eResult = i_eRequestName.eItemStatus_Invalid;
      let enumValues = Object.values(enRequestNames_t);
      let enumKeys = Object.keys(enRequestNames_t);
      let index = REQUEST_NAMES.indexOf(i_eRequestName);
      if (
        -1 < index &&
        enumValues.includes(index + 1) &&
        SOFTCONFIG_DEV_REQUESTNAMES_MIN <= index + 1 &&
        SOFTCONFIG_DEV_REQUESTNAMES_MAX >= index + 1
      ) {
        eResult = enumKeys[index];
      }
    
      return eResult;
    };
    
    /**
     * @brief Convert string to the proper enum enItemStatus if applicable,
     * The enum returned is based on the value of the string provided.
     * @param {number} i_uRequestName -> The ItemStatus to be converted to i_eRequestName
     * @returns {enRequestNames_t}
     */
    const fromNumber = (i_uRequestName) => {
      let eResult = null;
      if (
        SOFTCONFIG_DEV_REQUESTNAMES_MIN <= i_uRequestName &&
        SOFTCONFIG_DEV_REQUESTNAMES_MAX >= i_uRequestName
      ) {
        let enumValues = Object.values(enRequestNames_t);
        let enumKeys = Object.keys(enRequestNames_t);
        let index = enumValues.indexOf(i_uRequestName);
        if (-1 < index) {
          eResult = enumKeys[index];
        }
      }
      return eResult;
    };
    
    export {
      enRequestNames_t,
      REQUEST_NAMES,
      SOFTCONFIG_DEV_REQUESTNAMES_MAX,
      SOFTCONFIG_DEV_REQUESTNAMES_MIN,
      toString,
      fromString,
      fromNumber,
    };
    