const enUserTypes_t = Object.freeze({
  eUserType_Unknown: 0,
  eUserType_Customer: 1,
  eUserType_AfterSalesStaff: 2,
  eUserType_manager: 3,
  eUserType_Developer: 4,
  eGender_Invalid: 255,
});

export default enUserTypes_t;
