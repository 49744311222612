<template>
    <v-img :class="{ 'Img-default': this.m_oSoftDevConfigs.CST_IMAGES.DEFAULT_IMAGE==m_strMenuItemImage,
                     'Img': this.m_oSoftDevConfigs.CST_IMAGES.DEFAULT_IMAGE!=m_strMenuItemImage}"
        :v-if="m_strMenuItemImage"
        :src="m_strMenuItemImage" contain cover>
        <span class="container-1"><!-- TODO: LOAD DEFAULT IMAGE FOR DELETED ITEM STATUS prio 2-->
            <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! dialog START !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
            <v-dialog class="RemoveMenuItemPrompt" v-model="m_bDialog" persistent v-if="2 == m_eActionModes">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn :class="{
                            'RemoveMenuItem-Activated-smAndDown': m_bIsMobile  && (this.m_oSoftDevConfigs.CST_ENUMS.ITEM_ACTIVATED == m_oMenuItemData.m_eItemStatus),
                            'RemoveMenuItem-DeActivated-smAndDown': m_bIsMobile  && (this.m_oSoftDevConfigs.CST_ENUMS.ITEM_DEACTIVATED == m_oMenuItemData.m_eItemStatus),
                            'RemoveMenuItem-Activated-mdAndUp': !m_bIsMobile && (this.m_oSoftDevConfigs.CST_ENUMS.ITEM_ACTIVATED == m_oMenuItemData.m_eItemStatus),
                            'RemoveMenuItem-DeActivated-mdAndUp': !m_bIsMobile && (this.m_oSoftDevConfigs.CST_ENUMS.ITEM_DEACTIVATED == m_oMenuItemData.m_eItemStatus),
                            'RemoveMenuItem-todelete-smAndDown': m_bIsMobile  && (this.m_oSoftDevConfigs.CST_ENUMS.ITEM_DELETED == m_oMenuItemData.m_eItemStatus),
                            'RemoveMenuItem-todelete-mdAndUp': !m_bIsMobile && (this.m_oSoftDevConfigs.CST_ENUMS.ITEM_DELETED == m_oMenuItemData.m_eItemStatus),
                        }" icon dark v-bind="attrs" 
                        v-on:click="on" @click="onDeleteClicked(1)">
                        <!-- TODO: replace hard coded const prio 4-->
                        <!-- TODO: replace hard codedconstant with proper global? prio 4-->
                        <v-icon>
                            mdi-close-circle
                        </v-icon>
                    </v-btn>
                </template>
                <v-card v-if="0 == m_uConfirmeDeletion" class="dialogCard">
                    <span class="container-11">
                        <span class="Box-111"><h3>Voulez vous vraiment effacer?</h3></span>
                        <span class="container-112">
                            <v-btn class="Box-1121-deActivate" text @click="onDeleteClicked(2)">
                                <!-- TODO: replace hard coded const prio 4-->
                                {{m_strDeActivateButtonText}}
                            </v-btn>
                            <v-btn class="Box-1122-cancel" text @click="onDeleteClicked(3)">
                                <!-- TODO: replace hard coded const prio 4-->
                                Cancel
                            </v-btn>
                            <v-btn class="Box-1123-deleteFinal" text @click="onDeleteClicked(4)">
                                <!-- TODO: replace hard coded const prio 4-->
                                Delete DEFINITIVELY
                            </v-btn>
                        </span>
                    </span>
                </v-card>
                <v-card v-if="1 == m_uConfirmeDeletion" class="dialogCard">
                    <span class="container-11">
                        <span class="Box-111"><h3>{{ m_str2ndConfirmationText }}</h3></span>
                        <span class="container-112">
                            <v-btn class="Box-1122-cancel" text @click="onDeleteClicked(5)">
                                <!-- TODO: replace hard coded const prio 4-->
                                No
                            </v-btn>
                            <v-btn class="Box-1123-deleteFinal" text @click="onDeleteClicked(6)">
                                <!-- TODO: replace hard coded const prio 4-->
                                Yes
                            </v-btn>
                        </span>
                    </span>
                </v-card>
                <v-card v-if="2 == m_uConfirmeDeletion" class="dialogCard">
                    <span class="container-11">
                        <span class="Box-111"><h3>Les changements ne seront pas envoyés au server
                            avant que vous n'appuyez sauvegarder</h3>
                        </span>
                        <span class="container-112">
                            <v-spacer></v-spacer>
                            <v-btn class="Box-1122-cancel" text @click="onDeleteClicked(7)">
                                <!-- TODO: replace hard coded const prio 4-->
                                Ok!
                            </v-btn>
                        </span>
                    </span>
                </v-card>
            </v-dialog>
            <!-- !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! dialog END !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! -->
            <!-- card view main label(name of the menu item) -->
            <!-- <v-btn :class="{
                'viewCardPrice-Activated-smAndDown': m_bIsMobile  && (this.m_oSoftDevConfigs.CST_ENUMS.ITEM_ACTIVATED == m_oMenuItemData.m_eItemStatus),
                'viewCardPrice-DeActivated-smAndDown': m_bIsMobile  && (this.m_oSoftDevConfigs.CST_ENUMS.ITEM_DEACTIVATED == m_oMenuItemData.m_eItemStatus),
                'viewCardPrice-Activated-mdAndUp': !m_bIsMobile && (this.m_oSoftDevConfigs.CST_ENUMS.ITEM_ACTIVATED == m_oMenuItemData.m_eItemStatus),
                'viewCardPrice-DeActivated-mdAndUp': !m_bIsMobile && (this.m_oSoftDevConfigs.CST_ENUMS.ITEM_DEACTIVATED == m_oMenuItemData.m_eItemStatus),
                'viewCardPrice-todelete-smAndDown': m_bIsMobile  && (this.m_oSoftDevConfigs.CST_ENUMS.ITEM_DELETED == m_oMenuItemData.m_eItemStatus),
                'viewCardPrice-todelete-mdAndUp': !m_bIsMobile && (this.m_oSoftDevConfigs.CST_ENUMS.ITEM_DELETED == m_oMenuItemData.m_eItemStatus),
            }" v-if="2 == m_eActionModes">
                {{ m_oMenuItemData.m_uUnitPrice }}
            </v-btn> -->
            <v-text-field :class="{
                'viewCardPrice-Activated-smAndDown': m_bIsMobile  && (this.m_oSoftDevConfigs.CST_ENUMS.ITEM_ACTIVATED == m_oMenuItemData.m_eItemStatus),
                'viewCardPrice-DeActivated-smAndDown': m_bIsMobile  && (this.m_oSoftDevConfigs.CST_ENUMS.ITEM_DEACTIVATED == m_oMenuItemData.m_eItemStatus),
                'viewCardPrice-Activated-mdAndUp': !m_bIsMobile && (this.m_oSoftDevConfigs.CST_ENUMS.ITEM_ACTIVATED == m_oMenuItemData.m_eItemStatus),
                'viewCardPrice-DeActivated-mdAndUp': !m_bIsMobile && (this.m_oSoftDevConfigs.CST_ENUMS.ITEM_DEACTIVATED == m_oMenuItemData.m_eItemStatus),
                'viewCardPrice-todelete-smAndDown': m_bIsMobile  && (this.m_oSoftDevConfigs.CST_ENUMS.ITEM_DELETED == m_oMenuItemData.m_eItemStatus),
                'viewCardPrice-todelete-mdAndUp': !m_bIsMobile && (this.m_oSoftDevConfigs.CST_ENUMS.ITEM_DELETED == m_oMenuItemData.m_eItemStatus),
            }"  v-if="2 == m_eActionModes"
             label="Prix" @change="onChangedPrice"
             v-model.number.lazy="m_uUnitPrice" />
             
            <v-btn :class="{
                'viewCardPrice-smAndDown': m_bIsMobile,
                'viewCardPrice-mdAndUp': !m_bIsMobile,
            }" v-if="2 != m_eActionModes && 3 != m_eActionModes">
                {{ m_oMenuItemData.m_uUnitPrice }}
            </v-btn>
            <v-btn :class="{
                'viewCardPrice3-smAndDown': m_bIsMobile,
                'viewCardPrice3-mdAndUp': !m_bIsMobile,
            }" v-if="3 == m_eActionModes">
                {{ m_oMenuItemData.m_uUnitPrice }}
            </v-btn>
            <v-btn :class="{
                'viewCardTotalPrice-smAndDown': m_bIsMobile,
                'viewCardTotalPrice-mdAndUp': !m_bIsMobile,
            }" v-if="3 == m_eActionModes">
                {{ m_oMenuItemData.m_uTotalPrice }}
            </v-btn>
        </span>
    </v-img>
</template>


<script>
import { onBeforeMount, ref, watch } from "vue";

import SoftDevConfigs from "../../configurations/Configs.js";
import {enItemStatus_t} from "@/../Common/commontypes/enums/";
import jHttpHandler from '../../Model/ServerClient/Http/jHttpHandler';
import { enHttpResponseStatusCode_t } from "@/../Common/commontypes/enums/index";
// import {isPromise} from '../../Common/TaskUtils/AsyncTaskUtils';

export default {
    name: "DynamicMenuItemImage",
    components: {},

    props: {
        /**
         * @brief: property for the component to know weither the device is a mobile
         */
        m_bIsMobile: {
            type: Boolean,
            required: true,
        },
        /**
         * @brief: property for the component to know attributes necessary for responsiveness
         */
        m_bDisplayAttributes: {
            required: true,
        },
        /**
         * @brief: tell us which action buttons must be active (close and/or validate)
         * @details: if 2, close is active
         */
        m_eActionModes: {
            required: true,
        },
        /**
         * @brief: property for the component to know the name of the image to fetch
         */
        m_oMenuItemData: {
            // type: String,
            required: true,
        },



    },

    /**
  * @brief: explicite declaration of events for proper documentation and visibility 
  * (and some maybe unknown reasons)
  */
    emits: [SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent],



    //Use setup option as the entry point to the Composition API
    setup(props, { emit }) {

        var m_strMenuItemImage = new ref(null);
        /**
         * @brief:  private variable used to store generated image to preview newly selected file
         * until it is uploaded to server, by then it will have to be cleaned up
         *  */ 
        let m_urlLoadedPreviewImage = undefined;
        /**
         * @brief binding to allow configurable display of the price on appropriate interfaces
         */
        var m_uUnitPrice = new ref(props.m_oMenuItemData.m_uUnitPrice);

        /**
        * @brief: called either when the card view is created or when the image attribute of the menu 
        * item view data model has been changed in/by parent
        * @details: triggers the dynamic initialization of an image display via fetch from server
        * or by using a default image.
        * any change to props.m_oMenuItemData.m_strMenuItemImage must trigger a recall of
        * initImg()
        */
        function initImg() {
            // console.log(props.m_oMenuItemData.m_strMenuItemImage);//TODO: remove this or keep only oin debug mmode level prio 10
            if ((undefined !=props.m_oMenuItemData.m_strMenuItemImage.lastModified )) {
                // the user selecte a temporary other image,load preview until the menu item
                // is updated into server db and a re-fetch is done!
                const reader = new FileReader();
                reader.onload = e => {
                    m_strMenuItemImage.value = e.target.result;
                    m_urlLoadedPreviewImage = m_strMenuItemImage.value ;
                };
                reader.readAsDataURL(props.m_oMenuItemData.m_strMenuItemImage);
            } else if ((props.m_oMenuItemData.m_strMenuItemImage.length) &&
                (!props.m_oMenuItemData.m_strMenuItemImage.includes("/img/")) ) {
                if (undefined != m_urlLoadedPreviewImage) {// if a temporary image preview was done clean memory up!
                    window.URL.revokeObjectURL(m_urlLoadedPreviewImage);
                    m_urlLoadedPreviewImage = undefined;
                }
                fetchCardImage();
            } else {
                m_strMenuItemImage.value = SoftDevConfigs().CST_IMAGES.DEFAULT_IMAGE;
                m_urlLoadedPreviewImage = undefined;
            }
        }
        // trigger image display initialization!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        initImg();

        
        /**
         * @brief: launch the fetching of a dynamic image ressource from the server to be displayed
         * by this dynamic image component instance 
         * @details: the image file is to be received well preferably but not necessarely received 
         * before the view is activated.
         */
        function fetchCardImage() {
            jHttpHandler.requestImage(props.m_oMenuItemData)
                .then(async function (i_oResponse) {
                    let oResultData = {
                        m_eResponseStatus: i_oResponse.status,
                    };
                    if (enHttpResponseStatusCode_t.eStatusCode_Ok === i_oResponse.status) { //TODO: log only in debg modus to file?? prio 10
                        console.log("DynamicMenuItemImage::fetchCardImage:  succeeded " +
                            " Response Status:=" + i_oResponse.status +
                            " Response Type:=" + i_oResponse.type + " Response:=");
                        // console.log(i_oResponse);//TODO: remove this or keep only oin debug mmode level prio 10
                        let i_oBlobResponse = await i_oResponse.blob();
                        const m_urlLoadedPreviewImage = window.URL.createObjectURL(i_oBlobResponse);
                        m_strMenuItemImage.value = m_urlLoadedPreviewImage;
                        //  TODO: analyse memory consumption prio 3
                        // setTimeout(() => {
                        //     window.URL.revokeObjectURL(m_urlLoadedPreviewImage);
                        // }, 1000); //TODO: set this in a proper constant prio 5
                        
                        //  const link = document.createElement('a')
                        // link.href = m_urlLoadedPreviewImage
                        // link.setAttribute('download', props.m_oMenuItemData.m_strMenuItemImage)
                        // document.body.appendChild(link)
                        // link.click()
                        
                        // console.log(response);//TODO: deactivate(this log) if not debug modus?? prio 5
                    } else {
                        console.log("DynamicMenuItemImage::fetchCardImage:  failed " +
                            " because response NotOk! Status:=[" + i_oResponse.status +
                            "] type:=[" + i_oResponse.type + "] Response:=");
                        console.log(i_oResponse);//TODO: deactivate(this log) if not debug modus?? prio 5 
                        oResultData.m_strResponseMsg = await i_oResponse.text();
                    }
                    return oResultData;
                })
                .then((i_oResponse) => {
                    // m_strMenuItemImage.value = window.URL.createObjectURL(i_oBlobResponse.data);
                    if (enHttpResponseStatusCode_t.eStatusCode_Ok === i_oResponse.m_eResponseStatus) {
                        // console.log(m_strMenuItemImage.value);//TODO: do this only in debug!! prio 8
                    } else {
                        // console.log(i_oResponse);
                    }
                })
                .catch(function (e) {
                    console.log(e); // TODO: move this to an error handler prio 1
                });
        }

        onBeforeMount(() => {
            // if (props.m_oMenuItemData.m_strMenuItemImage.includes("/img/")) {
            //     m_strMenuItemImage.value = props.m_oMenuItemData.m_strMenuItemImage;
            // }
            m_strDeActivateButtonText.value =  (enItemStatus_t.eItemStatus_DeActivated == props.m_oMenuItemData.m_eItemStatus)
            ||  (enItemStatus_t.eItemStatus_Deleted == props.m_oMenuItemData.m_eItemStatus)
            ?  "Activate" : "DeActivate"; 
            
        })


        /// @brief: main close incon dialog state variable(true=visible, false= hidden)
        var m_bDialog = ref(false);
        /// @brief: second confirmation close icon dialog state variable(true=visible, false= hidden)
        var m_uConfirmeDeletion = ref(0);
        var m_strDeActivateButtonText = ref(""); //TODO: improve hard coded constant prio 4
        var m_str2ndConfirmationText = ref(""); //TODO: improve hard coded constant prio 4
        /**
         * @brief: callback caled when the close icon button has been pressed
         * @details: user must be prompt to choose between:
         * - delete item
         * - deactivate item
         */
        function onDeleteClicked(i_uUseCase) {
            switch (i_uUseCase) {
                case undefined:
                case 1: {// TODO: replace hard coded const prio 4
                    m_bDialog.value = true;
                    m_strDeActivateButtonText.value = 
                    (enItemStatus_t.eItemStatus_DeActivated == props.m_oMenuItemData.m_eItemStatus)
                        || (enItemStatus_t.eItemStatus_Deleted == props.m_oMenuItemData.m_eItemStatus)
                        ? "Activate" : "DeActivate"; 
                    break;
                }
                case 2: {// TODO: replace hard coded const prio 4
                    m_uConfirmeDeletion.value = 1;// open 2nd confirmation
                    m_str2ndConfirmationText.value = 
                    ("Activate" == m_strDeActivateButtonText.value ) ? "Voulez vous Vraiment Activer? "
                    :("DeActivate" == m_strDeActivateButtonText.value ) ?   "Voulez vous vraiment désactiver?"
                    : "ERROR!"; //TODO: improve hard coded constant prio 4
                    break;
                }
                case 3: {// TODO: replace hard coded const prio 4
                    m_bDialog.value = false;
                    break;
                }
                case 4: {// TODO: replace hard coded const prio 4
                    m_uConfirmeDeletion.value = 1;// open 2nd confirmation
                    m_str2ndConfirmationText.value = "Voulez vous vraiment vraiment effacer DEFINITIVEMENT?"; //TODO: improve hard coded constant prio 4
                    m_strDeActivateButtonText.value =  (
                        enItemStatus_t.eItemStatus_DeActivated != props.m_oMenuItemData.m_eItemStatus)
                        ? "DeActivate" : "Activate"; 
                    break;
                }
                case 5: {// TODO: replace hard coded const prio 4
                    m_bDialog.value = false;// close both dialogs
                    m_uConfirmeDeletion.value = 0;
                    break;
                }
                case 6: {// TODO: replace hard coded const prio 4
                    m_uConfirmeDeletion.value = 2;
                    break;
                }
                case 7: {// TODO: replace hard coded const prio 4
                    m_bDialog.value = false;// close both dialogs
                    m_uConfirmeDeletion.value = 0;
                    let newStatus = "Voulez vous vraiment vraiment effacer DEFINITIVEMENT?" == m_str2ndConfirmationText.value
                    ? enItemStatus_t.eItemStatus_Deleted : "Voulez vous vraiment désactiver?" == m_str2ndConfirmationText.value
                    ? enItemStatus_t.eItemStatus_DeActivated :enItemStatus_t.eItemStatus_Activated;
                    emitItemModificationRequest( newStatus);
                    break;
                }
                default: {// TODO: replace hard coded const prio 4
                    console.log("DynamicMenuItemImage::onDeleteClicked: WARNING: unknwon case ");
                    console.log(i_uUseCase);
                }
            }

        }

        /**
         * @brief: the current menu item data are used to send a request to modify
         * these items into the data base depending on the use cases
         * @details: if true the emission will request a deletion otherwise
         * a deactivation of the menu item
         * - 3: delete item, 2- desactivate item 1_ activate
         */
        function emitItemModificationRequest(i_eNewStatus) {
            emit(SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent, {
                m_strUsecase: SoftDevConfigs().CST_EVENT_NAMES.m_strRemoveItem,
                m_uMenuItemId: props.m_oMenuItemData.m_uMenuItemId,
                m_uRestaurantId: props.m_oMenuItemData.m_uRestaurantId,
                m_eNewStatus: i_eNewStatus// if false deactivate, otherwise mark item as enItemStatus_t.eItemStatus_Deleted
            });
        }

        /* watch for props to update item status */
        watch(() => props.m_oMenuItemData.m_strMenuItemImage, () => {
            initImg();
        }, { deep: false })        
        /* watch for props to update item status */
        watch(() => props.m_oMenuItemData.m_uUnitPrice, () => {// in case another spource changes the unit price
            if (props.m_oMenuItemData.m_uUnitPrice != m_uUnitPrice.value)
                m_uUnitPrice.value = props.m_oMenuItemData.m_uUnitPrice;
        }, { deep: false })

        function onChangedPrice(){

            emit(SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent, {
                m_strUsecase: SoftDevConfigs().CST_EVENT_NAMES.m_strUpdateRestaurantItem,
                m_uMenuItemId: props.m_oMenuItemData.m_uMenuItemId,
                m_uRestaurantId: props.m_oMenuItemData.m_uRestaurantId,
                m_uUnitPrice: m_uUnitPrice,

            });

        }


        return {
            m_strMenuItemImage, m_bDialog, m_uUnitPrice,
            m_uConfirmeDeletion, m_str2ndConfirmationText,
            m_strDeActivateButtonText,
            onDeleteClicked,onChangedPrice
        };
    },
}

</script>


<!-- id selector -> class selector -> element selector -->
<style lang="scss" scoped>
@import "@/styles/variables.scss"; // $text-primary would be defined in that file

.container-1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    justify-items: flex-start;
}
.Img-default {
    border-style: outset;
}
.Img {
    border-style: none;
}
.RemoveMenuItemPrompt {
    width: 40%;
    height: 40%;

}
.RemoveMenuItem-Activated-mdAndUp {
    order: 1;

    min-height: 0%;
    min-width: 0%;
    width: fit-content;
    height: fit-content;
    margin-left: 90%;
    background: none;
    color: $color-secondary;
    background-color: $color-on-secondary;
}
.RemoveMenuItem-DeActivated-mdAndUp {
    order: 1;

    min-height: 0%;
    min-width: 0%;
    width: fit-content;
    height: fit-content;
    margin-left: 90%;
    background: none;
    color: $color-info;
    background-color: $color-info;
}
.RemoveMenuItem-Activated-smAndDown {
    order: 1;

    min-height: 0%;
    min-width: 0%;
    width: fit-content;
    height: fit-content;
    margin-left: 90%;
    background: none;
    color: $color-secondary;
    background-color: $color-on-secondary;
}
.RemoveMenuItem-DeActivated-smAndDown {
    order: 1;

    min-height: 0%;
    min-width: 0%;
    width: fit-content;
    height: fit-content;
    margin-left: 90%;
    background: none;
    color: $color-info;
    background-color: $color-info;
}
.RemoveMenuItem-todelete-mdAndUp {
    order: 1;

    min-height: 0%;
    min-width: 0%;
    width: fit-content;
    height: fit-content;
    margin-left: 90%;
    background: none;
    color: $color-primary;
    background-color: $color-on-primary;
}
.RemoveMenuItem-todelete-smAndDown {
    order: 1;

    min-height: 0%;
    min-width: 0%;
    width: fit-content;
    height: fit-content;
    margin-left: 90%;
    background: none;
    color: $color-primary;
    background-color: $color-on-primary;
}



.dialogCard {
    margin-top: 0%;
    margin-left: 0%;
    position: relative;
}

.container-11 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Box-111 {
    order:1;
    color: $color-accent;
    font-size: large;
    margin-top: 2%;
    margin-bottom: 7%;
}

.container-112 {
    order:2;
    flex-direction: row;
    // justify-content: space-between;
    // align-content: space-around;
    margin-bottom: 5%;
}
.Box-1121-deActivate {
    order:1;
    color: $color-on-secondary;
    background-color: $color-secondary;
    width: auto;
}

.Box-1122-cancel {
    order:2;
    color: $color-on-primary;
    background-color: $color-primary;
    width: auto;
}

.Box-1123-deleteFinal {
    order:3;
    color: $color-on-surface;
    background-color: $color-surface;
    width: fit-content;
}


.viewCardPrice-Activated-mdAndUp {
    order: 2;

    min-height: 0%;
    min-width: 0%;
    width: 37%;
    height: fit-content;
    border-radius: 15%;
    margin-right: 2% !important;
    margin-left: auto;
    margin-top: 22%;
    background: $color-secondary;
    color: $color-on-secondary!important;
    background-blend-mode: darken;
    opacity: 90%;
    font-size: large;
    
    
}

.viewCardPrice-DeActivated-mdAndUp {
    order: 2;

    min-height: 0%;
    min-width: 0%;
    width: 37%;
    height: fit-content;
    border-radius: 15%;
    margin-right: 2% !important;
    margin-left: auto;
    margin-top: 22%;
    background: $color-info;
    color: $color-on-info;
    font-size: large;
}

.viewCardPrice-Activated-smAndDown {
    order: 2;
    width: fit-content;
    height: fit-content;
    border-radius: 15%;
    margin-left: 62%;
    margin-top: 22%;
    background: $color-secondary;
    color: $color-on-secondary;
    font-size: small;
}

.viewCardPrice-DeActivated-smAndDown {
    order: 2;
    width: fit-content;
    height: fit-content;
    border-radius: 15%;
    margin-left: 62%;
    margin-top: 22%;
    background: $color-secondary;
    color: $color-on-info;
    font-size: small;
}

.viewCardPrice-todelete-mdAndUp {
    order: 2;

    min-height: 0%;
    min-width: 0%;
    width: 37%;
    height: fit-content;
    border-radius: 15%;
    margin-right: 2% !important;
    margin-left: auto;
    margin-top: 22%;
    background: $color-primary;
    color: $color-on-primary;
    font-size: large;
}

.viewCardPrice-todelete-smAndDown {
    order: 2;
    width: fit-content;
    height: fit-content;
    border-radius: 15%;
    margin-left: 62%;
    margin-top: 22%;
    background: $color-primary;
    color: $color-on-primary;
    font-size: small;
}

.viewCardPrice-mdAndUp {
    order: 2;

    min-height: 0%;
    min-width: 0%;
    width: 37%;
    height: fit-content;
    border-radius: 15%;
    margin-right: 2% !important;
    margin-left: auto;
    margin-top: 20%;
    background: $color-secondary;
    color: $color-on-secondary;
    font-size: large;
}
.viewCardPrice-smAndDown {
    order: 2;
    
    min-height: 0%;
    min-width: 0%;
    width: 37%;
    height: fit-content;
    border-radius: 15%;
    margin-right: 2% !important;
    margin-left: auto;
    margin-top: 20%;
    background: $color-secondary;
    color: $color-on-secondary;
    font-size: small;
}


.viewCardPrice3-mdAndUp {
    order: 2;

    min-height: 0%;
    min-width: 0%;
    width: 37%;
    height: fit-content;
    border-radius: 15%;
    margin-right: 2% !important;
    margin-left: auto;
    margin-top: 10%;
    background: $color-secondary;
    color: $color-on-secondary;
    font-size: large;
}
.viewCardPrice3-smAndDown {
    order: 2;
    
    min-height: 0%;
    min-width: 0%;
    width: 37%;
    height: fit-content;
    border-radius: 15%;
    margin-right: 2% !important;
    margin-left: auto;
    margin-top: 10%;
    background: $color-secondary;
    color: $color-on-secondary;
    font-size: small;
}


.viewCardTotalPrice-mdAndUp {
    order: 3;

    min-height: 0%;
    min-width: 0%;
    width: 37%;
    height: fit-content;
    border-radius: 15%;
    margin-right: 2% !important;
    margin-left: auto;
    margin-top: 2%;
    background: $color-info;
    color: $color-on-info;
    font-size: large;
}
.viewCardTotalPrice-smAndDown {
    order: 3;
    
    min-height: 0%;
    min-width: 0%;
    width: 37%;
    height: fit-content;
    border-radius: 15%;
    margin-right: 2% !important;
    margin-left: auto;
    margin-top: 30%;
    background: $color-secondary;
    color: $color-on-secondary;
    font-size: small;
}
</style>