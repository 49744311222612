/**
 * @brief: base Menu Item 's card view data class
 * @details: this class describe the features all menu item's card views must have
 * This file make use of composition api for code reusability and modularity
 * (through which also a kind of extension will be implemented when reusing this in other files)
 * NB: the references allows double way binding of data and thsis composition api based pattern
 * reinforce the fact that each instantiation of this class will be independent from the others
 */

import { ref } from "vue"; //computed

import jCardViewData from "@/../viewmodel/jCardViewData";
import {
  enViewItemType_t,
  enMenuCategory_t,
} from "@/../Common/commontypes/enums/index";

// will return separate instance of variables for each call
export default function jMenuItemCardViewData(
  i_uMenuItemId = 0,
  i_eViewItemType0 = enViewItemType_t.eViewItemType_Unknown,
  i_strLabel = "",
  uCarouselPosition = 0,
  i_eMenuCategory = enMenuCategory_t.eMenuCategory_Unknown,
  i_strImageSourcePath = "",
  i_strLocalization = "",
  i_uUnitPrice = 0,
  i_uRestaurantId = "", //TODO: put this in second argument position after restaurant ids have been introduced prio 1
) {
  // simulate inheritance while using composition app by this call which instantiate base class
  const {
    m_uMenuItemId,
    m_eCarouselItemType,
    m_strMenuItemName,
    m_uCarouselPosition,
    m_eProductCategory,
    m_strMenuItemImage,
  } = jCardViewData(
    i_uMenuItemId,
    i_eViewItemType0,
    i_strLabel,
    uCarouselPosition,
    i_eMenuCategory,
    i_strImageSourcePath
  );
  const m_uRestaurantId = ref(i_uRestaurantId);
  // init member data with initial and/or default values
  const m_strLocalization = ref(i_strLocalization);

  const m_uUnitPrice = ref(i_uUnitPrice);

  return {
    m_uMenuItemId,
    m_eCarouselItemType,
    m_strMenuItemName,
    m_uCarouselPosition,
    m_eProductCategory,
    m_strMenuItemImage,
    m_strLocalization,
    m_uUnitPrice,
    m_uRestaurantId,  //TODO: put this in second argument position after restaurant ids have been introduced prio 1
  };
}
