const enRestaurantCategory_t = Object.freeze({
  eRestaurantCategory_Unknown: 0,
  eRestaurantCategory_Cameroonian: 1,
  eRestaurantCategory_Senegalese: 2,
  eRestaurantCategory_Ethiopian: 3,
  eRestaurantCategory_Chinese: 4,
  eStoryType_Supermarket: 5,
  eRestaurantCategory_Turc: 6,
  eRestaurantCategory_German: 7,
  eRestaurantCategory_Invalid: 255,
});
export default enRestaurantCategory_t;
