const enRequestParams_t = Object.freeze({
  eRequestParams_Request: 0,
  eRequestParams_MenuItemImage: 1,
  eRequestParams_MenuItemId: 2,
});


/// @brief: Define the maximum orderStatus constant
const SOFTCONFIG_DEV_REQUESTPARAMS_MAX = enRequestParams_t.eRequestParams_MenuItemId;
/// @brief: Define the minimum orderStatus constant
const SOFTCONFIG_DEV_REQUESTPARAMS_MIN = enRequestParams_t.eRequestParams_Request;

const REQUEST_PARAMS = Object.freeze([
    'm_Request',
    'm_strMenuItemImage',
    'm_uMenuItemId',
  ]);


  /**
 * @brief Convert the enum enItemStatus to string,
 * The string returned is based on the value of the i_eRequestParam defined.
 * @param {enRequestParams_t} i_eRequestParam -> The ItemStatus to be converted to string
 * @returns {String}
 */
const toString = (i_eRequestParam) => {
    let strRequestParam;
    if (
      i_eRequestParam < SOFTCONFIG_DEV_REQUESTPARAMS_MIN ||
      i_eRequestParam > SOFTCONFIG_DEV_REQUESTPARAMS_MAX
    ) {
      strRequestParam = REQUEST_PARAMS[REQUEST_PARAMS.length - 1];
    } else {
      strRequestParam = REQUEST_PARAMS[i_eRequestParam];
    }
    return strRequestParam;
  };
  
  /**
   * @brief Convert string to the proper enum enItemStatus if applicable,
   * The enum returned is based on the value of the string provided.
   * @param {String} i_strRequestParam -> The ItemStatus to be converted to i_eRequestParam
   * @returns {enRequestParams_t}
   */
  const fromString = (i_strRequestParam) => {
    let eResult = null;
    let enumValues = Object.values(enRequestParams_t);
    let enumKeys = Object.keys(enRequestParams_t);
    let index = REQUEST_PARAMS.indexOf(i_strRequestParam);
    if (
      -1 < index &&
      enumValues.includes(index + 1) &&
      SOFTCONFIG_DEV_REQUESTPARAMS_MIN <= index + 1 &&
      SOFTCONFIG_DEV_REQUESTPARAMS_MAX >= index + 1
    ) {
      eResult = enumKeys[index];
    }
  
    return eResult;
  };
  
  /**
   * @brief Convert string to the proper enum enItemStatus if applicable,
   * The enum returned is based on the value of the string provided.
   * @param {number} i_uItemStatus -> The ItemStatus to be converted to i_eRequestParam
   * @returns {i_eRequestParam}
   */
  const fromNumber = (i_uItemStatus) => {
    let eResult = null;
    if (
      SOFTCONFIG_DEV_REQUESTPARAMS_MIN <= i_uItemStatus &&
      SOFTCONFIG_DEV_REQUESTPARAMS_MAX >= i_uItemStatus
    ) {
      let enumValues = Object.values(enRequestParams_t);
      let enumKeys = Object.keys(enRequestParams_t);
      let index = enumValues.indexOf(i_uItemStatus);
      if (-1 < index) {
        eResult = enumKeys[index];
      }
    }
    return eResult;
  };
  
  export {
    enRequestParams_t,
    REQUEST_PARAMS,
    SOFTCONFIG_DEV_REQUESTPARAMS_MAX,
    SOFTCONFIG_DEV_REQUESTPARAMS_MIN,
    toString,
    fromString,
    fromNumber,
  };
  