/** * * @brief: single card view rendering on the restaurants' screen * @details: * this
is a modular reusable implementation of each * shopping item (carousel element)
* on the landing page */
<template  >
  <!-- main card view shape and ids for styling-. "contain" key word IS QUITE IMPORTANT -->

  <v-card :class="{ 'rounded-card-smAndDown': m_bIsMobile, 'rounded-card-mdAndUp': !m_bIsMobile }" elevation="10" fluid>
    <!-- self loading dynamic card view header filled with fetched image and a label -->
    <DynamicMenuItemImage class="Img" :m_bIsMobile="m_bIsMobile" :m_bDisplayAttributes=m_bDisplayAttributes
    :m_oMenuItemData="m_oMenuItemData" m_eActionModes="2"
      @[userChangeEventListener]="onCustomAppUserEvent" />
    <span class="Container-1">
      <span :class="{
        'carViewTitle-smAndDown': m_bIsMobile,
        'carViewTitle-mdAndUp': !m_bIsMobile
      }">
        <!-- <h5 v-if="m_bIsMobile">{{ m_oMenuItemData.m_strMenuItemName }}</h5>
        <h3 v-else>{{ m_oMenuItemData.m_strMenuItemName }}</h3> -->
        <input v-model.number.lazy="m_strMenuItemName" type="text" placeholder="Nbr. Min">
        <!-- <v-text-field  label="Nom du Resto"
          v-model="m_strMenuItemName" :rules="onChangedNameRules" /> -->
      </span>
      <!-- days as buttons for selection of the delivery days -->
      <span class="container-12">
        <v-btn v-for="(button, index) in m_lstDaysButtons" :key="index"
          :active-class="button.m_bIsActive ? 'active' : 'no-active'" :class="{
            'CardViewDayBtn-Active-smAndDown': m_bIsMobile && button.m_bIsActive,
            'CardViewDayBtn-Active-mdAndUp': !m_bIsMobile && button.m_bIsActive,
            'CardViewDayBtn-InActive-smAndDown': m_bIsMobile && !button.m_bIsActive,
            'CardViewDayBtn-InActive-mdAndUp': !m_bIsMobile && !button.m_bIsActive
          }" outlined @click="onChangedDaySelections(button)">
          {{ button.m_strDayLabel }}
        </v-btn>
      </span>
      <!-- Display of the total price and the call-to-action button -->
      <!-- no v-card-actions, becaus eof the default space its use -->
      <span class="container-13">
        <span :class="{ 'Box-131-smAndDown': m_bIsMobile, 'Box-131-mdAndUp': !m_bIsMobile }">

          <body>Max</body>
        </span>
        <input v-model.number.lazy="m_uMaxNbrofunits" type="text" placeholder="Nbr. Max"
          :class="{ 'Box-132-smAndDown': m_bIsMobile, 'Box-132-mdAndUp': !m_bIsMobile }">
        <span :class="{ 'Box-133-smAndDown': m_bIsMobile, 'Box-133-mdAndUp': !m_bIsMobile }">

          <body>Min</body>
        </span>
        <input v-model.number.lazy="m_uMinNbrofUnits" type="text" placeholder="Nbr. Min"
          :class="{ 'Box-134-smAndDown': m_bIsMobile, 'Box-134-mdAndUp': !m_bIsMobile }">
      </span>
      <span class="container-14">
        <v-file-input :class="{ 'Box-141-smAndDown': m_bIsMobile, 'Box-141-mdAndUp': !m_bIsMobile }"
          :label="m_strMenuItemImage" accept="image/*" prepend-icon="mdi-camera" @change="onImgFileChanged($event)"
          dense />
        <v-combobox :class="{ 'Box-142-smAndDown': m_bIsMobile, 'Box-142-mdAndUp': !m_bIsMobile }"
          :items="m_lstCategories" :update:search-input="onCategorySelected" v-model="m_strProductCategory"
          :label="m_strProductCategory" @change="onCategorySelected" return-object dense contain></v-combobox>
      </span>
    </span>
  </v-card>
</template>

<script>
import { ref, watch, onMounted } from "vue";

import SoftDevConfigs from "../../configurations/Configs.js";
import {toString as getWeekDayName, SOFTCONFIG_DEV_WEEKDAYS_SHORT_LENGTH, 
  SOFTCONFIG_DEV_WEEKDAYS_MAX, SOFTCONFIG_DEV_WEEKDAYS_MIN} from "@/../Common/commontypes/enums/enWeekDays_t";
import { SOFTCONFIG_DEV_MENUCATEGORY_MIN, SOFTCONFIG_DEV_MENUCATEGORY_MAX,
  toString as getMenuCategoryName}//TODO: there should be a single way of importing public items from enums module
from "@/../Common/commontypes/enums/enMenuCategory_t";
import DynamicMenuItemImage from "../../src/components/DynamicMenuItemImage.vue"

export default {
  name: "RestaurantsScreenCardView",
  components: {
    DynamicMenuItemImage
  },

  props: {
    /**
     * @brief: property for the component to know weither the device is a mobile
     */
    m_bIsMobile: {
      type: Boolean,
      required: true,
    },
    /**
      * @brief: property for the component to know attributes necessary for responsiveness
      */
      m_bDisplayAttributes: {
      required: true,
    },

    m_oMenuItemData: {
      required: true
    }
  },

  
   /**
    * @brief: explicite declaration of events for proper documentation and visibility 
    * (and some maybe unknown reasons)
    */
    emits: [ SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent],



  //Use setup option as the entry point to the Composition API
  setup(props, {emit}) {
    /**
     * @brief: text field rule set, to make sure that certain names are not
     *  empty nor too short and show appropriate messages
     */
     let onChangedNameRules= [
      v => !!v || "Name is required",
      v => (v && v.length > 2) || "Name must be more than 2 characters"
    ];

    /**
     * @brief: list of buttons representing days that can be selected
     * @details: the starting states of the buttons are initialized with
     * the same value as within m_oMenuItemData.m_lstDays, knowing that
     * the first weekday enumeration constant is unknown and therewith the position
     * 0 here must be undefined.
     */
    var m_lstDaysButtons = new ref([]);
    for (let i = SOFTCONFIG_DEV_WEEKDAYS_MIN;
         (SOFTCONFIG_DEV_WEEKDAYS_MAX < props.m_oMenuItemData.m_lstDays.length) 
         && (i <= SOFTCONFIG_DEV_WEEKDAYS_MAX);  ++i) 
    {
      // copy current opening day inout state or initialize with true
      m_lstDaysButtons.value.push({
        m_eDay: i,
        m_strDayLabel: getWeekDayName(i).slice(0, SOFTCONFIG_DEV_WEEKDAYS_SHORT_LENGTH),
        m_bIsActive: props.m_oMenuItemData.m_lstDays[i],
      });
    }
    /**
     * @brief dynamic view binding of data to visible dom element
     * @details this is necessary to not copy the complete input structure snce props cannot be modified
     * and thereby cannot be used in double way binding.
     * //TODO: is this herwith kind of te view model for this card view?
     *  */ 
    var m_strMenuItemName = new ref(props.m_oMenuItemData.m_strMenuItemName);
    var m_uMaxNbrofunits = new ref(props.m_oMenuItemData.m_uMaxNbrofunits);
    var m_uMinNbrofUnits = new ref(props.m_oMenuItemData.m_uMinNbrofUnits);
    var m_strMenuItemImage = new ref(props.m_oMenuItemData.m_strMenuItemImage);
    /// @brief: holder for binding the text view of the menu item(being created) category
    var m_strProductCategory = new ref(getMenuCategoryName(props.m_oMenuItemData.m_eProductCategory));
    /**
     * @brief: list of buttons representing days that can be selected
     */
     var m_lstCategories = new ref([]);
    for (let i = SOFTCONFIG_DEV_MENUCATEGORY_MIN; i <= SOFTCONFIG_DEV_MENUCATEGORY_MAX; ++i) {
      m_lstCategories.value.push({
        title: getMenuCategoryName(i),
      });
    }
    
    

     /**
     * @brief: emit menu item data to restaurant's screen view model 
     * @details: this will trigger the item to be sent via signal emission to restaurants screen
     * main parent view model.
     * 
     */ 
    function emitData(){
      emit(SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent, {
        m_strUsecase: SoftDevConfigs().CST_EVENT_NAMES.m_strUpdateRestaurantItem,
        m_uMenuItemId: props.m_oMenuItemData.m_uMenuItemId,
        m_uRestaurantId: props.m_oMenuItemData.m_uRestaurantId,
        m_strMenuItemName: m_strMenuItemName.value,
        m_uMaxNbrofunits: m_uMaxNbrofunits.value,
        m_uMinNbrofUnits: m_uMinNbrofUnits.value,
        m_strMenuItemImage: m_strMenuItemImage,
        m_strProductCategory: m_strProductCategory.value,
      });
    }

    /**
     * @brief: update restaurant's screen view model when menu item configuration changes
     * @details: this will trigger the item to be sent via signal emission to restaurants screen
     * main parent view model.
     * 
     * 
     */
     function onChangedDaySelections( i_obutton) {
      console.log(props.m_oMenuItemData)
      // `event` is the native DOM event
      if (i_obutton ) {
        i_obutton.m_bIsActive = !i_obutton.m_bIsActive; // switch button stat
        emit(SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent, {
        m_strUsecase: SoftDevConfigs().CST_EVENT_NAMES.m_strUpdateRestaurantItem,
        m_lstChangedDay: i_obutton.m_eDay,//TODO: use enumeration type
        m_uMenuItemId: props.m_oMenuItemData.m_uMenuItemId,
        m_uRestaurantId: props.m_oMenuItemData.m_uRestaurantId
      });
      }
      else {
        console.log("RestaurantsScreenCardView::onChangedDaySelections: Warning: was called without valid event")
      }
    }


    /**
     * @brief: event listeners for any card view's event signaling a change
     */
     const userChangeEventListener = SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent;
     /**
    * @brief: call back used by the listener for any card view's event signaling a change
    * @details: 
    * - called when the child dynamic image has emit an item state modification request
    */
    function onCustomAppUserEvent(i_oParams) {
      if (SoftDevConfigs().CST_EVENT_NAMES.m_strRemoveItem == i_oParams.m_strUsecase){
        
        if ((undefined != i_oParams.m_eNewStatus) || (undefined != i_oParams.m_uRestaurantId)
          || (undefined != i_oParams.m_uMenuItemId)) {
          // Bubble event with arguments 
          emit(SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent, i_oParams);
        } else {
          console.log("RestaurantsScreenCardView::onCustomAppUserEvent: CRITICAL has none of expected attributes");
          console.log(i_oParams);
        }
      } else  if (SoftDevConfigs().CST_EVENT_NAMES.m_strUpdateRestaurantItem == i_oParams.m_strUsecase) {
        console.log("RestaurantsScreenCardView::onCustomAppUserEvent: updating price");//TODO: delete this
        emit(SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent, i_oParams);
      } else {
        console.log("RestaurantsScreenCardView::onCustomAppUserEvent: CRITICAL UNKNOWN USECASE");
      }
    }
    
    
        /**
     * @brief: callback called each time the image input field is updated.
     * @param {*} e
     */
     function onImgFileChanged(e) {
      m_strMenuItemImage.value = e.target.files[0];
    }
    
    /**
     * @brief: callback called when a menu item category is selected to update the menu item attributes
     * and the text view of the category name
     * @param {*} i_uIndex 
     */
    function onCategorySelected() {
      // console.log(m_strProductCategory);
      // if (0<=i_uIndex){
      //   m_oNewMenuItem.value.m_eProductCategory = i_uIndex+1;//TODO: implement a real enumeration system to improve this
      //   // console.log(m_oNewMenuItem);
      //   m_strProductCategory.value = MENU_CATEGORIES[m_oNewMenuItem.value.m_eProductCategory];
        
      // }
    }
    onMounted(() => {

      /* watch for changes of these values */
      watch([m_strMenuItemName, m_uMaxNbrofunits, m_uMinNbrofUnits, m_strMenuItemImage, m_strProductCategory], () => {
        emitData();
      }, { deep: true })//TODO: deep watch necessary ???? prio 3
    });

    



    return {m_strMenuItemName, m_uMaxNbrofunits, m_uMinNbrofUnits, 
            m_lstDaysButtons, m_strMenuItemImage,m_strProductCategory,m_lstCategories,
            onChangedDaySelections, onCustomAppUserEvent, onImgFileChanged,onCategorySelected,
            userChangeEventListener, onChangedNameRules};
  }
  // computed: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss"; // $text-primary would be defined in that file

.rounded-card-mdAndUp {
  // outline-width: 5px;
  border-radius: 5%;
  border-color: $color-secondary;
  // padding-left: 0%;
  // padding-right: 0%;
  // min-width: 0%;
  // min-height: 0%;
  width: 250px;
  height: 350px;
  font-family: "Rockwell", sans-serif;
  color: $color-on-surface;
  border: $color-secondary;
  border-style: none;
  box-shadow: $color-secondary;

  // margin-right: 0%;
  // margin-left: 0%;;
}

.rounded-card-smAndDown {

  outline-width: 5px;
  border-radius: 5%;
  border-color: $color-secondary;
  // padding-left: 0%;
  // padding-right: 0%;
  // min-width: 0%;
  // min-height: 0%;
  width: 184px;
  height: 175px;
  font-family: "Rockwell", sans-serif;
  color: $color-on-surface;
  border: $color-secondary;
  // border-style: dashed;
  box-shadow: $color-secondary;

  // margin-right: 0%;
  // margin-left: 0%;;
}

.Img {

  // min-width: 0%;
  // min-height: 0%;
  // height: 50px !important;
  // width: 5%!important;
  max-width: 100% !important;
  max-height: 40% !important;
}

.viewCardPrice-mdAndUp {

  width: fit-content;
  height: fit-content;
  border-radius: 15%;
  margin-left: 65%;
  margin-top: 30%;
  background: $color-secondary;
  color: #fff;
  font-size: large;
}
.viewCardPrice-smAndDown {

  width: fit-content;
  height: fit-content;
  border-radius: 15%;
  margin-left: 60%;
  margin-top: 35%;
  background: $color-secondary;
  color: #fff;
  font-size: small;
}
.Container-1 {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
  justify-content: space-around;
  align-items: flex-start;
  align-content: flex-start;
  // border-style: dotted;
  // border-color: chartreuse;
}
.carViewTitle-smAndDown {
  order: 1;
  margin-top: 0%;
  margin-left: 0%;
  // border-style: dashed;
}
.carViewTitle-mdAndUp {
  order: 1;
  margin-top: 0%;
  margin-left: 3%;
  align-self: center;
}
h3 {
  color: $color-accent;
  font-size: large;
}
h5 {
  color: $color-accent;
  font-size: x-small;
}
.container-12 {
  order: 2;
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content:flex-start ;
  justify-items: flex-start;
  justify-content: space-between;
  // min-height: 0%;
  // min-width: 0%;
  width: 100%;
  height: 30%;
  margin-top: 0%;
  // margin-left: 3%;
  margin-bottom: 0%;
  // border-style: dotted;
  // border-color: chocolate;
}

.CardViewDayBtn-Active-mdAndUp {
  min-width: 0%;
  min-height: 0%;

  width: 10%;
  height: 50%;
  margin-top: 0%;
  margin-right: 2%;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 1%;
  padding-bottom: 1%;

  border: thin solid;
  border-style: none;
  border-color: $color-surface;
  color: $color-surface;
  background-color: $color-secondary;
  font-size: xx-small;
}
.CardViewDayBtn-Active-smAndDown {
  min-width: 0%;
  min-height: 0%;

  width: 3%;
  height: 50%;
  margin-top: 0%;
  margin-right: 1%;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 1%;
  padding-bottom: 1%;

  border: thin solid;
  border-style: none;
  border-color: $color-surface;
  color: $color-surface;
  background-color: $color-secondary;
  font-size: xx-small;
}
.CardViewDayBtn-InActive-mdAndUp {
  min-width: 0%;
  min-height: 0%;

  width: 7%;
  height: 50%;
  margin-top: 0.5%;
  margin-right: 0.5%;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 1%;
  padding-bottom: 1%;

  border: thin solid;
  border-style: dashed;
  color: $color-secondary;
  border-color: $color-secondary;
  background-color: $color-surface;
  font-size: xx-small;
}
.CardViewDayBtn-InActive-smAndDown {
  min-width: 0%;
  min-height: 0%;

  // width: 19%;
  height: 50%;
  margin-top: 1%;
  margin-right: 2.5%;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 1%;
  padding-bottom: 1%;

  border: thin solid;
  border-style: dashed;
  color: $color-secondary;
  border-color: $color-secondary;
  background-color: $color-surface;
  font-size: xx-small;
}

.container-13 {
  order: 3;
  display: flex;
  flex-direction: row;
  min-height: 0%;
  min-width: 0%;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: space-evenly;
  // align-content: space-between;
  // justify-items: ;
  margin-top: 1%;
}
.Box-131-mdAndUp {
  order: 1;

}
.Box-131-smAndDown {
  order: 1;
}
.Box-132-mdAndUp {
  order: 2;
  min-width: 0%;
  min-height: 0%;
  width: 20%;
  height: auto;
  margin-top: 0%;
  margin-left: 3%;
  font-size: small;
  overflow: hidden;
}
.Box-132-smAndDown {
  order: 2;
}
.Box-133-mdAndUp {
  order: 3;

}
.Box-133-smAndDown {
  order: 3;

}

.Box-134-mdAndUp {
  order: 4;
  min-width: 0%;
  min-height: 0%;
  width: 20%;
  height: auto;
  margin-top: 0%;
  margin-left: 3%;
  font-size: small;
}
.Box-134-smAndDown {
  order: 4;

}


.container-14 {
  order: 4;
  display: flex;
  flex-direction: column;
  width: 100%;
  // min-height: 0%;
  // height: auto;
  align-items: center;
  // justify-content: space-evenly;
  // align-content: space-between;
  // justify-items: ;
  margin-top: 0%;
  // border-style: groove;
}
.Box-141-mdAndUp {
  order: 1;

  margin-left: 0%;
  margin-bottom: 0%;
  min-width: 0%;
  min-height: 0%;
  width: 90%;
  // height: 5%!important;
  margin-top: 0%;
  // margin-right: 2%;
  font-size: xx-small;
  // border-style: dashed;
}
.Box-141-smAndDown {
  order: 1;
  margin-left: 0%;
  margin-top: 0%;
  font-size:xx-small;
  min-width: 0%;
  min-height: 0%!important;
  width: 45%;
  height: 30%!important;
}
.Box-142-mdAndUp {
  order: 2;
  margin-left: 0%;
  min-width: 0%;
  min-height: 0%;
  width: 90%;
  // height: 5%!important;
  margin-top: 0%;
  // margin-right: 2%;
  font-size: small;
  // border-style: dashed;
}
.Box-142-smAndDown {
  order: 2;
  margin-left: 0%;
  margin-top: 0%;
  font-size: small;
  min-width: 0%;
  min-height: 0%;
  width: 45%;
  height: auto;
  margin-top: 1%;
}

</style>