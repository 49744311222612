import SoftDevConfigs from "../../../configurations/Configs.js";
import {toString as toRequestName} from "/Common/commontypes/enums/enHttpRequestNames_t.js";
import {enRequestNames_t} from "/Common/commontypes/enums/enHttpRequestNames_t.js";
import {toString as toRequestParam} from "/Common/commontypes/enums/enHttpRequestParams_t"
import {enRequestParams_t} from "/Common/commontypes/enums/enHttpRequestParams_t.js";



/**
 * @brief: A handler class to handle asynchronous http communications
 */
export default class jHttpHandler {

  
  /**
   * @brief get request url to be used with get request, 
   * @detail: for every attribute and value in i_oData, add the string formed 
   * by attr=value to server url
   * @param {*} i_oData: The parameters that will be used to build the request URL 
   * @returns URL object containing request url
   */
  static getUrl(i_oData = {}){
    let strGetURL = new URL(SoftDevConfigs().CST_HTTP_SERVER.m_strHost);

    //the part of url containing data must be like "?param1=value1&param2=value2&.....&param_n=valuen"
    //the right way to add search params
    strGetURL.search = new URLSearchParams(i_oData).toString();

    return strGetURL
  }


  /**
   * @brief Get data from a specific URL, this is done using the GET method,
   * @param {String} url -> The URL where the requests will be sent
   * @param {Object} i_oData -> The parameters that will be used to build the request URL
   * @returns {Promise}
   */
  static async fetchData(url, i_oData) {
    let scriptUrl = new URL(url);
    scriptUrl.search = new URLSearchParams(i_oData).toString();
    return await fetch(scriptUrl);
  }

  /**
   * @brief: send a post request to a specific URL
   * @param {String} url -> The URL where the request will be submitted
   * @param {Object} i_oData -> Object data that will be sent in the POST requests
   * @returns {Promise}
   */
  static #doPost(url, i_oData) {
    let formData = new FormData();
    // let bHasImage = undefined != i_oData.m_strMenuItemImage;
    for (let attribute in i_oData) {
      formData.append(attribute, i_oData[attribute]);
    }
    //TODO: log onto after logging debug level strategy prio 6
    console.log(
      "jHttphandler::#sendPostrequest:: to " + url + "Data:=[" + formData + "]"
    );
    // prepare request option //TODO:: make this configurable. prio 10
    let oRequestOptions = {
      method: "POST",
      // headers: { "Content-Type": "application/json" },
      Accept: "*/*",
      mode: "cors", // no-cors,'cors', *cors, same-origin
      "User-Agent": "Aturdo Food",
      body: formData,
    };
    // if (bHasImage) {
    //   oRequestOptions["content-disposition"] = "attachment;filename="+i_oData.m_strMenuItemImage;
    // }
    return fetch(url, oRequestOptions);
  }

  /**
   * @brief: send a post request to a specific URL
   * @param {String} url -> The URL where the request will be submitted
   * @param {Object} i_oData -> Object data that will be sent in the POST requests
   * @returns {Promise}
   */
  static sendData(i_oData) {
    return jHttpHandler.#doPost(
      SoftDevConfigs().CST_HTTP_SERVER.m_strHost,
      i_oData
    );
  }

  /**
   * @brief: send a post request to a specific URL
   * @param {String} url -> The URL where the request will be submitted
   * @param {Object} i_oData -> Object data that will be sent in the POST requests
   * @returns {Promise}
   */
  static #doGet(url) {
    // prepare request option //TODO:: make this configurable. prio 10
    const oRequestOptions = {
      method: "GET",
      // headers: { "Content-Type": "application/json" },
      Accept: "*/*",
      mode: "cors", // no-cors,'cors', *cors, same-origin
      "User-Agent": "Aturdo Food",
    };
    return fetch(url, oRequestOptions);
  }

  /**
   * @brief: send a get request to fetch all restaurants from server
   * @returns {Promise}
   */
  static requestRestaurants() {
    //TODO: improve hard coded constants
    console.log("jHttpHandler::requestRestaurants!");

    let i_oData = {}
    i_oData[toRequestParam(enRequestParams_t.eRequestParams_Request)] = toRequestName(enRequestNames_t.eRequestNames_AllRestaurants);
   

    let strGetURL = jHttpHandler.getUrl(i_oData);
      //SoftDevConfigs().CST_HTTP_SERVER.m_strHost + "?m_Request=AllRestaurants";
    return jHttpHandler.#doGet(strGetURL);
  }

  /**
   * @brief: send a get request to fetch all menu items from server
   * @returns {Promise}
   */
   static requestMenuItems() {
    //TODO: improve hard coded constants prio 4
    console.log("jHttpHandler::requestMenuItems!");

    let i_oData = {}
    i_oData[toRequestParam(enRequestParams_t.eRequestParams_Request)] = toRequestName(enRequestNames_t.eRequestNames_AllMenuItems);

   
    let strGetURL = jHttpHandler.getUrl(i_oData);
      //SoftDevConfigs().CST_HTTP_SERVER.m_strHost + "?m_Request=AllMenuItems";
    return jHttpHandler.#doGet(strGetURL);
  }
  
  /**
   * @brief: send a get request to fetch all orders from server
   * @returns {Promise}
   */
   static requestOrders() {
    //TODO: improve hard coded constants prio 4
    console.log("jHttpHandler::requestOrders!");

    let i_oData = {}
    i_oData[toRequestParam(enRequestParams_t.eRequestParams_Request)] = toRequestName(enRequestNames_t.eRequestNames_AllOrders);

   
    let strGetURL = jHttpHandler.getUrl(i_oData);
      //SoftDevConfigs().CST_HTTP_SERVER.m_strHost + "?m_Request=AllOrders";
    return jHttpHandler.#doGet(strGetURL);
  }

  /**
   * @brief: send a get request to fetch an image from server
   * @details: a get request will be sent by building the string composing the
   * proper get url query params, which will be prepended with the target php file url
   * currently only the image name and the menu item name are used within the query
   * of request name MenuItemImg
   *
   * @param {String} i_oMenuItem -> The menu item which image must be fetched from the server database
   * @returns {Promise}
   * example:
   * http://localhost:3000/Model/ServerClient/Http/pHttpHandler.php?m_Request=MenuItemImg&m_strMenuItemImage=WhatsApp Image 2022-10-01 at 2.12.58 PM (2).jpeg&m_strMenuItemName=Plat 3
   */
  static requestImage(i_oMenuItem) {
    //TODO: improve hard coded constants prio 4
    
    let i_oData = {}
    i_oData[toRequestParam(enRequestParams_t.eRequestParams_Request)] = toRequestName(enRequestNames_t.eRequestNames_MenuItemImg);
    i_oData[toRequestParam(enRequestParams_t.eRequestParams_MenuItemImage)] =  i_oMenuItem.m_strMenuItemImage;
    i_oData[toRequestParam(enRequestParams_t.eRequestParams_MenuItemId)] =  i_oMenuItem.m_uMenuItemId;

    let strGetURL = jHttpHandler.getUrl(i_oData);
    //TODO: does the common card view name m_uMenuItemId prevail over the context MenuItemName? prio 7
        
      console.log(strGetURL)
    return jHttpHandler.#doGet(strGetURL);
  }
}
