/**
 * @brief: reusable codes for Async Task Utilities
 */
import { ref } from "vue";
import { enVariableConversionTypes_t } from "@/../Common/commontypes/enums/index";


//map for attributes and conversions types enums
const m_lstAttributesTypes = new Map([
  ["m_eProductCategory", enVariableConversionTypes_t.eVariableType_Int],
  ["m_uNbrofUnits", enVariableConversionTypes_t.eVariableType_Int],
  ["m_uMinNbrofUnits", enVariableConversionTypes_t.eVariableType_Int],
  ["m_uMaxNbrofunits", enVariableConversionTypes_t.eVariableType_Int],
  ["m_uUnitPrice", enVariableConversionTypes_t.eVariableType_Int],
  ["m_uTotalPrice", enVariableConversionTypes_t.eVariableType_Int],
  ["m_eItemStatus", enVariableConversionTypes_t.eVariableType_Int],
  ["m_lstDays", enVariableConversionTypes_t.eVariableType_Array],

]);

/*
const m_eProductCategory = "m_eProductCategory";
const m_uNbrofUnits = "m_uNbrofUnits";
const m_uMinNbrofUnits = "m_uMinNbrofUnits";
const m_uMaxNbrofunits = "m_uMaxNbrofunits";
const m_uUnitPrice = "m_uUnitPrice";
const m_uTotalPrice = "m_uTotalPrice";
const m_eItemStatus = "m_eItemStatus";
const m_lstDays = "m_lstDays";*/


/**
 * 
 * @breif convert attribute value to stpecific type according to corresponding conversion type 
 * in m_lstAttributesTypes
 * @details -find i_strAttribute type in m_lstAttributesTypes
 * - convert to conrresponding variable type
 * @param {*}  i_strAttribute , i_oValueToConvert
 * @returns convertedAttribute
 */
function convertCSVAttributes(i_strAttribute, i_oValueToConvert){
  let convertedAttribute = i_oValueToConvert;
  let enVariableAttributeType = m_lstAttributesTypes.get(i_strAttribute)

  if(enVariableAttributeType){
    switch(enVariableAttributeType){
      case enVariableConversionTypes_t.eVariableType_Int:{
        convertedAttribute = parseInt(i_oValueToConvert);
        //handle case where i_strAttribute is "m_eItemStatus" and i_oValueToConvert is undefined 
        if((i_strAttribute == "m_eItemStatus")&&(undefined == i_oValueToConvert)){
          convertedAttribute = 2;
        }
        break;
      }
      case enVariableConversionTypes_t.eVariableType_Array:{
        if ((undefined != i_oValueToConvert) && ('' != i_oValueToConvert)){
          // to days array conversion, TODO: use non hard coded constant for split character prio 4
          let temp = i_oValueToConvert.split(',');
          convertedAttribute = []
          temp.forEach((i_strDay) => {
            if (i_strDay.length)
            convertedAttribute.push( ('true' === i_strDay) ? true : false);
          } ); 
        }    
        break;
      }
      default: {
        convertedAttribute = i_oValueToConvert
      }
    }
  }

  return convertedAttribute;
}


/**
 * @brief: convert backend text based csv data into js array of objects
 * @details: the header is the first row of the input. its values contains
 * the attribute of each object represented by all other rows.
 * For the following enumeration, we directly transform the value into the
 * proper enumeration class type: enMenucategory_t
 *
 * @export
 * @param {*} i_nPrimaryKeys => number of first columns from the csv files,
 *  that represent the primarey keys. NB: the primary keys are
 * supposed to be the contiguous first column(s); hence they cannot be scattered.
 *
 * @param {*} i_lstCSVArray
 * @param {*} o_oMapReference
 * @return {*} a map associating either the first column out of the csv file if
 * it is the only primary key or a json stringified string generated out of the
 * [i_nPrimaryKeys] values extract at each row to all values of that row transform
 * as object using the values of the first row as object name
 * //TODO: should there be a separate method for each class instead returning the proper VM class?
 */
export function convertCSVtoMapRef(
  i_nPrimaryKeys,
  i_lstCSVArray,
  o_oMapReference
) {
  let bRes = true;
  if (1 >= i_lstCSVArray.length) {
    console.log(
      "csvFilehandling::convertCSVtoMapRef: i_lstCSVArray size " +
        i_lstCSVArray +
        " not enough!"
    );
    bRes = false;
  } else {
    for (let i = 1; i < i_lstCSVArray.length; ++i) {
      var oObject = {}; // initialize empty object for row i
      // for each column in the csv data set the corresponding value of the
      // current row using the attribute name on the same column  of the header row[0]
      for (let j = 0; j < i_lstCSVArray[0].length; ++j) {
        let convertedAttribute = convertCSVAttributes(i_lstCSVArray[0][j], i_lstCSVArray[i][j]);
        oObject[i_lstCSVArray[0][j]] = new ref(convertedAttribute);
      }
      // save object in output map using first column as key
      if (1 < i_nPrimaryKeys) {
        // generate global item  map key from list of primary keys, since map here are not multimap
        // and cannot have more than one value within the key, we need to serialize the key
        let strPKeys = JSON.stringify(
          i_lstCSVArray[i].slice(0, i_nPrimaryKeys)
        );//TODO: transform this into a number for numbers as ids/keys? prio 6
        // console.log("csvFilehandling::convertCSVtoMapRef: " + strPKeys);
        o_oMapReference.value.set(strPKeys, oObject);
      } else {
        o_oMapReference.value.set(i_lstCSVArray[i][0], oObject);
      }
    }
  }
  return bRes;
}