/**
 * @brief: "ShoppingCart Screen"'s card views data class
 * @details: this class describe the features all menu item's card views in the "shoppingcart screen" must have
 * This file make use of composition api for code reusability and modularity
 * (through which also a kind of extension will be implemented when reusing this in other files)
 * NB: the references allows double way binding of data and thsis composition api based pattern
 * reinforce the fact that each instantiation of this class will be independent from the others
 */

import { ref } from "vue"; //computed

import jHomeScreenCategoryItemData from "../HomeScreenVM/jHomeScreenCategoryItemData";
import {
  enViewItemType_t,
  enMenuCategory_t,
} from "@/../Common/commontypes/enums/index";

/**
 * @brief: constant initializing constructor
 * @details: this emulate an explicit constructor
 *
 * @export
 * @param {number} [i_uMenuItemId=0]
 * @param {*} [i_eViewItemType0=enViewItemType_t.eViewItemType_Unknown]
 * @param {string} [i_strLabel=""]
 * @param {number} [uCarouselPosition=0]
 * @param {*} [i_eMenuCategory=enMenuCategory_t.eMenuCategory_Unknown]
 * @param {string} [i_strImageSourcePath=""]
 * @param {string} [i_strLocalization=""]
 * @param {number} [i_uUnitPrice=0]
 * @param {number} [i_uNbrofUnits=0]
 * @param {number} [i_uTotalPrice=0]
 * @param {*} [i_lstDays=[]]
 * @param {string} [i_strDestination=""]
 * @param {string} [i_strDateTime="12h"]
 * @return {*} as jShoppingCartMenuItemData
 */
export default function jShoppingCartMenuItemData(
  i_strBasketItemId_as_DateTime,// this is a mandatory parameter for shopping cart screen //TODO: change name; remove as_DateTime when order id has been added prio 5
  i_uMenuItemId = 0,
  i_eViewItemType0 = enViewItemType_t.eViewItemType_Unknown,
  i_strLabel = "",
  uCarouselPosition = 0,
  i_eMenuCategory = enMenuCategory_t.eMenuCategory_Unknown,
  i_strImageSourcePath = "",
  i_strLocalization = "",
  i_uUnitPrice = 0,
  i_uNbrofUnits = 0,
  i_uTotalPrice = 0,
  i_lstDays = [],
  i_strDestination = "",
  i_strDateTime ="12h"
) {
  // simulate inheritance while using composition app by this call which instantiate base class
  const {
    m_uMenuItemId,
    m_eCarouselItemType,
    m_strMenuItemName,
    m_uCarouselPosition,
    m_eProductCategory,
    m_strMenuItemImage,
    m_strLocalization,
    m_uUnitPrice,
    m_uNbrofUnits,
    m_uTotalPrice,
    m_lstDays,
    m_strBasketItemId_as_DateTime
  } = jHomeScreenCategoryItemData(
    i_uMenuItemId,
    i_eViewItemType0,
    i_strLabel,
    uCarouselPosition,
    i_eMenuCategory,
    i_strImageSourcePath,
    i_strLocalization,
    i_uUnitPrice,
    i_uNbrofUnits,
    i_uTotalPrice,
    i_lstDays,
    i_strBasketItemId_as_DateTime,
  );
  
  const m_strDestination = ref(i_strDestination);

  const m_strDeliveryTime = ref(i_strDateTime);

  
  return {
    m_strBasketItemId_as_DateTime,
    m_uMenuItemId,
    m_eCarouselItemType,
    m_strMenuItemName,
    m_uCarouselPosition,
    m_eProductCategory,
    m_strMenuItemImage,
    m_strLocalization,
    m_uNbrofUnits,
    m_uUnitPrice,
    m_uTotalPrice,
    m_lstDays,
    m_strDestination,
    m_strDeliveryTime,
  };
}
