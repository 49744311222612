const enContactTypes_t = Object.freeze({
  eContact_Unknown: 0,
  eContact_CellPhone: 1,
  eContact_Phone: 2,
  eContact_Email: 3,
  eContact_Fax: 4,
  eContact_Invalid: 255,
});

export default enContactTypes_t;
