/** * @brief: single category block rendering on the home screen * @details:
this is a modular reusable implementation of each * menu item category block on
the landing page, made of title * subtitle and the carousel of menu items
https://github.com/luvejo/vue-3-carousel-tutorial*/
<template>
  <span :class="{
    'container-1-smAndDown': m_bIsMobile,
    'container-1-mdAndUp': !m_bIsMobile,
  }">
    <span span :class="{'Box-11-smAndDown': m_bIsMobile,'Box-11-mdAndUp': !m_bIsMobile }">
      <h1 v-if="m_bIsMobile">
        {{ m_oCategoryCarouselData.m_strTitle }}
      </h1>
      <h4 v-else>{{ m_oCategoryCarouselData.m_strTitle }}</h4>
    </span>
    <span class="Box-12">
      <body>
        {{ m_oCategoryCarouselData.m_strSubTitle }}
      </body>
    </span>
    <!-- main carousel module builder TODO: this caroussel should be in a box 13? prio 1 -->
    <span class="Box-13">
      <CustomCarousel :m_lstItems=m_oCategoryCarouselData.m_lstCategoryItems 
      :m_CardView=1 :m_bIsMobile=m_bIsMobile :m_bDisplayAttributes=m_bDisplayAttributes
      @[userChangeEventListener]="onCustomAppUserEvent"/>
    </span>
  </span>
</template>

<script>
// import { ref, onMounted, onUnmounted } from "vue";


import SoftDevConfigs from "../../configurations/Configs.js";
import CustomCarousel from "@/components/CustomCarousel.vue"


export default {
  name: "HomeScreenCategoryCarousel",
  components: {
    CustomCarousel,
  },

  props: {
    /**
     * @brief: property for the component to know weither the device is a mobile
     */
    m_bIsMobile: {
      type: Boolean,
      required: true,
    },
    /**
      * @brief: property for the component to know attributes necessary for responsiveness
      */
      m_bDisplayAttributes: {
      required: true,
    },

    m_oCategoryCarouselData : {
      required: true,
    },//: jHomeScreencategoryCarouselData,
  },

  
  setup(props, {emit}) { 
    
    /**
     * @brief: event listeners for any card view's event signaling a change
     */
     const userChangeEventListener = SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent;
    /**
     * @brief: call back used by the listener for any card view's event signaling a change
     * @details: used by userChangeEventListener when it receives a signaled event
     * The method actually just forward the emission to parent
    */
    function onCustomAppUserEvent(i_oParams) {
      // Bubble event with arguments 
      emit( SoftDevConfigs().CST_EVENT_NAMES.m_strUserEvent, i_oParams);
    }

    return {onCustomAppUserEvent, userChangeEventListener}  
  },


};
</script>

<!-- id selector -> class selector -> element selector -->
<style lang="scss" scoped>
@import "@/styles/variables.scss"; // $text-primary would be defined in that file

.container-1-mdAndUp {
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  align-content: flex-start;
  justify-items: flex-start;
  justify-content: flex-start;

  margin-bottom: 0%;
  // border-style: dotted;
  // border-color: brown;
}
.container-1-smAndDown {
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  align-content: flex-start;
  justify-items: flex-start;
  justify-content: flex-start;

  margin-bottom: 0%;
  // border-style: dotted;
  // border-color: brown;
}
.Box-11-mdAndUp {
  order: 1;
  margin-left: 3%;
  // border-style: dashed;
  font-family: "Rockwell", sans-serif;
  font-size: xx-large;
}
.Box-11-smAndDown {
  order: 1;
  margin-left: 3%;
  // border-style: dashed;
  font-family: "Rockwell", sans-serif;
  font-size: xx-small;
}

.Box-12 {
  order: 2;
  width: 95%;
  margin-top: 1%;
  margin-left: 3%;
  font-family: "Rockwell", sans-serif;
  // font-size: xx-small;
  // border-style: dashed;
  // border-color: aqua;
}
.Box-13 {
  order: 3;//TODO: maybe the ordering of this containe rmust be removed to be handled by including parent  span-box?
  width: 100%!important;
  height: 100%;

  margin-top: 1%;
  margin-left: 0%;
}
</style>
