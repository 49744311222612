/**
 * @brief: reusable codes for Async Task Utilities
 */
// import { ref } from "vue";

export default function isPromise(p) {
  if (
    p !== null &&
    typeof p === "object" &&
    typeof p.then === "function" &&
    typeof p.catch === "function"
  ) {
    return true;
  }

  return false;
}

// export default {
//   isPromise,
// };
