/**
 * @brief: enumeration of the possible category of menu items(meals)
 * and menus(group of meals)
 * @details: this is not really an enumeratio as it is not yet
 * supported by js, but it may be improved in the future
 */
const enMenuCategory_t = Object.freeze({
  eMenuCategory_Unknown: 0,
  eMenuCategory_Breakfast: 1,
  eMenuCategory_Starter: 2,
  eMenuCategory_Soup: 3,
  eMenuCategory_Salad: 4,
  eMenuCategory_HorsDoeuvre: 5,
  eMenuCategory_MainCourse: 6,
  eMenuCategory_Burgers: 7,
  eMenuCategory_Pizza: 8,
  eMenuCategory_SideDish: 9,
  eMenuCategory_Appetizer: 10,
  eMenuCategory_Dessert: 11,
  eMenuCategory_Drink: 12,
  eMenuCategory_Others: 13,
  eMenuCategory_Invalid: 255,
});

/// @brief: Define the maximum MenuCategory constant
const SOFTCONFIG_DEV_MENUCATEGORY_MAX = enMenuCategory_t.eMenuCategory_Others;
/// @brief: Define the minimum MenuCategory constant
const SOFTCONFIG_DEV_MENUCATEGORY_MIN = enMenuCategory_t.eMenuCategory_Breakfast;

// const MENU_CATEGORIES = Object.freeze([
//   "Unknown",
//   "Starter",
//   "Soup",
//   "Salad",
//   "HorsDoeuvre",
//   "MainCourse",
//   "AfricanMeals",
//   "OtherMainCourse",
//   "SideDish",
//   "FastFood",
//   "Appetizer",
//   "Dessert",
//   "Drink",
//   "Others",
//   "Invalid",
// ]);
const MENU_CATEGORIES = Object.freeze([
  "Inconnue",
  "Petit déjeuner",
  "Entrées",
  "Soupes",
  "Salades",
  "Hors-d'œuvres",
  "Plat de Resistance",
  "Burgers",
  "Pizzas",
  "Plat d'accompagnement",
  "Apéritif",
  "Dessert",
  "Boissons",
  "Autres",
  "Non valide",
]);

/**
 * @brief Convert the enum enMenuCategory to string,
 * The string returned is based on the value of the enMenuCategory_t defined.
 * @param {enMenuCategory_t} i_eMenuCategory -> The MenuCategory to be converted to string
 * @returns {String}
 */
const toString = (i_eMenuCategory) => {
  let strMenuCategory;
  if (
    i_eMenuCategory < SOFTCONFIG_DEV_MENUCATEGORY_MIN ||
    i_eMenuCategory > SOFTCONFIG_DEV_MENUCATEGORY_MAX
  ) {
    strMenuCategory = MENU_CATEGORIES[MENU_CATEGORIES.length - 1];
  } else {
    strMenuCategory = MENU_CATEGORIES[i_eMenuCategory];
  }
  return strMenuCategory;
};

/**
 * @brief Convert string to the proper enum enMenuCategory if applicable,
 * The enum returned is based on the value of the string provided.
 * @param {String} i_strMenuCategory -> The MenuCategory to be converted to enMenuCategory_t
 * @returns {enMenuCategory_t}
 */
const fromString = (i_strMenuCategory) => {
  let eResult = enMenuCategory_t.eMenuCategory_Invalid;
  let enumValues = Object.values(enMenuCategory_t);
  let enumKeys = Object.keys(enMenuCategory_t);
  let index = MENU_CATEGORIES.indexOf(i_strMenuCategory);
  if (
    -1 < index &&
    enumValues.includes(index) &&
    SOFTCONFIG_DEV_MENUCATEGORY_MIN <= index &&
    SOFTCONFIG_DEV_MENUCATEGORY_MAX >= index
  ) {
    eResult = enMenuCategory_t[enumKeys[index]];
  } else {
    eResult = enMenuCategory_t.eMenuCategory_Invalid;
  }

  return eResult;
};
/**
 * @brief Convert string to the proper enum enMenuCategory if applicable,
 * The enum returned is based on the value of the string provided.
 * @param {number} i_uMenuCategory -> The MenuCategory to be converted to enMenuCategory_t
 * @returns {enMenuCategory_t}
 */
const fromNumber = (i_uMenuCategory) => {
  // let eResult = enMenuCategory_t.eMenuCategory_Invalid;
  // if (
  //   SOFTCONFIG_DEV_MENUCATEGORY_MIN <= i_uMenuCategory &&
  //   SOFTCONFIG_DEV_MENUCATEGORY_MAX >= i_uMenuCategory
  // ) {
  //   let enumValues = Object.values(enMenuCategory_t);
  //   let enumKeys = Object.keys(enMenuCategory_t);
  //   let index = enumValues.indexOf(i_uMenuCategory);
  //   if (0 <= index) {
  //     eResult = enMenuCategory_t[enumKeys[index]];
  //   }
  // }
  return i_uMenuCategory;
};

/// @brief List of menu categories sub titles
const MENU_CATEGORIES_SUBTITLES = Object.freeze([
  "Inconnue", // Inconnue
  "Rien de mieux pour démarre la journée qu’un bon petit déjeuner.", // Petit
  "Commandez à domicile des entrées plus délicieuses les unes que les autres !", // Entrées
  "Soupes", // Soupes
  "Salades", // Salades
  "Hors-d'œuvres", // Hors-d'œuvres
  "De quoi extasier votre palais avec toutes les saveurs des mets africain faites vous livrer en un clic !", // Plat principal
  "Faites vous livrer peu importe le lieux des burgers pour satisfaire toutes vos envies", // Burgers
  "Des pizzas délicieuses pour tous les goûts et tous les âges !", // Pizzas
  "Plat d'accompagnement", // Plat d'accompagnement
  "Apéritif", // Apéritif
  "Faites vous livrer peu importe le lieux des desserts gourmands pour satisfaire toutes vos envies!", // Dessert
  "Découvrez des boisons de toutes les saveurs, gazeuses, naturelles, des cocktails et bien d’autres...", // Boissons
  "Autres", // Autres
  "Non valide", // Non valide
]);

/**
 * @brief Get the sub title of a menu category base on the value provided from the enMenuCategory_t,
 * @param {String} i_eMenuCategory -> The MenuCategory where the sub title will be gotten
 * @returns {String}
 */
const getMenuCategorySubTitle = (i_eMenuCategory) => {
  let strMenuCategorySubTitle;
  if (
    i_eMenuCategory < SOFTCONFIG_DEV_MENUCATEGORY_MIN ||
    i_eMenuCategory > SOFTCONFIG_DEV_MENUCATEGORY_MAX
  ) {
    strMenuCategorySubTitle =
      MENU_CATEGORIES_SUBTITLES[MENU_CATEGORIES_SUBTITLES.length - 1];
  } else {
    strMenuCategorySubTitle = MENU_CATEGORIES_SUBTITLES[i_eMenuCategory];
  }
  return strMenuCategorySubTitle;
};

export {
  enMenuCategory_t,
  MENU_CATEGORIES,
  SOFTCONFIG_DEV_MENUCATEGORY_MIN,
  SOFTCONFIG_DEV_MENUCATEGORY_MAX,
  toString,
  fromString,
  fromNumber,
  getMenuCategorySubTitle,
};
