const enPaymentMethodTypes_t = Object.freeze({
  ePaymentMethodTypesContact_Unknown: 0,
  ePaymentMethodTypesContact_Paypal: 1,
  ePaymentMethodTypesContact_CreditCard: 2,
  ePaymentMethodTypesContact_Cash: 3,
  ePaymentMethodTypesContact_BankTransfer: 4,
  ePaymentMethodTypesContact: 5,
  ePaymentMethod_Invalid: 255,
});

export default enPaymentMethodTypes_t;
